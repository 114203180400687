import {useState, useEffect} from 'react'
import { Line, Image, H1, H2, Div, Card } from './styles'
import Mobile from '../../../images/mobile.png'
import ScreenSection from '../Shared/ScreenSection/ScreenSection'
import { IoIosGlobe } from 'react-icons/io'

const ScreenOne = () => {
    const [uf, setUf] = useState('');

    useEffect(() => {
        const abortCont = new AbortController()

        async function getAddress(){
            const state = window.localStorage.getItem('uf');
            let response;
            let json;

            if(state === null){
                try{
                    response = await fetch('https://freegeoip.app/json/', {signal: abortCont.signal});
                    json = await response.json();
                    const estado = json.region_code;
                    setUf(estado)
                    window.localStorage.setItem('uf', estado);
                    if(estado === ''){
                        setUf('SP');
                        window.localStorage.setItem('uf', 'SP');
                    }
                } catch(e){
                    setUf('SP');
                    window.localStorage.setItem('uf', 'SP');
                }
            } else {
                setUf(state);
            }
          }
        getAddress();

        return () => abortCont.abort();
    }, [])

    return (
        <ScreenSection color={"#FFFFFF"}>
            <H1>Consumer Ads</H1>
            <Line />
            <H2>Gostaria de fazer o seu cardápio<br/> vender mais no Google?</H2>
            <Div>
                <Image src={Mobile} alt="Visualização do celular" className='removeSelection' />    
                <Card className='removeSelection'>
                    <p><b>Anúncio</b> - menudino.com/seucardapio</p>
                    <span>Melhor Burger delivery de {uf}</span>
                    <p>Peça já o melhor e mais saboroso burger artesanal de {uf}</p>
                    <div><IoIosGlobe size="25"/> Visite o Site</div>
                </Card>        
            </Div>
        </ScreenSection>
    )
}

export default ScreenOne
