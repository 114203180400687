export class GetUserData {
    success: boolean;
    errorMessage: string;
    content: Content

    constructor(success: boolean, errorMessage: string, content: Content) {
        this.success = success
        this.errorMessage = errorMessage
        this.content = content
    }

    static fromJson(json: any): GetUserData {
        return new GetUserData(json["success"], json["error_message"], json["content"])
    }
}

export class Content {
    userId: string
    username: string
    name: string
    consumerId: string
    menuId: string
    menuUrl: string
    menuTitle: string
    adsCreationCurrentStep: Number
    autoRechargeConfig: RechargeConfig

    constructor(userId: string, username: string, name: string, consumerId: string, menuId: string, menuUrl: string, menuTitle: string, adsCreationCurrentStep: Number, autoRechargeConfig: RechargeConfig) {
        this.userId = userId
        this.username = username
        this.name = name
        this.consumerId = consumerId
        this.menuId = menuId
        this.menuUrl = menuUrl
        this.menuTitle = menuTitle
        this.adsCreationCurrentStep = adsCreationCurrentStep
        this.autoRechargeConfig = RechargeConfig.fromJson(autoRechargeConfig)
    }

    static fromJson(json: any): Content {

        return new Content(json.userId, json.username, json.name, json.consumerId, json.menuId, json.menuUrl, json.menuTitle, json.adsCreationCurrentStep, json.autoRechargeConfig)
    }
}

class RechargeConfig {
    createdAt: Date
    amount: Number
    creditCard: CreditCard

    constructor(createdAt: Date, amount: Number, creditCard: CreditCard) {
        this.createdAt = createdAt
        this.amount = amount
        this.creditCard = CreditCard.fromJson(creditCard)
    }

    static fromJson(json: any): RechargeConfig {
        return new RechargeConfig(json["createdAt"], json["amount"], json["creditCard"])
    }
}

export class CreditCard {
    externalId: string
    maskedNumber: string
    expiration: string
    brand: string
    holderName: string

    constructor(externalId: string, maskedNumber: string, expiration: string, brand: string, holderName: string) {
        this.externalId = externalId
        this.maskedNumber = maskedNumber
        this.expiration = expiration
        this.brand = brand
        this.holderName = holderName
    }

    static fromJson(json: any): CreditCard {
        return new CreditCard(json["externalId"], json["maskedNumber"], json["expiration"], json["brand"], json["holderName"])
    }
}