import styled, {keyframes} from "styled-components";

const isPassing = keyframes`
    from {
    transform: translateX(-100%); 
  }
`

export const PlaceholderAd = styled.div`
    display: flex;
    width: 100%;
    height: 60%;
    margin-top: 20px;
    align-items: start !important;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 20px;
    overflow: hidden;
    text-align: start;

    > span{
        background: #c5c5c5;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        text-align: start;

        &::before{
            animation: ${isPassing} .8s ease-in infinite;
            background:
            linear-gradient(
                to right,
                transparent,
                #fff 10%,
                transparent
            );
            content: '';
            display: block;
            height: 100vh;
            position: absolute;
            width: 100vw;
        }
    }
`