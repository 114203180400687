import styled, { keyframes } from 'styled-components';

const imageAnimation = keyframes`
    from {
        margin-top: -20px;
        opacity: 0;
    } 
    to {
        margin-top: 0;
        opacity: initial;
    }
`; 

export const H1 = styled.span`
    color: ${props => props.theme.color.blackColor};
    font-size: ${props => props.theme.fonts.bigBoldTextOnboard}px;
    font-weight: bold;

    @media(max-width: 530px){
        font-size: min(35px, 8vh);
    }
`; 

export const H2 = styled.h2`
    margin-bottom: 20px;
    white-space: nowrap;
    font-size: 32px;
    @media(max-width: 530px){
        font-size: min(20px, 6vh);
    }
`;

export const Line = styled.div`
    height: 5px;
    min-width: min(90%, 300px);
    margin: 20px auto;
    background-color: ${props => props.theme.color.buttonColor};
`;

export const Div = styled.div`
    height: 100%;
    margin-bottom: -188px;

    @media(max-width: 800px){
        margin-bottom: -138px;
    }
    @media(max-width: 400px){
        margin-bottom: -98px;
    }
`;

export const Image = styled.img`
    max-height: 100%;
    max-width: 400px;
    text-align: center;
    animation: ${imageAnimation} 1s forwards;
    object-fit: cover;
    object-position: top;
    margin-left: 25px;

    @media(max-width: 800px){
        max-width: 300px;
    }

    @media(max-width: 400px){
        max-width: 200px;
    }

    @media(max-width: 272px){
        max-width: 150px;
    }
`;

export const Card = styled.div`
    animation: ${imageAnimation} 1s forwards;
    position: relative;
    height: 182px;
    width: 310px;
    background: white;
    margin: 0 auto;
    bottom: 250px;
    left: 10px;
    right: 300px;
    box-shadow: 1px 1px 5px rgba(0,0,0,.1);
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 10px 20px;
    text-align: start;

    div > svg {
        color: gray;
        margin-right: 5px;
    }

    div{
        display: flex;
        align-items: center;
        width: 110px;
        margin-top: 5px;
        justify-content: start;
    }

    span {
        color: #004FAC;
        font-weight: bold;
    }

    @media(max-width: 800px){
        bottom: 190px;
        left: 0px;
        width: 230px;
        height: 130px;
        font-size: 10px;
    }

    @media(max-width: 400px){
        bottom: 130px;
        left: 8px;
        width: 160px;
        height: 90px;
        font-size: 7px;

        p {
            font-size: 6px;
            margin-bottom: 5px;
        }

        span{
            margin-bottom: 5px;
        }
    }

    @media(max-width: 272px){
        bottom: 100px;
        left: 8px;
        width: 120px;
        height: 90px;
        font-size: 7px;
        background: white;
        p {
            font-size: 6px;
            margin-bottom: 2px;
        }

        span{
            margin-bottom: 2px;
            font-size: 5px;
        }
        div > svg {
            height: 10px;
            width: 10px;
            margin-right: 5px;
        }
    }
`;
    