import { FC, useEffect } from 'react'
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import ScreenFive from './components/ScreenFive/ScreenFive';
import ScreenFour from './components/ScreenFour/ScreenFour';
import ScreenOne from './components/ScreenOne/ScreenOne';
import ScreenTree from './components/ScreenTree/ScreenTree';
import ScreenTwo from './components/ScreenTwo/ScreenTwo';
import Modal from './components/Shared/Modal/Modal';
import { Section } from './styles';

const OnBoardPage:FC = () => {

    useEffect(() => {
        window.open('https://www.consumer.com.br/consumerads', '_self');

        // const abortCont = new AbortController();

        // async function getAddress(){
        //     const state = window.localStorage.getItem('uf');
        //     let response;
        //     let json;

        //     if(state === null){
        //         try{
        //             response = await fetch('https://freegeoip.app/json/', {signal: abortCont.signal});
        //             json = await response.json();
        //             const estado = json.region_code;
        //             window.localStorage.setItem('uf', estado);
        //             if(estado === ''){
        //                 window.localStorage.setItem('uf', 'SP');
        //             }
        //         } catch(e){
        //             window.localStorage.setItem('uf', 'SP');
        //         }
        //     }
        //   }
        // getAddress();

        // return () => abortCont.abort();
    }, [])

    return (
        <>
            <Header/>
            <Section>
                <ScreenOne/>
                <ScreenTwo />
                <ScreenTree />
                <ScreenFour />
                <ScreenFive />
                <Footer />
                <Modal />
            </Section>
        </>
    )
}

export default OnBoardPage