import { PlaceholderCredit, Placeholder } from './styles'

const index = () => {
    return (
        <PlaceholderCredit>
            <Placeholder>
                <span style={{width: "30%"}}></span>
                <span style={{width: "50%"}}></span>
            </Placeholder>
            <span style={{width: "45%"}}></span>
        </PlaceholderCredit>
    )
}

export default index
