import { useState, useCallback, useEffect } from 'react'
import { Circle1, Circle2, Circle3, Items, Initial, Slider, Footer, DivBack} from './styles';
import { FaCheck } from 'react-icons/fa';
import Group from '../../Screens/Group';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

const Index = () => {
    const [step, setStep] = useState<any>(1);
    const [hasMenudino, setHasMenudino] = useState<string>()
    const next = useCallback(() => {
            if(step === 4){
                return;
            } else {
                let temp = step + 1;
                setStep(temp);
            }
        },[step],
    )    

    const previous = useCallback(() => {
            if(step === 1){
                 return;
            } else {
                let temp = step - 1;
                setStep(temp);
            }
        },[step],
    )

    useEffect(() => {
        let currentStep = window.localStorage.getItem("currentStep");
        let hasMenudinoStorage = window.localStorage.getItem("temMenuDino");
        setHasMenudino(hasMenudinoStorage!);
        if(hasMenudino === "false") {
            setStep(1)
        }
        
        if(currentStep !== null){
            setStep(Number(currentStep))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Initial>
            {hasMenudino === "true" && 
            <>
                <Items>
                    <div onClick={() => {
                                    if(step === 4){
                                        return;
                                    } else {
                                        setStep(1)
                                    }
                                }}>
                        <Circle1 step={step}>{step >= 2 ? <FaCheck /> : '1'}</Circle1>
                        <p style={{color: `${step === 1 ? 'black' : '#9B9BA2'}`}}>Seu estabelecimento</p>
                    </div>
                    <div onClick={() => {
                                    if(step === 4){
                                        return;
                                    } else {
                                        setStep(2)
                                    }
                                }}>
                        <Circle2 step={step}>{step >= 3 ? <FaCheck /> : '2'}</Circle2>
                        <p style={{color: `${step === 2 ? 'black' : '#9B9BA2'}`}}>Anúncio no Google</p>
                    </div>
                    <div onClick={() => {
                                    if(step === 4){
                                        return;
                                    } else {
                                        setStep(3)
                                    }
                                }}>
                        <Circle3 step={step}>{step >= 4 ? <FaCheck /> : '3'}</Circle3>
                        <p style={{color: `${step === 3 ? 'black' : '#9B9BA2'}`}}>Investimento</p>
                    </div>
                </Items>
                <Slider animationTo={step}/>
            </>
            }

            <Group step={step} next={next}/>
            

            { hasMenudino  === "true" &&
            <Footer>
                {step === 4 ? null : 
                    <>
                        <DivBack step={step} onClick={() => previous()}>
                            <FaChevronLeft size="25" />
                            <p>Voltar</p>
                        </DivBack>

                        {step >= 3 ? null :
                            <div onClick={() => next()}>
                                <button>Continuar <FaChevronRight size="18"/></button>
                            </div> 
                        }
                    </>
                }
            </Footer>
            }
        </Initial>
    )
}

export default Index
