export class AddCreditResponse {
    success: boolean;
    errorMessage: string;
    content?: Content;

    constructor(success: boolean, errorMessage: string, content?: Content) {
        this.success = success;
        this.errorMessage = errorMessage;
        this.content = content;
    }

    static fromJson(json: any): AddCreditResponse {
        return new AddCreditResponse(json["success"], json["error_message"], Content.fromJson(json["content"]))
    }
}

class Content {
    userAdsAccountCreditHistoryId: string;

    constructor(userAdsAccountCreditHistoryId: string) {
        this.userAdsAccountCreditHistoryId = userAdsAccountCreditHistoryId
    }

    static fromJson(json: any): Content {
        return new Content(json === null ? null : json["userAdsAccountCreditHistoryId"])
    }
}