/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useNavigate } from 'react-router';

const useFetch = () => {
    const [data, setData] = React.useState<any>(null);
    const [error, setError] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const navigate = useNavigate()

    function saveUrl(){
        const url = window.location.href;
        const result = url.split("/");
        window.localStorage.setItem('url', result[result.length - 1]);
        window.localStorage.removeItem('token');
        navigate('/login');
    }

    const request = React.useCallback(async (url, options) => {
        let response;
        let json;

        try {
            setError(null);
            setLoading(true);

            response = await fetch(url, options )
            json = await response.json();

            if(response.ok === false) {
                if(json.error_message === "User not found") {
                    navigate('/login')
                }
                else {
                    setError("Erro ao obter as informações.");
                    setTimeout(() => {
                        setError(null)
                    }, 5000)
                }
            }


        } catch(e: any){
            json = null;

            if(e.message === "AbortError"){
                
            } else 
            if(response?.status === 401 || response?.status === 403){
                saveUrl();
                setError(null)
                navigate('/login');
            }  else if(response?.status === 400){
                setError("Ocorreu um erro ao obter as informações. Tente novamente.")
            } else if(response?.status === 500){
                setError("Ocorreu um erro ao obter as informações. Tente novamente.")
            } else {
                setError(e.message)
                setTimeout(() => {
                    setError(null)
                }, 5000)
            }
        } finally {
            setData(json);
            setLoading(false);

            return { response, json }
        }

    }, []);


    return {
        data,
        error,
        loading,
        request
    }
}

export default useFetch
