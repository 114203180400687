import styled, { css, keyframes } from 'styled-components';

export const Initial = styled.div`
    top: 70px;
    position: relative;
    width: 100%;
    height: 100%;
`;

export const Items = styled.div`
    display: flex;
    padding: 5px 30px 10px 30px;
    align-items: flex-start;
    justify-content: center;
    box-shadow: 2px 5px 8px rgba(0,0,0,.2);
    max-width: 100%;
    
    div{
        max-width: 200px;
        cursor: pointer;
        margin: 0 auto;
        display: flex;
        font-size: min(.75rem, 3vw);
        flex-wrap: wrap;
        text-align: center;
        align-items: center;
        padding: 0;
        justify-content: center;

        p {
            font-weight: bold;
            padding: 0;
            margin-right: 10px;
        }
    }

    @media(max-width: 553px){
        padding: 10px;
    }
`;

interface SliderProps {
    animationTo: Number
}

const fill1 = keyframes`
    to {
        width: calc(100vw - 86.7vw);
    }
`

const fill2 = keyframes`
    from {
        width: calc(100vw - 86.7vw);
    }

    to {
        width: calc(100vw - 53.3vw);
    }
`

const fill3 = keyframes`
    from {
        width: calc(100vw - 53.3vw);
    }
    to {
        width: calc(100vw - 21vw);
    }
`

const fill4 = keyframes`
    from {
        width: calc(100vw - 21vw);
    }
    to {
        width: 100vw;
    }
`

export const Slider = styled.div<SliderProps>`
    width: 0vw;
    background: ${props => props.theme.color.buttonColor};
    height: 5px;
    animation: ${props => props.animationTo === 1 ? 
    css`${fill1} .5s forwards` : props.animationTo === 2 ? 
    css`${fill2} .5s forwards` : props.animationTo === 3 ? 
    css`${fill3} .5s forwards` : css`${fill4} .5s forwards`};
`;

interface CircleProps {
    step: Number;
}

export const Circle1 = styled.span<CircleProps>`
    height: 35px;
    width: 35px;
    border-radius: 50%;
    padding: 10px;
    text-align: center;
    line-height: 18px;
    background: ${props => props.step >= 1 ? props.theme.color.buttonColor : '#D4D4DB' };
    margin-right: 10px;
    color: white;
    font-weight: bold;
    transition: .5s;
`;

export const Circle2 = styled.span<CircleProps>`
    height: 35px;
    width: 35px;
    border-radius: 50%;
    padding: 10px;
    text-align: center;
    line-height: 18px;
    background: ${props => props.step >= 2 ? props.theme.color.buttonColor : '#D4D4DB' };
    margin-right: 10px;
    color: white;
    font-weight: bold;
    transition: .5s;
`;

export const Circle3 = styled.span<CircleProps>`
    height: 35px;
    width: 35px;
    border-radius: 50%;
    padding: 10px;
    text-align: center;
    line-height: 18px;
    background: ${props => props.step >= 3 ? props.theme.color.buttonColor : '#D4D4DB' };
    margin-right: 10px;
    color: white;
    font-weight: bold;
    transition: .5s;
`;

export const Footer = styled.footer`
    height: 70px;
    width: 100%;
    background: ${props => props.theme.color.blackColor};
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    color: white;

    div{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: start;
        transition: .3s;

        p{
            font-size: .9rem;
            font-weight: bold;
            padding: 15px 10px;
        }

        button {
            display: flex;
            align-items: center;
            cursor: pointer;
            background: ${props => props.theme.color.buttonColor};
            outline: none;
            border: 1px solid transparent;
            padding: 10px 20px;
            letter-spacing: .05rem;
            font-size: .9rem;
            color: white;
            font-weight: 700;
            border-radius: 3px;
            transition: .3s;

            svg {
                margin-left: 8px;
            }

            &:hover{
                filter: hue-rotate(9deg);
            }
        }
    }

    @media(max-width: 400px){
        padding: 0 10px;
    }
`;

interface DivBackProps {
    step: any
}

export const DivBack = styled.div<DivBackProps>`
    opacity: ${props => props.step === 1 ? '0' : '1'};

    &:hover{
        filter: brightness(.9);
    }
`;

export const Button = styled.div`
    background: ${props => props.theme.color.buttonColor};
    color: white;
    font-weight: bold;
    margin: 10px auto;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: min(.9rem, 4vw);
    cursor: pointer;
    border: 1px solid transparent;
    transition: .5s;

    &:hover{
        border-color: ${props => props.theme.color.buttonColor};
        color: ${props => props.theme.color.buttonColor};
        background: white;
    }
`;

export const ButtonProvisorio = styled.div`
    background: #5cb85c;
    color: white;
    font-weight: bold;
    margin: 10px auto;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: min(.9rem, 4vw);
    cursor: pointer;
    border: 1px solid transparent;
    transition: .5s;

    &:hover{
        border-color: #5cb85c;
        color: #5cb85c;
        background: white;
    }
`;