/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, useCallback } from 'react'
import { ContentFlex, H1, Item1, Div1, Div2, Item2, Items, Modal, ModalContent, Close, AutoRecharge, ChangeValue, Spin } from './styles'
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa'
import { GET_INFOS, GET_USER_DATA, REMOVE_AUTO_RECHARGE, UPDATE_AUTO_RECHARGE_AMOUNT } from '../../../api';
import { useNavigate } from 'react-router-dom';
import ContentPlaceholder from '../Shared/PlaceholderComponentCreditAd';
import CardPlans from '../../../Campaing/Shared/CardPlans';
import { GetUserData } from '../../../Models/Responses/GetUserData';

const Index: FC = () => {

    const [ads, setAds] = useState(1);
    const [modal, showModal] = useState<any>("hide")
    const [modalDisableAutoRecharge, setModalDisableAutoRecharge] = useState<boolean>()
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedPriceUpdate, setSelectedPriceUpdate] = useState<number>()
    const [changeValue, setChangeValue] = useState<boolean>(false)
    const [userDataAutoRecharge, setUserDataAutoRecharge] = useState<GetUserData>()
    const [loadingRecharge, setLoadingRecharge] = useState<boolean>(false)
    const navigate = useNavigate();

    async function getUserData() {
        let resp, json;

        try {
            resp = await fetch(GET_USER_DATA, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });

            if (resp.ok || resp.status === 200) {
                json = await resp.json()
                setUserDataAutoRecharge(GetUserData.fromJson(json))
            } else {
                setError(`${resp.status}`)
            }
        } catch (e: any) {
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }

    async function getInfos() {
        let response;
        let json;

        try {
            setLoading(true);
            response = await fetch(GET_INFOS, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });

            json = await response.json();
            if (response?.status === 401 || response?.status === 403) {
                navigate('/login');
            } else if (response?.status === 404) {
                setError("Não há informações para esta conta!")
                setLoading(false);
            } else if (response?.status === 500) {
                setError(json.error_message)
                setLoading(false);
            } else if (response.ok) {
                setData(json);
                setLoading(false);
            } else {
                setError(json["error_message"])
                setData(null);
                setLoading(false);
            }
        } catch (e: any) {
            if (response?.status === 404) {
                setError("Não há informações para esta conta!")
                setLoading(false);
            } else if (response?.status === 401 || response?.status === 403) {
                navigate('/login')
            } else {
                setError(e.message)
                setLoading(false);
            }
        } finally {
            getUserData();
        }
    }

    async function desactivateAutoRecharge() {
        let response;
        setLoadingRecharge(true)
        try {
            response = await fetch(REMOVE_AUTO_RECHARGE, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });

            if (response.status === 401 || response.status === 403) {
                navigate("/login")
            } else if (response.ok || response.status === 204) {
                window.location.reload();
            } else {
                setError("Ocorreu um erro ao desativar a recarga automática, tente novamente.")
            }
        } catch (e: any) {
            setError("Ocorreu um erro ao desativar a recarga automática: " + e.message)
        } finally {
            setLoadingRecharge(false)
        }
    }

    async function updateAutoRecharge(amount: number) {
        let response;

        setLoadingRecharge(true)

        try {
            response = await fetch(`${UPDATE_AUTO_RECHARGE_AMOUNT}?amount=${amount}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 401 || response.status === 403) {
                navigate("/login")
            } else if (response.ok || response.status === 204) {
                window.location.reload();
            } else {
                setError("Ocorreu um erro ao desativar a recarga automática, tente novamente.")
            }
        } catch (e: any) {
            setError(e.message)
        } finally {
            setLoadingRecharge(false)
        }
    }


    async function click(amount: Number) {

        localStorage.setItem("valorEscolhido", `${amount}`);
        document.body.style.overflowY = 'scroll';

        navigate("/checkout");
    }

    useEffect(() => {
        getInfos();
    }, [])

    useEffect(() => {
        if (modal === true) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'scroll';
        }
        return () => {

        }
    }, [modal])

    const handleClick = useCallback((e) => {
        if (e.currentTarget === e.target) {
            document.body.style.overflowY = 'scroll';
            showModal(false);
            setModalDisableAutoRecharge(false)
            setTimeout(() => {
                showModal('hide');
            }, 200);
        }
    }, [])

    useEffect(() => {
        if (error !== null)
            setLoading(false)
    }, [error])

    if (error) return <ContentFlex>
        <H1>Informações da Campanha</H1>
        <Items>
            <Item1>
                <Div1>
                    <b>Orçamento</b>
                </Div1>:
                <Div2 style={{ display: 'flex', height: '50%', alignItems: 'center', justifyContent: 'center' }}>
                    <p>{error}</p>
                </Div2>
            </Item1>
            <Item2>
                <b>Meus anúncios <span>(0 de 0)</span></b>
                <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <p>{error}</p>
                </div>
            </Item2>
        </Items>
    </ContentFlex>

    if (loading) return <ContentPlaceholder />

    if (data === null) return <ContentPlaceholder />

    if (data.content === null) return <ContentFlex><p>Nenhum dado encontrado!</p></ContentFlex>

    return (
        <ContentFlex>
            {/* <Message success={false} status={statusRecharge}>
                {statusRecharge !== 'hide' && statusRecharge !== false && <p>{statusRecharge}</p>}
            </Message> */}
            <Modal modal={modal} onClick={(e) => handleClick(e)}>
                <ModalContent>
                    <Close onClick={() => {
                        showModal(false);
                        setTimeout(() => {
                            showModal('hide');
                        }, 200);
                    }
                    }>X</Close>
                    <div className="title">
                        <b>Adicionar crédito para minha campanha</b>
                    </div>

                    {
                        // paymentLoading ? <div style={{ height: '80%', display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        //     <Spin />
                        //     <p style={{ marginTop: '20px' }}> Processando dados para pagamento, aguarde!</p></div>
                        //     :
                        <div className="content">
                            <CardPlans isRecommended={false} cardTitle="Iniciante" create={() => click(150)} fee="7,50" price={150} spendDay={5} totalPrice={"142,50"} />
                            <CardPlans isRecommended={false} cardTitle="Básico" create={() => click(300)} fee="15,00" price={300} spendDay={10} totalPrice={"285,00"} />
                            <CardPlans isRecommended={true} cardTitle="Profissional" create={() => click(600)} fee="30,00" price={600} spendDay={20} totalPrice={"570,00"} />
                            <CardPlans isRecommended={false} cardTitle="Avançado" create={() => click(1200)} fee="60,00" price={1200} spendDay={30} totalPrice={"1.140,00"} />
                        </div>
                    }
                    <div className='title'>
                        <p>Todas opções representam aproximadamente 30 dias de campanha</p>
                    </div>
                </ModalContent>
            </Modal>

            {modalDisableAutoRecharge &&
                <Modal modal={modalDisableAutoRecharge} onClick={(e) => handleClick(e)}>
                    <ModalContent>
                        <Close onClick={() => {
                            setModalDisableAutoRecharge(false);
                        }
                        }>X</Close>
                        <div className="title">
                            <b>Deseja desativar a recarga automática de sua campanha?</b>
                            <div className="content">
                                <span>Voltar</span>
                                <p onClick={() => desactivateAutoRecharge()}>Sim, desativar</p>
                            </div>
                        </div>
                        <div className='title footer'>
                            <p>Para reativar a recarga automática, você precisará realizar um novo pagamento marcando a opção de recarga automática.</p>
                        </div>
                    </ModalContent>
                </Modal>
            }

            <H1>Informações da Campanha</H1>

            <Items>
                {
                    // rechargeError !== '' ?
                    //     <Item1>
                    //         <Div1>
                    //             <b>Orçamento</b>
                    //         </Div1>:
                    //         <Div2 style={{ display: 'flex', height: '50%', alignItems: 'center', justifyContent: 'center' }}>
                    //             {/* <p>{rechargeError}</p> */}
                    //         </Div2>
                    //     </Item1> :

                    <Item1>
                        <Div1>
                            <b>Orçamento</b>
                        </Div1>
                        <Div2>

                            <div className="div1">
                                <b>Saldo atual</b>
                                <span>R$ {data.content.creditAvaiable.toFixed(2).toString().replace('.', ',')}</span>
                            </div>
                            <button onClick={async () => {
                                showModal(true);
                            }}
                            >Adicionar mais crédito</button>
                            <br />
                            <br />
                            {/* {success && <p style={{ marginTop: '20px' }}>O link de pagamento foi aberto em nova guia, se não estiver visualiando, <a href="https://pay.juno.com.br/checkout.html?code=BD0DC6A10C65C76D" target="_blank" rel="noreferrer" style={{ cursor: 'pointer', fontWeight: 'bold' }}
                            >clique aqui!</a></p>} */}
                        </Div2>
                        {loadingRecharge ? <AutoRecharge isLoading={true}><Spin /></AutoRecharge> :
                            userDataAutoRecharge && (
                                <AutoRecharge isLoading={false}>
                                    {
                                        userDataAutoRecharge.content.autoRechargeConfig ?
                                            <div><span>Recarga automática: <b className='activate'>ATIVADA</b> (R$ {userDataAutoRecharge.content.autoRechargeConfig.amount},00)</span></div>
                                            : <div><span>Recarga automática: <b className='desactivate'>DESATIVADA</b></span></div>
                                    }
                                    <br />
                                    {
                                        userDataAutoRecharge.content.autoRechargeConfig ?
                                            <div onClick={() => setModalDisableAutoRecharge(true)}><p>Desativar recarga automática</p></div>
                                            : <div><small>Para ativar a recarga automática, faça uma recarga marcando a opção recarga automática.</small></div>
                                    }

                                    {
                                        userDataAutoRecharge.content.autoRechargeConfig &&
                                        <ChangeValue className='changeValue'>
                                            <p onClick={() => setChangeValue(true)}>Alterar valor da recarga automática</p>
                                            {
                                                changeValue &&
                                                <select onChange={({ target }) => { setChangeValue(false); setSelectedPriceUpdate(Number.parseFloat(target.value)) }}>
                                                    <option disabled selected value="">selecione</option>
                                                    <option value={150}>Iniciante - R$ 150,00</option>
                                                    <option value={300}>Básico R$ 300,00</option>
                                                    <option value={600}>Profissional - R$ 600,00</option>
                                                    <option value={1200}>Avançado - R$ 1.200,00</option>
                                                </select>
                                            }
                                            {
                                                selectedPriceUpdate && <p onClick={() => updateAutoRecharge(selectedPriceUpdate)}>Valor: R$ {selectedPriceUpdate},00 <span>Clique para atualizar a recarga automática.</span></p>
                                            }
                                        </ChangeValue>
                                    }
                                </AutoRecharge>
                            )}
                    </Item1>
                }
                {data.content.ads !== null ?
                    <Item2>
                        {
                            data.content.ads.length === 0 ? <b>Menus anúncios</b> : <b>Meus anúncios <span>({ads} de {data.content.ads.length})</span></b>
                        }
                        <div>
                            {data.content.ads.length > 1 && <FaCaretLeft style={{
                                cursor: ads === 1 ? 'default' : 'pointer'
                            }} color={ads === 1 ? '#c2c2c2' : '#000'} onClick={() => setAds(1)} />}
                            {data.content.ads.length === 0 ? <p>Anúncios não encontrados.</p> :
                                ads === 1 ?
                                    <article>
                                        <b>Anúncio - <span onClick={() => window.open(data.content.ads[0].url)}>{data.content.ads[0].url}</span></b><br /><br />
                                        <span>{data.content.ads[0].title}</span>
                                        <br /><br />
                                        <p>{data.content.ads[0].description}</p>
                                    </article> :
                                    <article>
                                        <b>Anúncio - <span onClick={() => data.content.ads[0].url}>{data.content.ads[1].url}</span></b><br /><br />
                                        <span>{data.content.ads[1].title}</span>
                                        <br /><br />
                                        <p>{data.content.ads[1].description}</p>
                                    </article>}
                            {/* <p>{JSON.stringify(data.content)}</p> */}
                            {data.content.ads.length > 1 && <FaCaretRight style={{
                                cursor: ads === 2 ? 'default' : 'pointer'
                            }} color={ads === 2 ? '#c2c2c2' : '#000'} onClick={() => { if (ads === 1) setAds(2) }} />}

                        </div>
                    </Item2> : <></>}
            </Items>
        </ContentFlex>
    )
}

export default Index
