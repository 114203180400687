import OnBoardPage from './OnBoardPage';
import GlobalStyle from './styles/global';
import { ThemeProvider } from 'styled-components'
import theme from './styles/theme/theme';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Campaing from './Campaing/index';
import Dashboard from './Dashboard/index';
import Login from './Login';
import NotFound from './NotFound';
import CampaignOrder from './CampaignOrder';
import Checkout from './Checkout';

function App() {

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <main className="App">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="inicio" element={<OnBoardPage />} />
              <Route path="criar" element={<Campaing />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="login" element={<Login />} />
              <Route path="checkout" element={<Checkout />} />
              <Route path="pagamento/:id" element={<CampaignOrder />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
        </main>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
