import styled from 'styled-components';

export const Container = styled.div`
    max-width: 1200px;
    min-height: 74vh;
    padding: 3rem 60px 8rem 60px;
    margin: 0 auto;

    div{
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 200px .7fr 230px;
        grid-template-rows: 50px 230px 230px 30px;
    }

    @media(max-width: 1146px){
        div {
            grid-template-columns: 200px auto;
            grid-template-rows: 50px 230px 230px 230px 30px;
        }
        margin-bottom: 200px;
    }

    @media(max-width: 1036px){
        margin-bottom: 0px;
        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        padding: 20px 10px 90px 10px;
    }

    @media(max-width: 546px){
        padding: 0 0 100px 0;
        margin: 0;
    }
`;

export const GridImage = styled.div`
    position: relative;
    
    img{
        grid-row: 0/3;
        grid-column: 0/1;
        width: 110%;
        box-shadow: 1px 2px 8px rgba(0,0,0,0.5);
    }

    

    @media(max-width: 1036px){
        display: none !important;
        img{
            display: none !important;
        }
    }

    @media(max-width: 1146px){ 
        grid-row: 0/3;
        grid-column: 0/1;
        width: 140%;
    }
`;

export const MenuDinoCard = styled.div`
    width: 200px;
    height: 170px;
    position: absolute;
    top: 29px;
    margin: 0 auto;

    div {
        position: relative;
        top: 25px;
        left: 5px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    /* @media(max-width: 1146px){
        top: 26px;
        width: 57.8%;
        height: 300%;
    } */

    @media(max-width: 1036px){
        display: none !important;
    }
`;

export const Cover = styled.img`
    width: 200px;
    height: 100%;
    position: absolute;
    object-fit: cover;
    margin: 0;
    filter: brightness(.85);

    @media(max-width: 1036px){
        display: none !important;
    }
`;

export const Logo = styled.div`
    z-index: 3;
    /* position: absolute; */
    background: white;

    img {
        width: 60px;
    }

    @media(max-width: 1036px){
        display: none !important;

        img {
            display: none !important;
        }
    }
`;

export const MdTitle = styled.div`
    /* position: absolute; */
    z-index: 3;
    width: 180px;
    height: 12px;

    p{
        white-space: nowrap;
        color: white;
        font-size: 14px;
        font-weight: bold;
    }

    @media(max-width: 1036px){
        display: none !important;
        p {
            display: none !important;
        }
    }
`;

export const OpenNow = styled.div`
    width: 80px;
    height: 20px;
    font-size: 10px;
    z-index: 3;
    background: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
        
    b {
        white-space: nowrap;
        color: #008b00;
        line-height: 20px;
    }

    @media(max-width: 1036px){
        display: none !important;
        b {
            display: none !important;
        }
    }
`;

export const GridTitle = styled.div`
    grid-column: 2/4;
    grid-row: 0/1;
    margin-left: 35px;
    border-bottom: 1px solid #DEDEDE;

    div {
        display: flex;
        margin-bottom: 30px;   

        span{
            cursor: pointer;
            letter-spacing: .05rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 24px;
            
            svg {
                margin-right: 10px;
                color: ${props => props.theme.color.buttonColor};
            }

            b{
                white-space: nowrap;
                overflow: hidden;
                color: ${props => props.theme.color.buttonColor};
            }
        }
    }

    @media(max-width: 1146px){
        grid-column: 2/2;
    }

    @media(max-width: 1036px){
        margin-left: 0px;
    }

    @media(max-width: 546px){
        div{
            span{
                margin-top: 20px;
                font-size: min(18px, 4vw);
            }
        }
    }
`;

export const GridCenter = styled.div`
    grid-row: 2/3;
    grid-column: 2/4;
    padding: 0px 40px;
    
    > div {
        display: flex;
        flex-direction: column;
        width: 450px;

        em {
            margin-top: -5px;
            font-size: .7rem;
            margin-left: 43px;
            margin-bottom: 10px;
        }

        div {
            width: 100%;
            display: flex;
            height: 30px;
            align-items: center;

            b{
                display: flex;
                align-items: center;
                font-size: 20px;

                svg {
                    height: 22px;
                    width: 22px;
                    margin-right: 20px;
                }
            }
        }
    }

    @media(max-width: 1036px){
        > div {
            width: 300px;

            div {
                flex-direction: row;
            }
        }
    }

    @media(max-width: 390px){
        margin: 0 auto;
        padding: 0;
    }

    @media(max-width: 340px){
        > div {
            div {
                
                margin: 0 auto;
                padding: 0;
                text-align: center;

                b {
                    font-size: 16px;
                }
            }
        }
        em {
            font-size: .6rem;
            margin-right: 15px;
            padding-left: -10px;
        }
    }
`;

export const GridCenter1 = styled.div`
    grid-row: 2/4;
    grid-column: 2/4;
    padding: 90px 40px;
    
    div {
        display: flex;
        flex-direction: column;

        span {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                width: 22px;
                height: 22px;
                margin-right: 20px;
            }

            b{
                font-size: 20px;
            }
        }

        ul {
            padding: 10px 20px;
            margin-left: 23px;
            width: max(70%, 300px);
        }

        ul > li {
            list-style: none;
            margin-bottom: 10px;
            display: flex;

            p{
                position: relative;
                margin-left: 5px;
            }

            /* p[title]:hover:after{
                content:attr(title);
                background-color: ${props => props.theme.color.blackColor};
                position: absolute;
                padding: 5px;
                padding-bottom: 5px;
                margin: 10px;
                color:#ffffff;
                display: flex;
                width: 200px;
                font-size: .7rem;
                border-radius: 5px;
                box-shadow: 1px 1px 5px 2px rgba(0,0,0,.5);
            } */
        }
    }

    @media(max-width: 1036px){
        padding-top: 0px;
    }
`;

export const GridCenter2 = styled.div`
    grid-row: 3/4;
    grid-column: 2/3;
    padding: 0px 40px;

    div {
        display: flex;
        flex-direction: column;
        min-height: 180px;

        span {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                width: 22px;
                height: 22px;
                margin-right: 20px;
            }

            b{
                font-size: 20px;
            }
        }

        ul {
            padding: 10px 20px;
            max-width: 450px;
            height: 190px;      
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            font-size: 14px;
        }

        ul li {
            list-style: none;
            margin-bottom: 20px;
            display: flex;
            width: 180px;
            justify-content: space-between;
            align-items: flex-start;
            
            b {
                margin-left: 23px;
            }
        }
    }

    @media(max-width: 1036px){
        margin-top: -70px;
        padding: 0px;

        div {
            width: 100%;

            ul{
                margin-right: 200px;
                justify-content: start;
                align-items: flex-start;
            }

            ul li {
                justify-content: start;
                b {
                    margin-right: 20px;
                }
            }
        }
    }

    @media(max-width: 546px){
        div {
            ul {
                flex-wrap: nowrap;
                height: 300px;
                align-items: center;
                margin-top: 20px;
                margin-right: 0px;
            }
            ul li {
                justify-content: start;
                b {
                    margin-right: 20px;
                }
            }
        }
    }
`;

export const GridAside = styled.aside`
    grid-row: 2/4;
    grid-column: 3/5;
    width: 100%;
    margin-left: -40px;

    div {
        display: flex;
        flex-direction: column;
        justify-content: start;
        justify-content: flex-end;
        text-align: right;
        flex-wrap: wrap;
        padding: 10px 40px;

        > p{
            text-align: start;
            margin-bottom: 0px;
            font-size: 13px;
            max-width: 300px;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                width: 22px;
                height: 22px;
                margin-right: 20px;
            }

            b{
                font-size: 20px;
            }
        }

        img {
            height: 280px;
            width: 280px;
            filter: blur(2px);
            margin-top: 5px;
            box-shadow: 1px 2px 8px rgba(0,0,0,0.5);
        }
    }

    @media(max-width: 1146px){
        grid-row: 4/5;
        grid-column: 2/3;
        margin-left: 0px;
        padding-top: 10px;

        > div {
            p{
                text-align: start;
                margin-left: 40px;
            }

            img {
                margin-left: 40px;
            }
        }   
    }

    @media(max-width: 1036px){
        div {
            margin-top: -30px;

            img {
                margin-left: 10px;
            }
        }
    }

    @media(max-width: 546px){
        margin-top: 0px; 
        > div {
            img {
                width: 180px;
                height: 180px;
            }
        }   
    }

    @media(max-width: 364px){
        margin-top: 0px; 
        > div {
            padding: 0 0 20px 0;
            img {
                width: 130px;
                height: 130px;
            }

            p {
                font-size: 12px;
                max-width: 150px;
            }
        }   
    }
`;

export const GridFooter = styled.footer`
    grid-column: 1/5;
    grid-row: 4/5;   
    display: flex;
    justify-content:center;
    align-items: center;
    padding-top: 40px;

    p {
        margin: 0;
        padding: 0;
        color: black;
        font-weight: 500;
        cursor: pointer;
        transition: .3s;

        span{
            color: ${props => props.theme.color.buttonColor};
            transition: .3s;
        }

        &:hover{
            color: ${props => props.theme.color.buttonColor};
        }
    }

    @media(max-width: 1146px){
        grid-row: 4/5;
        grid-column: 2/3;
        margin-left: -150px;
        margin-top: 430px;  
        padding-bottom: 50px;
    }

    @media(max-width: 1036px){
        margin-left: 20px;
        margin-top: 50px; 
    }

    @media(max-width: 846px){
        margin-left: 0;
        margin-top: 10px;
    }

    @media(max-width: 364px){
        p {
            font-size: 12px;
        }
    }
`;

export const TextCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 70vh;
`;