import styled, { keyframes } from 'styled-components';

export const ContentFlex = styled.div`
    max-width: 1200px;
    border-top: 2px solid white;
    position: relative;

    @media(min-width: 731px){ 
        margin: 20px;
    }
`

export const H1 = styled.h1`
    padding-left: 50px;
    margin-top: 40px;
    width: 100%;
    margin-bottom: 20px;
`

export const Items = styled.section`
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    @media(min-width: 731px){
        margin: 0 10px 0 30px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    @media(max-width: 769px){
        margin: 0 10px;
    }

    @media(max-width: 730px){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 30px;
    }

    @media(max-width: 429px){
        margin: 0;
    }
`

export const Item1 = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    max-width: 380px;
    min-width: 310px;
    min-height: 270px;
    flex: 1 1 350px;
    margin: 10px;
    border-radius: 5px;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    font-size: .8rem;

    @media(min-width: 900px){
        max-width: 500px;
    }

    @media(max-width: 879px){
        min-width: 300px;
        flex: 1 1 300px;
    }

    @media(max-width: 730px){
        width: 100%;
        flex: 1;
    }
`
export const Div1 = styled.div`
    display: flex;
    overflow: hidden;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: .1rem solid #D4D4DB33;
    font-size: 1.2rem;
    > div {
        display: flex;
        align-items: center;
    }
`

export const Div2 = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    /* margin-top: 30px; */
    flex-wrap: wrap;

    .div1 {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-top: 20px;
        width: 50%;

        b {
            font-size: 18px;
        }

        span {
            color: ${props => props.theme.color.buttonColor};
            font-weight: bold;
            font-size: 30px;
        }

        @media(max-width: 821px) and  (min-width: 731px) {
            span {
                font-size: 26px;
            }
        }

        @media(max-width: 365px) {
            span {
                font-size: 26px;
            }
        }
    }   

    > button {
        margin-top: 30px;
        cursor: pointer;
        max-width: 200px;
        background: black;
        color: white;
        height: 40px;
        border-radius: 20px;
        transition: .3s;
        outline: none;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        &:hover{
            filter: brightness(150%);
        }
    }

    > p a {
        text-decoration: none;
        color: black;
    }

    @media(max-width: 1008px){
        align-items: center;
        justify-content: center;

        div{
            width: 100%;
        }
    }

    .div2 {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        text-align: end;

        p {
            font-size: 12px;
            margin: 0
        }

        span{
            font-weight: bold; 
        }
    }
`

interface AutoRechargeProps {
    isLoading: boolean
}

export const AutoRecharge = styled.div<AutoRechargeProps>`
    padding: 20px;
    display: flex;
    align-items: ${props => props.isLoading ? "center" : "flex-start"};    
    justify-content: ${props => props.isLoading ? "center" : "pace-between"};
    flex-direction: column;
    flex-wrap: wrap;    
    border-top: 1px solid rgba(200, 200, 200, .6);
    margin-top: 20px;

    > div:not(.changeValue) {
        //flex: 1 1 1 100px;        

        > p {
            cursor: pointer;
            border: 1px solid transparent;
            padding: 5px 5px;
            border-radius: 5px;
            color: #f4f4f4;
            background-color: ${props => props.theme.color.redColor};            
        }

        > span {
            font-weight: 700;
            margin-bottom: 10px;
        }

        > span > b {
            margin-bottom: 10px;
            color: ${props => props.theme.color.blueColor};

            &:not(.activate) {
                color: ${props => props.theme.color.redColor};
            }
        }

        @media(max-width: 1100px){
        }

        @media(max-width: 730px){
            display: flex;
            flex-direction: column;
        }
    }
`;

export const ChangeValue = styled.div`
    height: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 15px;
    color: ${props => props.theme.color.blackColor};
    cursor: pointer;    
    transition: .3s;
    position: relative;

    &:hover {
        color: ${props => props.theme.color.blueColor}
    }

    > select {
        position: absolute;
        top: 23px;
        width: 100%;
        border: none;
    }

    > p:has(span) {
        background-color: ${props => props.theme.color.blueColor};
        color: #f4f4f4;
        padding: 5px 3px;
        border-radius: 5px;
        text-align: center;
        font-size: .7rem;
        font-weight: 600;
    }
`;

export const Div3 = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: .1rem solid #D4D4DB33;

    > div {
        display: flex;
        flex-direction: column;

        span {
            color: ${props => props.theme.color.buttonColor};
            font-size: 24px;
        }

        @media(max-width: 400px){
            span {
                font-size: 20px;
            }
        }
    }
`

export const Item2 = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 380px;
    min-width: 310px;
    background: white;
    margin: 10px;
    padding: 20px 30px 20px 20px;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 240px;
    border-radius: 5px;
    flex: 1 1 350px;    

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;

        svg {
            height: 30px;
            width: 30px;
            cursor: pointer;
        }

        svg:last-of-type{
            margin-left: -10px;
        }

        article{
            margin: 20px;
            font-size: .8rem;
            b > span{
                font-weight: normal;
                color: black;
                margin-bottom: 10px;
                cursor: pointer;
            }

            span {
                color: ${props => props.theme.color.blueColor};
                font-weight: bold;
            }

            p{
                color: #303030;
            }
        }
    }

    @media(min-width: 900px){
        max-width: 500px;
    }

    @media(max-width: 879px){
        flex: 1 1 300px;
    }

    @media(max-width: 730px){
        width: 100%;
    }
`

export const Item3 = styled.div`
    display: flex;
    background: white;
    max-width: 380px;
    min-width: 310px;
    min-height: 240px;
    flex: 1 1 400px;
    border-radius: 5px;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 10px;
    padding: 10px;
    font-size: .7rem;    

    b {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    div{
        display: flex;
        flex: 1 1 100px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        /* width: 100%; */
        p{
            color: #171B29;
            margin: 8px;
        }
    }

    @media(min-width: 900px){
        max-width: 500px;
        min-height: 280px;
    }

    @media(max-width: 879px){
        flex: 1 1 300px;
    }

    @media(max-width: 730px){
        width: 100%;
    }

    @media(max-width: 361px){
        font-size: .6rem;
    }
`

export const Item4 = styled.div`
    display: flex;
    background: white;
    max-width: 380px;
    min-width: 310px;
    min-height: 280px;
    flex: 1 1 400px;
    flex-direction: column;
    font-size: .7rem;
    padding: 20px;
    margin: 10px;
    
    b{
        margin-bottom: 20px;
    }

    img{
        /* max-width: 380px; */
        filter: blur(2px)
    }

    @media(min-width: 900px){
        max-width: 500px;
    }

    @media(max-width: 879px){
        min-width: 300px;
        flex: 1 1 350px;
    }
    
    @media(max-width: 730px){
        width: 100%;
    }
`

export const Toggle = styled.div`
    width: 35px;
    height: 20px;
    border-radius: 40px;
    border: 1px solid black;
    position: relative;
    margin-left: 20px;
`;

export const CircleToogle = styled.div`
    border-radius: 50%;
    background: ${props => props.theme.color.buttonColor};
    width: 14px;
    height: 14px;
    position: absolute;
    right: 1.8px;
    bottom: 1.3px;
`;

interface ModalProps {
    modal: any
}

const modalShow = keyframes`
    from{
        top: 500px;
        opacity: .5;
    } to {
        opacity: 1;
        top: 0px;
    }
`;

const modalOut = keyframes`
    from{
        top: 0px;
        opacity: 1;
    } to {
        top: 800px;
        opacity: .5;
    }
`;

export const Modal = styled.section<ModalProps>`
    position: fixed;    
    left: 0;
    width: 100vw;
    height: 100%;
    background: #171B29CC;
    z-index: 1000;
    margin: 0 auto;
    display: ${props => props.modal === 'hide' ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    
    animation: ${props => props.modal === true ? modalShow : modalOut} .3s ease-out forwards ;
`;


export const ModalContent = styled.div`
    position: relative;
    width: max(320px, 80%);
    background: white;
    border-radius: 10px;
    height: min(80%, 700px);
    overflow-y: none;
    padding-bottom: 30px;

    > div > div:hover:not(.content) {
        transition: .3s;

        &:hover{
            transform: scale(1.2) !important;
        }
    }

    .title {
        margin-top: 30px;
        margin-left: 50px;

        b {
            font-size: 1.2rem;
        }

        p {
            margin-top: 10px;
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            @media (max-width: 460px) {
                padding: 20px;
                span { 
                    margin-bottom: 20px;
                }
            }
        }

        .content:has(span, p) {
            margin-top: 150px;

            @media (max-width: 760px) {
                margin-top: 30px;
            }
        }

        .content span {
            height: 50px;
            width: 100px;
            background-color: #f4f4f4;
            border: 1px solid ${props => props.theme.color.blackColor};
            border-radius: 5px;
            line-height: 50px;
            margin-right: 30px;
            cursor: pointer;
            box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
        }

        .content p {
            height: 50px;
            width: 200px;
            background-color: ${props => props.theme.color.redColor};
            border: 1px solid ${props => props.theme.color.redColor};
            border-radius: 5px;
            margin-top: 0;
            line-height: 50px;
            color: #ffffff;
            cursor: pointer;
            box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
        }

        @media(max-width: 570px){
            margin-right: 0px;
            margin-top: 20px;
            width: 80%;
            height: 180px;
        }        
    }

    .footer {
        margin-top: 100px;
        font-size: medium;
    }

    .content{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 70px;

        @media(max-width: 900px){
            flex-wrap: wrap;
        }
    }

    @media(max-width: 900px){
        overflow-y: scroll;
    }
`;

export const Close = styled.div`
    position: absolute;
    right: 20px;
    top: 15px;
    background: black;
    font-weight: bold;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .5s;

    :hover{
        filter: brightness(150%);
    }
`;

interface MessageProps {
    success: boolean,
    status: any
}

const animeIn = keyframes`
    from {
        right: -150px;
    } to {
        right: 30px;
    }
`

const animeOut = keyframes`
    from {
        right: 30px;
    } to {
        right: -300px;
    }
`

export const Message = styled.div<MessageProps>`
    display: ${props => props.status === false ? 'none' : 'flex'};
    position: fixed;
    animation: ${props => props.status === 'hide' ? animeOut : animeIn} 1s ease-out forwards;
    top: 80px;
    right: 50px;
    width: 300px;
    align-items: center;
    justify-content: start;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid ${props => props.success === false ? '#772e25' : "#10451d"};
    background: ${props => props.success === false ? '#c44536' : "#1a7431"};
    height: 80px;
    color: white;
    box-shadow: 2px 3px 8px rgba(0,0,0,0.6);
`

const anime = keyframes`
    to {
        transform: rotate(360deg);
    }
`

export const Spin = styled.div`
    border: 5px solid rgba(0,0,0, .1);   
    border-left-color: ${props => props.theme.color.blackColor}; 
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation: ${anime} 1s linear infinite;
`;