import styled, { keyframes } from 'styled-components';

export const Header = styled.header`
    height: 60px;
    background: ${props => props.theme.color.blackColor};
    width: 100%;
    z-index: 1000;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 0 30px;

    .bars {
        
        @media(min-width: 500px){
            display: none;
        }
    }

    @media(max-width: 500px){
        padding: 0 15px;
        font-size: .8rem;
    }
`;

export const DivLogo = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    img{
        margin-right: 10px;
    }

    p{
        font-weight: 200;
    }

    @media(max-width: 500px){
        p{
            font-size: .8rem;
        }

        img {
            height: 25px;
            width: 25px;
        }
    }
`;

export const DivInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    font-size: .8rem;
    font-weight: 200;
    flex-wrap: wrap;

    span{
        margin-right: 50px;
        overflow: hidden;
    }

    div{
        cursor: pointer;
        display: flex;
        align-items: center;
        
        p{
            margin-right: 8px;
        }
    }    

    @media(max-width: 500px){
        /* span {
            margin-right: 20px;
        } */

        display: none;
    }

    /* @media(max-width: 380px){
        p, span{
            margin-right:0;
        }
        span{
            margin-bottom: 5px;
            font-weight: bold;
        }

        flex-direction: column;
        align-items: flex-end;
    } */
`;

const showsMenu = keyframes`
    from {
        top: -150px;
    }
    to {
        top: 0;
    }
`;

const hideMenu = keyframes`
    from {
        top: 0;
    }
    to {
        top: -130px;
    }
`

interface MenuMobileProps {
    action: any
}

export const MenuMobile = styled.menu<MenuMobileProps>`
   display: none;

   @media(max-width: 500px){
        display: ${props => props.action === "hide" ? 'none' : 'flex'};
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 130px;
        width: 100%;
        padding: 30px;
        background: rgba(23, 24, 41, .98);
        position: absolute;
        top: -150px;
        left: 0;
        box-shadow: 10px 5px 8px 4px rgba(0,0,0,0.5);
        animation: ${props => props.action === true.toString() ? showsMenu : hideMenu} .5s ease-out forwards;

        .icon {
            position: absolute;
            right: 15px;
            top: 25px;
        }

        span {
            font-weight: bold;
            letter-spacing: 0.05rem;
            font-size: .9rem;
        }

        div {
            display: flex;
            align-items: center;
            margin-top: 15px;

            

            p {
                margin-right: 5px;
            }
        }
   }
`;