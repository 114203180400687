import styled, { css, keyframes } from 'styled-components';

export const Container = styled.section``

export const BodyContent = styled.div`
    padding-top: 5rem;
    text-align: center;
    margin: 0 auto;
    max-width: 1200px;
    min-height: calc(100vh - 5rem);    

    > p {
        font-weight: bold;
        margin-bottom: 35px;
    }
`;

export const PaymentContent = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;    
    
    > p {
        border: 1px solid black;
        padding: 5px;
        max-width: 350px;
        border-radius: 4px;
        font-weight: 600;
        box-shadow: 2px 2px 7px rgba(0,0,0,.3);
    }

    > div {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    > div span {
        margin-right: 10px;
    }

    > div select {
        border: none;
        outline: none;
        min-width: 200px;
    }

    > div svg {
        fill: blue;
        height: 15px;
        cursor: pointer;
        margin-left: 10px;
    }
`;

interface PaymentModeProps {
    isCreditCard: boolean
}

export const PaymentMode = styled.div <PaymentModeProps>`
    background-color: #fff;
    margin-top: 10px;
    max-width: 500px;
    height: ${props => props.isCreditCard ? '650px' : '400px'};
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0,0,0,.4);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    transition: .2s;
`;

export const PaymentModeHeader = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
`;

interface PaymentModeHeaderItemProps {
    select: boolean
}

export const PaymentModeHeaderItem = styled.div <PaymentModeHeaderItemProps>`
    flex: 1;
    background-color: ${props => props.select ? props.theme.color.buttonColor : props.theme.color.tinWhite};    
    display: flex;
    align-items: center;
    justify-content: center;
    color:  ${props => props.select ? '#fff' : props.theme.color.blackColor};
    height: 100%;
    font-size: .9rem;
    font-weight: 600;
    transition: .3s;
    cursor: pointer;
    
    &:first-child {
        border-top-left-radius: 10px;
    }

    &:last-child {
        border-top-right-radius: 10px;
    }
`;

export const PaymentModeBody = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;   
`;

export const PaymentModeCreditCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 20px;

    > p {
        margin: 20px;
    }
`;

export const ContentInputs = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
        position: relative;
        
        > input {
            width: 280px;
            height: 30px;
            margin: 10px 0;
            box-shadow: 2px 2px 4px rgba(0,0,0,.4);
            border: 2px solid ${props => props.theme.color.redAccentColor};
            outline: none;
            padding-left: 10px;

            ::placeholder {
                color: #ccc;
                opacity: 1;
            }

            &:not(.hasError) {
                border-color: transparent;
            }
        }

        > input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        > img {
            position: absolute;
            right: 5px;
            height: 25px;
            bottom: 12px;
        }
`;

export const PaymentModePix = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 30px;
    height: 100%;    
`;

export const Button = styled.button`
    background-color: ${props => props.theme.color.buttonColor};
    height: 35px;
    min-width: 200px;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    outline: none;
    color: #fff;
    font-weight: 600;
    border-radius: 3px;
    font-size: 1rem;
    box-shadow: 2px 2px 5px rgba(0,0,0,.5);
    transition: .3s;

    &:hover {
        filter: brightness(110%);
    }
`;

export const RecuringPayment = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 20px;
    margin-bottom: 20px;

    > small {
        width: 80%;        
        font-size: .7rem;
        text-align: start;
        letter-spacing: .6px;
    }
`;


interface CheckboxProps {
    selected: boolean
}

export const Checkbox = styled.div <CheckboxProps>`
    height: 18px;
    width: 18px;
    border-radius: 3px;
    border: 2px solid ${props => props.theme.color.blueColor};
    margin: 20px;
    display: flex; 
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: .3s;
    background-color: ${props => props.selected ? props.theme.color.blueColor : props.theme.color.whiteColor};

    
    &::after {        
        content: "✔";    
        position: absolute;
        color: white;
        bottom: -1.5px;        
        left: 2px;
        border-radius: 2px;
        animation: ${props => (props.selected ? css`${enableCheckbox} .3s ease-in-out forwards` : css`${unableCheckbox} .3s ease-in-out forwards`)} ;
    }    
`;

const enableCheckbox = keyframes`
    from {        
        font-size: 0;
    }

    to {        
        font-size: 14px;
    }
`;

const unableCheckbox = keyframes`
    from {        
        font-size: 14px;
    }

    to {        
        font-size: 0px;
    }
`;

export const Footer = styled.div`
    width: 100%;
    background-color: ${props => props.theme.color.blackColor};
    height: 70px;
    color: white;
    display: flex;
    line-height: 70px;
    align-content: center;
    justify-content: center;
    font-size: 1.5rem;
    position: sticky;
    bottom: -70px;
    left: 0;
    margin-top: 1rem;

    > span {
        position: absolute;
        left: 30px;
        font-size: 1rem;
        padding: 0 10px;
        cursor: pointer;
        transition: .3s;
    }
`;

const showError = keyframes`
    from {
        right: -250px;
    } to{
        right: 0px;
    }
`;

export const Error = styled.aside`
    position: fixed;
    background: #dd5554;
    min-height: 80px;
    width: 300px;
    color: white;
    top: 6rem;
    right: 0px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-shadow: 2px 1px 1px rgba(0,0,0,.5);
    animation: ${showError} 2s forwards;

    > span {
        width: 15px;
        height: 3px;
        background-color: #fff;
        position: absolute;
        right: 5px;
        top: 15px;
        border-radius: 3px;
        transform: rotate(45deg);    
        cursor: pointer;
        
        &::after {
            content: '';
            position: absolute;
            background-color: inherit;
            width: inherit;
            height: inherit;
            transform: rotate(-90deg);
        }
    }
`;

export const Loading = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(230, 230, 230, .7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    > div {
        width: 300px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #fff; 
        box-shadow: 2px 2px 5px rgba(0, 0, 0, .4);
        border-radius: 5px;

        > p {
            margin-top: 10px;
         }
    }
`;