import { FC } from 'react'
import { LoadScreen, Spin } from './styles'

interface LoadProps {
    message: string
}

const index:FC<LoadProps> = (props) => {
    return (
            <LoadScreen>
                <Spin />
                {props && <p>{props.message}</p>}
            </LoadScreen>
        
    )
}

export default index
