import { Div1, H1, Item1, Item2, Items } from '../../SectionTwo/styles'
import PlaceholderAd from '../PlaceholderAd'
import PlaceholderCredit from '../PlaceholderCredit'
import { PlaceholderComponentCreditAd } from './styles'

const index = () => {
    return (
        <PlaceholderComponentCreditAd>
            <H1>Informações da Campanha</H1>
            <Items>
                <Item1>
                    <Div1>
                        <b>Orçamento</b>
                    </Div1>
                    <PlaceholderCredit /> 
                </Item1>
                <Item2>
                    <b>Meus anúncios</b>
                    <PlaceholderAd />
                </Item2>
            </Items>
    </PlaceholderComponentCreditAd>
    )
}

export default index
