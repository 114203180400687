import { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Card, Star, CardTitle, CardContent } from "./styles";
import { ReactComponent as StarSvg } from '../../../images/Icon material-star.svg'

interface CardPlansProps {
    isRecommended: boolean,
    create: (amount: number) => void,
    cardTitle: string,
    price: number,
    totalPrice: string,
    spendDay: number,
    fee: string
}

export default function CardPlans(props: CardPlansProps) {

    const [tooltip, setTooltip] = useState<boolean>(false)
    const [scale, setScale] = useState<boolean>(true);

    return (
        <Card scale={scale.toString()} onMouseEnter={() => setScale(false)} onMouseLeave={() => setScale(true)}>
            {props.isRecommended && <Star>
                <span>Recomendado</span>
                <StarSvg height="45" fill="#FACA00" />
            </Star>}
            <CardTitle><b>{props.cardTitle}</b></CardTitle>
            <CardContent>
                <p><b>{props.price}</b>,00</p>
                <div>
                    <b>Taxa operacional <FaInfoCircle className="info" onMouseEnter={() => setTooltip(true)} onMouseLeave={() => setTooltip(false)} /></b>
                    <small>5% - R$ {props.fee}</small>
                    {tooltip && <div className="tooltip-info"><p>Será descontado 5% sobre o valor de cada recarga para custos operacionais.</p></div>}
                </div>
                <div>
                    <b>Crédito Ads</b>
                    <small>R$ {props.totalPrice}</small>
                </div>
                <small>Aproximadamente <br /><b>R$ {props.spendDay},00</b> por dia</small>
                <button id="buttonPro" className="buttonPro" onClick={() => props.create(props.price)}>Selecionar</button>
            </CardContent>
        </Card>
    )
}