import { FC } from 'react'
import { Group } from './styles'
import InfoCompany from '../InfoCompany'
import GoogleAds from '../GoogleAds'
import Investment from '../Investment'
import Finished from '../Finished'

interface GroupProps {
    step: Number,
    next: () => void
}

const Index:FC<GroupProps> = (props) => {

    if(props.step === 1) 
    return (
        <Group>
            <InfoCompany />
        </Group>
    )

    if(props.step === 2) 
    return (
        <Group>
            <GoogleAds />
        </Group>
    )

    if(props.step === 3)
    return (
        <Group>
            <Investment next={props.next}/>
        </Group>
    )

    return (
        <Group>
            <Finished />
        </Group>
    )
}

export default Index
