import {useState, useEffect} from 'react'
import { useNavigate, useParams } from 'react-router';
import { Content, BarCode, Header, Section, Pix, CopyText, DivStep, Info } from './styles';
import Logo from '../images/icons/whitelogo.png';
import PixSvg from '../images/icons/pix.svg';
import { FaCopy, FaCreditCard } from 'react-icons/fa';
import { Circle1 } from '../Campaing/Shared/Steps/styles';
import { IoMdExit } from 'react-icons/io';
import { PAYMENT_DETAILS } from '../api';
import LoadScreen from '../Campaing/Shared/Load/';

const CampaignOrder = () => {

    const [copy, setCopy] = useState<boolean>(false)
    const [error, setError] = useState<any>()
    const [data, setData] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    let { id } = useParams();
    const navigate = useNavigate();

    function saveUrl(){
        const url = window.location.href;
        var listUrl = url.split('/')
        window.localStorage.setItem('url', listUrl[3] + '/' + listUrl[4]);
        window.localStorage.removeItem('token');
        navigate('/login');
    }
    
    function copyText(text: string){
        setCopy(true);
        navigator.clipboard.writeText(text)
        setTimeout(() => {
            setCopy(false)
        }, 2000)
    }

    function exit(){
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        navigate('/login')
    }

    useEffect(() => {
        async function getPaymentData(){
            let response: any;
            let json: any;
            setLoading(true)

            try {
                response = await fetch(`${PAYMENT_DETAILS}/${id}/Details`, {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('token')}`
                    }
                })

                if(response?.ok){
                    json = await response.json();                    
                    
                    if(json.content.paymentStatus === "Confirmed"){
                        window.localStorage.removeItem('url')
                        navigate('/dashboard')
                    } else {
                        setData(json)
                    }
                } else if(response?.status === 401 || response?.status === 403){
                    saveUrl();
                    navigate('/login')
                } else if(response?.status === 404){
                    setError("Não foi possível encontrar os dados do pagamento!")
                } else {
                    setError("Ocorreu um erro ao buscar as informações de pagamento!")
                }
            } catch(e: any){
                setError("Ocorreu um erro ao obter as informações.")
                if(response?.status === 401 || response?.status === 403){
                    saveUrl();
                    navigate('/login')
                }
            } finally {
                setLoading(false)
            }
        }

        getPaymentData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <Content>
            {copy && <CopyText><p>Texto copiado para área de transferência!</p></CopyText>}
            <Header>
                <div onClick={() => navigate('/')}>
                    <img src={Logo} height="30" alt="Logo Consumer"/>
                    <p>Consumer <b>Ads</b></p>
                </div>
                <div className="out">
                    <span>Olá! {window.localStorage.getItem('user')}</span>
                    <div onClick={() =>  exit()} className='removeSelection'>
                        <p>Sair</p>
                        <IoMdExit size="20px"/>
                    </div>
                </div>
            </Header>
            <DivStep>
                <Circle1 step={1}><FaCreditCard /> </Circle1>
                <p>Pagamento</p>
            </DivStep>
            {loading ? <LoadScreen message="Obtendo informações para pagamento"/> : error ? <Section><p>{error}</p></Section> : data ?
            
            <Section>
                <h3>Pedido Realizado! Agora é só pagar via Pix ou boleto.</h3>
                
                <small>O valor para recarga selecionado é <b>R$ {data.content.originalAmount.toFixed(2).replace('.', ',')}</b> e a identificação do seu pedido é: <b>{id}</b></small>
                
                <p>Assim que confirmado o pagamento, seus anúncios já estarão em exibição, você poderá acompanhar os resultados pelo nosso dashboard!</p>
                <Pix>
                    <b>Pagamento via Pix</b>
                    <small>Escaneie o QRCode abaixo pelo app do seu banco</small>
                    <img src={data.content.pix.pixImageInBase64} height="30" alt="PIX QrCode"/>
                    <img src={PixSvg} alt="Logo do Pix" style={{height: '30px'}}/>
                </Pix>
                <BarCode>
                    <b>Pagamento via boleto</b>
                    <small>Utilize o número abaixo ou clique no link para visualizar o boleto</small>
                    <div>
                        <p>{data.content.bankSlip.payNumber}</p>
                        <FaCopy onClick={() => copyText(`${data.content.bankSlip.payNumber}`)}/>
                    </div>
                    <button onClick={() => window.open(`${data.content.bankSlip.link}`, 'blank')}>Clique para visualizar o boleto</button>
                </BarCode>
                <Info>
                    <p>Sua campanha será recarregada assim que o pagamento for confirmado.</p>
                    <br />
                    <p>O prazo para confirmação do pagamento é:</p>
                    <ul>
                        <li>Para Pix: até 24h</li>
                        <li>Para Boleto: até 3 dias úteis</li>
                    </ul>
                </Info>
                <small>Já realizou o pagamento?</small>
                <br />
                <button onClick={() => navigate('/dashboard')}>Analisar resultados</button>
            </Section> 
            : null
            }
            
        </Content>
    )
}

export default CampaignOrder