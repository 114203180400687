/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect, FC, useMemo } from 'react'
import { Aside, AsideItem1, AsideItem2, AsideItem3, AsideItem4, TooltipDiv, ContentGrid, Footer, FooterItem1, FooterItem2, FooterItem3, Graph, ItemTitle1, ItemTitle2, ItemTitle3, ItemTitle4, Title, Menu, MobileMenu, ContentMenu, LineVertical, LineHorizontal, CircleLine, Select, DateCustom, DateCustomMobile, ErrorSection } from './styles'
import { FaCaretDown, FaCaretUp, FaCalendarAlt, FaEye, FaMousePointer, FaExchangeAlt, FaDollarSign, FaTimes, FaBars } from 'react-icons/fa'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DATA_CAMPAIGN, GET_CAMPAIGN } from '../../../api';
import { useNavigate } from 'react-router-dom';
import { formatRelative, subDays } from 'date-fns';
import { format } from "date-fns-tz";
import PlaceholderMetrics from '../Shared/PlaceholderMetrics';

const Index: FC = () => {
    const [index, setIndex] = useState<any>(0);
    const [showAds, setShowAds] = useState<boolean>(false);
    const [inputImp, setinputImp] = useState(false);
    const [inputClick, setinputClick] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [status, setStatus] = useState(true);
    const [hideMenu, setHideMenu] = useState<any>(null);
    const [dataGraph, setDataGraph] = useState<boolean>(true);
    const [arrData, setArrData] = useState<any>([]);
    const [showDates, setShowDates] = useState<boolean>(false);
    const [dateCustom, showDateCustom] = useState<boolean>(false);
    const [dateCustomMobile, showDateCustomMobile] = useState<boolean>(false);
    const [campaignData, setCampaignData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [dateStart, setDateStart] = useState<any>(null);
    const [dateEnd, setDateEnd] = useState<any>(null);
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate();

    let arr: any[] = useMemo(() => { return [] }, []);

    // chamada da API para obter os dados da campanha
    async function getCampaignData(dateStart: string, dateEnd: string) {
        let response;
        let json;
        try {
            // esta chamada é para verificar se o usuário tem uma campanha, caso não tenha, será redirecionado para a tela de criar
            response = await fetch(`${DATA_CAMPAIGN}/${dateStart}/${dateEnd}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })


            if (response.status === 401 || response.status === 403) {
                navigate('/login');
                return;
            }

            json = await response.json()

            if (response.status === 500) {
                setError(json.error_message)
            }

            if (json.success === false && json.error_message.includes("haven't an account")) {
                setLoading(false)
                setShowDates(false)
                navigate('/criar')
            } else if (json.success === false) {
                setLoading(false)
                setError(json.error_message)
            }

            // se não teve nenhuma métrica, esta chamada é feita para obter os dados necessários e depois realizamos o seed da campanha com dados zerados
            else if (json.content.length < 1) {
                let response = await fetch(GET_CAMPAIGN, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('token')}`
                    }
                })

                let body = await response.json()

                seedCampaign(body);
                setLoading(false)
            } else {
                setCampaignData(json)
                setShowDates(false)
                setMenuOpen(false)
                setLoading(false)
                window.localStorage.setItem('userAdsAccountId', json.content[0].userAdsAccountId)
            }

        } catch (e: any) {
            setLoading(false)
            if (e.message === 'AbortError') {
            } else {
                setError(e.message)
            }
        }
    }

    // inserindo dados zerados na campanha quando a campanha não teve nenhuma metrica
    function seedCampaign(body: any) {
        let content = body['content'];
        window.localStorage.setItem('userAdsAccountId', content["userAdsAccountId"]);

        let dataCampaign = {
            success: true,
            error_message: "",
            content: [
                {
                    userAdsAccountId: content["userAdsAccountId"],
                    creditAvaiable: 0,
                    googleCampaingId: content["campaignId"],
                    campaingName: content["adGroup"]["name"],
                    impressions: 0,
                    clicks: 0,
                    conversions: 0,
                    costPerConversion: 0,
                    conversionRate: 0,
                    invoice: 0,
                    averageTicket: 0,
                    keywords: [
                        ""
                    ],
                    ads: [
                        {
                            url: content["ads"][0]["finalUrls"],
                            title: `${content["ads"][0]["headlinePart1"]} ${content["ads"][0]["headlinePart2"]} ${content["ads"][0]["headlinePart3"]}`,
                            description: `${content["ads"][0]["description"]} ${content["ads"][0]["description2"]}`
                        },
                        {
                            url: content["ads"][1]["finalUrls"],
                            title: `${content["ads"][1]["headlinePart1"]} ${content["ads"][1]["headlinePart2"]} ${content["ads"][1]["headlinePart3"]}`,
                            description: `${content["ads"][1]["description"]} ${content["ads"][1]["description2"]}`
                        }
                    ],
                    metrics: [
                        {
                            impressions: 0,
                            clicks: 0,
                            conversions: 0,
                            costPerConversion: 0,
                            cost: 0,
                            date: `${new Date()}`
                        }
                    ]
                }
            ]
        }
        setCampaignData(dataCampaign)
        setShowDates(false)
        setMenuOpen(false);
        setLoading(false)
    }


    // definindo a data para chamar a função e obter as métricas
    async function defineDate(days: any) {
        setLoading(true)
        let date = Date.now();
        const lastDate = format(date, 'yyyy-MM-dd');
        const initialDate = formatRelative(subDays(date, days), new Date())
        const dateStarted = format(Date.parse(initialDate), 'yyyy-MM-dd')
        window.localStorage.setItem("searchedDays", days);

        getCampaignData(dateStarted, lastDate);
    }

    // inserindo valor no input do gráfico
    const handleClick = useCallback(() => {
        setinputImp(!inputImp);
        setinputClick(!inputClick);

    }, [inputImp, inputClick],
    )

    // adicionando o 0 quando o dia for menor de 10
    function setDay(date: string) {

        let d = new Date(date);
        let day = d.getDate();
        let month = d.getMonth() + 1;
        if (day < 10) {
            if (month < 10)
                return `0${day}/0${month}`;
            return `0${day}/${month}`;

        } else {
            if (month < 10)
                return `${day}/0${month}`;
            return `${day}/${month}`;
        }
    }

    // inserindo os dados no gráfico
    const seedDataGraph = useCallback(() => {
        arr = [];
        if (campaignData !== null && campaignData.content !== null) {
            campaignData.content[index].metrics.map((item: any) => (
                arr.push({ impressoes: item.impressions, cliques: item.clicks, day: setDay(item.date) })
            ))
            if (arr.length > 0) {
                setArrData(arr)
                setDataGraph(false)
            }
        }
    }, [arr, index, campaignData])

    // modificando o tooltip do gráfico
    function CustomToolTip({ active, payload, label }: any) {
        if (active) {
            return (
                <TooltipDiv isImp={inputImp}>
                    <p>Dia: <b>{label}</b></p>
                    <p>{inputClick ? 'Cliques: ' : 'Impressões: '}<b>{payload[0].value}</b></p>
                </TooltipDiv>
            )
        }

        return null;
    }

    // essa função será chamada quando alterar a dataEnd para chamar a API
    function customPeriod() {
        if (dateEnd !== null && dateStart !== null) {
            showDateCustom(false)
            showDateCustomMobile(false)
            setShowDates(false)
            setLoading(true)
            var currentDateStart = Date.parse(`${dateStart} 03:00:00`);
            var currentDateEnd = Date.parse(`${dateEnd} 23:59:59`);
            window.localStorage.setItem("searchedDays", `De ${new Date(currentDateStart).toLocaleDateString()} até ${new Date(currentDateEnd).toLocaleDateString()}`);
            getCampaignData(dateStart, dateEnd);
        }
    }

    // esse useEffect será chamado quando tiver dados na campanha
    useEffect(() => {
        if (campaignData != null)
            seedDataGraph()
    }, [campaignData])

    // esse useEffect será chamado quando o index mudar
    useEffect(() => {
        window.scroll({ top: 0, behavior: 'smooth' })
    }, [index])

    // esse useEffect será chamado sempre que a tela carregar
    useEffect(() => {
        setinputImp(true);
        setStatus(true);
        defineDate(30);
        setShowDates(false);
        setMenuOpen(false);
        window.localStorage.removeItem('url');
        window.scroll({ top: 0, behavior: 'smooth' });
    }, [])

    useEffect(() => {
        if (error !== null)
            setLoading(false)
    }, [error])

    if (loading) return <PlaceholderMetrics />

    if (error) return <ErrorSection><p>Ocorreu um erro ao buscar as informações: <span>{error}</span></p><p>Se o erro persistir, entre em contato com nosso time.</p></ErrorSection>

    if (campaignData === null) return <section style={{ height: '30vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><p>Nenhum dado encontrado! CamapignData igual a null</p></section>

    if (campaignData.content === null) return <section style={{ height: '30vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><p>Nenhum dado encontrado!</p></section>

    return (
        <div>
            <ContentGrid>
                <Title>
                    <ItemTitle1 className={"removeSelection"} title={`Campanha ID: ${campaignData.content[index].googleCampaingId}`}>
                        <b>{campaignData.content[index].campaingName}</b>
                        {showAds && <ul>
                            {campaignData.content.map((item: any, index: any) => (
                                <li key={item.googleCampaingId} onClick={() => {
                                    setIndex(index)
                                    setShowAds(false)
                                }}>{item.campaingName}</li>
                            ))}
                        </ul>
                        }
                        {campaignData.content.length === 1 ? null :
                            showAds ? <FaCaretUp /> : <FaCaretDown />
                        }
                    </ItemTitle1>

                    <ItemTitle2 className={"removeSelection"}>
                        <p onClick={() => {
                            if (dateCustom) {
                                showDateCustom(false)
                            }
                            setShowDates(!showDates);

                        }}>{window.localStorage.getItem("searchedDays")?.includes('De') ? window.localStorage.getItem("searchedDays") : `Últimos ${window.localStorage.getItem("searchedDays")} dias`}</p>
                        <FaCalendarAlt onClick={() => {
                            if (dateCustom) {
                                showDateCustom(false)
                            }
                            setShowDates(!showDates);

                        }} />
                        <Select show={showDates}>
                            <p onClick={() => {
                                defineDate(7)
                            }}>7 dias</p>
                            <p onClick={() => {
                                defineDate(15)
                            }}>15 dias</p>
                            <p onClick={() => {
                                defineDate(30)
                            }}>30 dias</p>
                            <p onClick={() => {
                                defineDate(60)
                            }}>60 dias</p>
                            <p onClick={() => {
                                showDateCustom(true)
                                setShowDates(false)
                            }}>Personalizado</p>
                        </Select>
                        {dateCustom &&
                            <DateCustom>
                                <div>
                                    <label htmlFor="dateStart">Data de início:</label>
                                    <input type="date" data-date-inline-picker="true" name="dateStart" onChange={({ target }) => setDateStart(target.value)} />
                                </div>
                                <div>
                                    <label htmlFor="dateEnd">Data de término:</label>
                                    <input type="date" data-date-inline-picker="true" name="dateEnd" onChange={({ target }) => setDateEnd(target.value)} />
                                </div>
                                <button onClick={customPeriod}>Filtrar</button>

                                <span onClick={() => showDateCustom(false)}><FaTimes /></span>
                            </DateCustom>}
                    </ItemTitle2>
                    <ItemTitle3><a href={`http://${window.localStorage.getItem('menudino')}.menudino.com`} target="_blank" rel="noreferrer"><span>{window.localStorage.getItem('menudino')}</span>.menudino.com</a></ItemTitle3>
                    <ItemTitle4 className={"removeSelection"} status={status}><span>•</span> {status ? 'Ativa' : 'Expirada'}</ItemTitle4>

                </Title>
                <MobileMenu>
                    <Menu onClick={() => {
                        setMenuOpen(!menuOpen);
                        if (menuOpen === true) {
                            setHideMenu(null);
                        } else {
                            setHideMenu(true);
                        }
                    }}>
                        {menuOpen ?
                            <FaTimes /> : <FaBars />
                        }
                    </Menu>
                    {menuOpen ?
                        <ContentMenu status={status}>
                            <span title={campaignData.content[index].googleCampaingId}>
                                <b>{campaignData.content[index].campaingName}</b>
                            </span>
                            <select defaultValue={`${window.localStorage.getItem("searchedDays")?.includes('De') ? 'P' :
                                window.localStorage.getItem("searchedDays")}`}
                                onChange={({ target }) => {
                                    if (target.value === 'P') {
                                        showDateCustomMobile(true)
                                    } else {
                                        defineDate(target.value);
                                    }
                                }}>
                                <option value="7">Últimos 7 dias</option>
                                <option value="15">Últimos 15 dias</option>
                                <option value="30">Últimos 30 dias</option>
                                <option value="60">Últimos 60 dias</option>
                                <option value="P">Personalizado</option>
                            </select>
                            {dateCustomMobile &&
                                <DateCustomMobile>
                                    <label htmlFor="dateStart">Data de início:</label>
                                    <input type="date"
                                        data-date-inline-picker="true"
                                        name="dateStart"
                                        onChange={({ target }) => setDateStart(target.value)} />

                                    <label htmlFor="dateEnd">Data de término:</label>
                                    <input type="date" data-date-inline-picker="true" name="dateEnd" onChange={({ target }) => setDateEnd(target.value)} />
                                    <button onClick={customPeriod}>Filtrar</button>
                                    <FaTimes onClick={() => showDateCustomMobile(false)} />
                                </DateCustomMobile>
                            }
                            <a href={"http://app.menudino.com/" + window.localStorage.getItem('menudino')} target="_blank" rel="noreferrer">app.menudino.com/<p>{window.localStorage.getItem('menudino')}</p></a>
                            <div>
                                <b>{status ? 'Campanha Ativa' : 'Campanha expirada'}</b>
                            </div>
                        </ContentMenu> : null
                    }
                </MobileMenu>

                {dataGraph ? <p>Carregando...</p> :
                    <Graph>
                        <div>
                            <b>Evolução no período</b>
                            <div>
                                <input type="radio" name="webmaster" value="impressoes" checked={inputImp}
                                    onChange={handleClick} /> Impressões<br />
                                <input style={{ left: '1.5px' }} type="radio" name="webmaster" value="cliques" checked={inputClick}
                                    onChange={handleClick} /> Cliques<br />
                            </div>
                        </div>
                        <span>
                            <ResponsiveContainer height={300}>
                                <LineChart
                                    key={index}
                                    width={550}
                                    height={250}
                                    data={arrData}
                                    margin={{
                                        top: 20,
                                        right: 10,
                                        left: 0,
                                        bottom: 30,
                                    }}>
                                    <CartesianGrid strokeDasharray="8 8" />
                                    <XAxis dataKey="day" />
                                    <YAxis />
                                    <Tooltip content={<CustomToolTip />} />
                                    {inputImp && <Line type="monotone" dataKey="impressoes" strokeWidth={3} stroke="#EA4335" activeDot={{ r: 6 }} />}
                                    {inputClick && <Line type="monotone" dataKey="cliques" strokeWidth={3} stroke="#004FAC" activeDot={{ r: 6 }} />}
                                </LineChart>
                            </ResponsiveContainer>
                        </span>
                    </Graph>}
                <LineVertical />
                <Aside>
                    <AsideItem1>
                        <b>Impressões</b>
                        <div>
                            <FaEye />
                            <p>{campaignData.content[index].impressions}</p>
                        </div>
                        <small>Quantas vezes seu anúncio foi exibido no Google</small>
                    </AsideItem1>
                    <AsideItem2>
                        <b>Cliques</b>
                        <div>
                            <FaMousePointer />
                            <p>{campaignData.content[index].clicks}</p>
                        </div>
                        <small>Quantas pessoas acessaram seu cardápio</small>
                        <LineHorizontal />
                        <CircleLine />
                    </AsideItem2>
                    <AsideItem3>
                        <b>Pedidos</b>
                        <div>
                            <FaExchangeAlt />
                            <p>{campaignData.content[index].conversions}</p>
                        </div>
                        <small>Total de pedidos realizados por estas pessoas</small>
                        <LineHorizontal />
                        <CircleLine />
                    </AsideItem3>
                    <AsideItem4>
                        <b>Taxa de Conversão</b>
                        <div>
                            <FaDollarSign />
                            <p>{Math.floor(campaignData.content[index].conversionRate * 100) / 100}%</p>
                        </div>
                        <small>Percentual de pedidos realizados por cliques</small>
                        <LineHorizontal></LineHorizontal>
                        <CircleLine />
                    </AsideItem4>
                </Aside>
                <Footer>
                    <div>
                        <FooterItem1>
                            <b>Investimento</b>
                            <p>R$ {campaignData.content[index].invoice.toFixed(2).toString().replace('.', ',')}</p>
                        </FooterItem1>
                        <FooterItem2>
                            <b>Por Pedido</b>
                            <p>R$ {campaignData.content[index].costPerConversion.toFixed(2).toString().replace('.', ',')}</p>
                        </FooterItem2>
                        <FooterItem3>
                            <b>Por Clique</b>
                            <p>R$ {campaignData.content[index].averageTicket.toFixed(2).toString().replace('.', ',')}</p>
                        </FooterItem3>
                    </div>
                </Footer>

            </ContentGrid>

        </div>
    )
}

export default Index