import { useEffect, useState } from 'react'
import RedButton from '../RedButton/RedButton'
import ScreenSection from '../Shared/ScreenSection/ScreenSection'
import { Div, H2, Text, Image, Card, ChildOne, ChildTwo } from './styles'
import WomanReading from '../../../images/womanreading.png'
import Mic from '../../../images/icons/micgoogle.png'
import { Zoom } from "react-awesome-reveal";

const ScreenTwo = () => {
    const [uf, setUf] = useState<string>('');

    useEffect(() => {
        const abortCont = new AbortController()

        async function getAddress(){
            const state = window.localStorage.getItem('uf');
            let response;
            let json;

            if(state === null){
                try{
                    response = await fetch('https://freegeoip.app/json/', {signal: abortCont.signal});
                    json = await response.json();
                    const estado = json.region_code;
                    setUf(estado)
                    window.localStorage.setItem('uf', estado);
                    if(estado === ''){
                        setUf('SP');
                        window.localStorage.setItem('uf', 'SP');
                    }
                } catch(e){
                    setUf('SP');
                    window.localStorage.setItem('uf', 'SP');
                }
            } else {
                setUf(state);
            }
          }
        getAddress();

        return () => abortCont.abort();
    }, [])

    return (
        <ScreenSection color={'#F4F4F7'}>
            <Zoom><H2>Todo mundo pesquisa no Google</H2></Zoom>
            
            <Div><Text>Alcance seu público no momento exato em que as pessoas estão procurando pelo seu restaurante.</Text></Div>
            <RedButton adaptive={false} />
            <div style={{position: 'relative'}}>
                <Image src={WomanReading} alt="" className='removeSelection'/>
                <Card className='removeSelection'>
                    <ChildOne>
                        <b>Cafeteria delivery</b>
                        <img src={Mic} alt="" height="30"/>
                    </ChildOne>
                    <ChildTwo>
                        <p><b>Anúncio</b> - menudino.com/seucardapio</p>
                        <span>Melhor cafeteria em {uf}</span>
                        <p>Peça já o melhor e mais saboroso café artesanal de {uf}</p>
                    </ChildTwo>
                </Card>
            </div>
        </ScreenSection >
    )
}

export default ScreenTwo
