import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CURRENT_CREATION_STATUS } from '../api'
import Header from '../Campaing/Shared/Header'
import useFetch from '../Hooks/useFetch'
import Container from './components'
import Footer from './components/Footer'
import { Content } from './styles'

const Index = () => {
    const [hasToken, setHasToken] = useState<boolean>(false)
    const { request } = useFetch();
    const navigate = useNavigate();

    async function verifyCampaign(){
        const { response, json } = await request(CURRENT_CREATION_STATUS, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`
            }
        })
        
        if(json === null || response?.status === 400 || response?.status === 404 
        || response?.status === 401 || response?.status === 403){
            navigate('/login') 
        } else if(json["content"] !== 0 ) {
            navigate('/criar')
        } else {
            setHasToken(true)
        }
    }

    useEffect(() => {
        if(window.localStorage.getItem('token') === null){
            navigate('/login')
        } else {
            verifyCampaign()
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate])

    if(!hasToken) return null;

    return (
        <Content>
            <Header />
            <Container />
            <Footer />
        </Content>
    )
}

export default Index
