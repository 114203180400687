import ScreenSection from '../Shared/ScreenSection/ScreenSection'
import { Div, H2, Text, ContentDiv, ContentSection, Title, Description, Link, ImageBanner, BannerDivImage, InfoCard, CardBannerDiv } from './styles'
import Banner from '../../../images/orangebanner.png'
import Gift from '../../../images/icons/Icon metro-gifts.png'
import {Fade, Zoom, Slide } from 'react-awesome-reveal'

const ScreenFour = () => {
    return (
        <ScreenSection color={'#F4F4F7'}>
            <Zoom><H2>Fácil e inovador, como tudo no Consumer</H2></Zoom>
            <Div><Text>Foque no que é mais importante: o seu negócio. E deixa que a publicidade no Google a gente cuida. São 3 passos para criar seu anúncio, e nada mais.</Text></Div>
            <ContentDiv>
                {/* <Image src={Video} alt=""/> */}
                <br/>
                <ContentSection>
                    <Slide direction="up">
                        <div>
                            <Title><span>#1</span> Crie seu cardápio</Title>
                            <Description>Primeiro você publica seu cardápio no <b>MenuDino</b>. Caso já tenha feito isso, então tá tudo certo!</Description>
                            <Link onClick={() => window.open('https://ajuda.programaconsumer.com.br/como-receber-pedidos-do-menudino-no-consumer-desktop/')}>Saiba mais</Link>
                        </div>
                    </Slide>
                    <Slide direction="up" delay={100}>
                        <div>
                            <Title><span>#2</span> Aprove o Anúncio</Title>
                            <Description>Com base nos dados do seu restaurante, o Consumer irá criar uma campanha no Google Ads. <b>Você apenas confere</b> se está tudo ok.</Description>
                        </div>
                    </Slide>
                    <Slide direction="up" delay={200}>
                        <div>
                            <Title><span>#3</span> Escolha um plano</Title>
                            <Description>Você define um <b>orçamento inicial de publicidade</b>. Fique tranquilo, você pode acompanhar o desempenho da sua campanha em tempo real.</Description>
                        </div>    
                    </Slide>                    
                </ContentSection>
                <Fade>
                    <BannerDivImage>
                            <ImageBanner src={Banner} alt=""/>
                            <InfoCard className='removeSelection'>
                                <img src={Gift} alt="" width="50" height="60" className='removeSelection'/>
                                <CardBannerDiv className='removeSelection'>
                                    <b>Um Presente Especial para Você</b>
                                    <p>Crie sua campanha agora e <span>ganhe até R$ 225,00</span> de crédito* para usar no segundo mês!</p>
                                    <br/>
                                    <em>* O seu cupom replicará o valor gasto no primeiro mês da campanha e o oferecerá como crédito para o seu segundo mês, sendo o valor máximo oferecido de R$ 225,00.</em>
                                </CardBannerDiv>
                            </InfoCard>
                    </BannerDivImage>
                </Fade>
            </ContentDiv>
            <br/><br/>
        </ScreenSection>
    )
}

export default ScreenFour
