import styled, { keyframes } from 'styled-components';

interface CardProps {
    scale: any
}

export const Card = styled.div<CardProps>`
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    height: 300px;
    margin: 20px 20px 50px 20px;
    position: relative;
    box-shadow: 1px 3px 12px rgba(0,0,0,0.3);
    transition: transform .5s;  
    
    &:nth-child(3){
        transform: ${props => props.scale === 'true' ? 'scale(1.1)' : ''};
    }  

    @media(max-width: 779px){
        &:nth-child(2){
            transform: scale(1);
        } 
    }

    @media(max-width: 539px){
        
        :nth-child(1){
            margin-bottom: 70px;
        }

        :nth-child(3){
            margin-bottom: 30px;
        }
    }

    &:hover{ 
        transform: scale(1.1);
    }
`

export const CardTitle = styled.div`
    background: ${props => props.theme.color.blackColor};
    color: white;
    text-align: center;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    line-height: 35px;
    font-size: .8rem;
`

const tooltipAnimation = keyframes`
    from {
        opacity: 0;
    } 
    to {
        opacity: 1;
    }
`

export const CardContent = styled.div`
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    height: 300px;
    background: white;
    width: 100%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    button {
        cursor: pointer;
        background: ${props => props.theme.color.blackColor};
        border-radius: 20px;
        color: white;
        width: 120px;
        font-size: .7rem;
        padding: 5px 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        letter-spacing: .05rem;
        outline: none;
        border: 1px solid transparent;
        box-shadow: 1px 3px 5px rgba(0,0,0,0.3);
        transition: .5s;

        &:hover{
            background: white;
            border: 1px solid ${props => props.theme.color.blackColor};
            color: ${props => props.theme.color.blackColor};
        }
    }

    p > b {
        font-size: 2rem;
    }

    small {
        font-size: .7rem;
    }

    > div {
        display: flex;
        flex-direction: column;
        position: relative;

        > small {
            margin-top: 5px;
        }

        > b {
            font-size: .7rem;
        }

        .info {
        cursor: pointer;
        color: ${props => props.theme.color.blueColor};
    }

    .tooltip-info {
        animation: ${tooltipAnimation} .4s linear forwards;
        position: absolute;
        margin-top: 5px;
        padding: 5px;
        border-radius: 5px;
        border: 2px solid black;
        background: ${props => props.theme.color.blackColor};
        box-shadow: 1px 1px 5px rgba(0,0,0,0.6);
        color: white;
        z-index: 10;
        width: 130%;
        top: 10px;
        right: -15px;

        p {
            line-height: 15px;
            font-size: 10px;
        }
    }
    }
`

export const Star = styled.div`
    position: absolute;
    top: -57px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    span{
        padding: 5px 20px;
        border: .05rem solid black;
        border-radius: 30px;
        font-size: .7rem;
        background: white;
    }
`

export const DivFee = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;

    small {
        margin-top: 10px;
        font-size: 9px;
    }

    .info {
        cursor: pointer;
        color: ${props => props.theme.color.blueColor};
    }

    .tooltip-info {
        animation: ${tooltipAnimation} .4s linear forwards;
        position: absolute;
        margin-top: 5px;
        padding: 5px;
        border-radius: 5px;
        border: 2px solid black;
        background: ${props => props.theme.color.blackColor};
        box-shadow: 1px 1px 5px rgba(0,0,0,0.6);
        color: white;

        p {
            line-height: 15px;
        }
    }
`