import React, { useState } from 'react'
import { DivInfo, DivLogo, Header, MenuMobile } from './styles';
import Logo from '../../../images/icons/whitelogo.png';
import { IoMdExit} from 'react-icons/io'
import { useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Index = () => {
    type MenuStatus = true | false | "hide";
    const navigate = useNavigate();
    const [user, setUser] = React.useState<any>(null);
    const [menu, showMenu] = useState<MenuStatus>("hide");

    function exit(){
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        navigate('/login')
    }

    React.useEffect(() => {
        const username = window.localStorage.getItem('user') === null ? '' : window.localStorage.getItem('user');
        setUser(username)
    },[])

    return (
        <Header >
            <DivLogo onClick={() => navigate('/')} className='removeSelection'>
                <img src={Logo} height="30" alt="Logo Consumer"/>
                <p>Consumer <b>Ads</b></p>
            </DivLogo>

            <div className="bars"><FaBars size='18px' onClick={() => showMenu(true)} /></div>

            <DivInfo>
                <span>Olá! {user}</span>
                <div onClick={() =>  exit()} className='removeSelection'>
                    <p>Sair</p>
                    <IoMdExit size="20px"/>
                </div>
            </DivInfo>

            {menu !== "hide" ? 
                <MenuMobile action={menu.toString()}>
                    <span>Olá! {user}</span>
                    <div onClick={() =>  exit()} className='removeSelection'>
                        <p>Sair</p>
                        <IoMdExit size="20px"/>
                    </div>
                    <FaTimes size='17px' className="icon" onClick={() => {
                        showMenu(false)
                        setTimeout(() => {
                            showMenu("hide")
                        }, 500)
                    }} />
                </MenuMobile>
            : null }
        </Header>
    )
}

export default Index
