import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../OnBoardPage/components/Header/Header'
import WhiteLogo from './../images/icons/logo-branco.svg'
import { NotFound, Top, Bottom } from './styles'

const Index = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.localStorage.setItem('notfound', 'true');
    }, [])

    function go(url: string){
        window.localStorage.removeItem('notfound');
        navigate(url);
    }

    return (
        <NotFound>
            <Top>
                <Header />
            </Top>
            <b>Ops... página não encontrada!</b>
            <p>Para onde deseja seguir? Escolha abaixo:</p>
            <ul>
                <li><span onClick={() => go('/')}>Página inicial</span></li>
                <li><span onClick={() => go('/criar')}>Criar campanha</span></li>
                <li><span onClick={() => go('/dashboard')}>Visualizar os dados de sua campanha</span></li>
            </ul>
            <Bottom>
                <img onClick={() => window.open("https://www.programaconsumer.com.br")} src={WhiteLogo} alt="" height="40"/>
                <a href="https://www.programaconsumer.com.br" target="_blank" rel="noreferrer">
                    
                    <p>consumer</p>
                </a>
            </Bottom>
        </NotFound>
    )
}

export default Index
