import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    padding-bottom: 3rem;
    flex-direction: column;
    &:first-child(){
        margin: 0 auto;
        padding: 20px;
        background: red;
    }
`;

export const Title = styled.div`
    max-width: 600px;
    margin: 40px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 50%;

    b {
        font-size: 20px;
    }
    p{
        margin: 10px 0;
        text-align: center;
    }
    img {
        margin: 20px;
        max-height: 200px;
    }

    span {
        font-weight: bold;
        margin: 5px 0px;
    }

    @media(max-width: 450px){
        padding: 0;
        margin: 20px 20px;
    }
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    background: #F4F4F7;
    align-items: flex-start;
    justify-content: center;
    text-align: start;
    width: 100vw;
    padding: 0 20px;
    min-height: 50%;

    div {
        max-width: 800px;
        margin: 10px auto;
        
    }
`;

export const CardTitle = styled.div`
    font-size: .9rem;
    max-width: 800px;
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    margin-top: 20px;
`

export const CardContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    border-top: 1px solid #b2b2b2;
    margin: 0 20px;
    flex-wrap: wrap;
    padding: 0 0 100px 0;


    div {
        flex: 1 1 200px;
        max-width: 250px;
        margin: 10px;

        b{
            font-size: .8rem;
        }

        p {
            margin-right: 10px;
            font-size: .7rem;
            line-height: 30px;
        }
    }
    @media(min-width: 480px){
        div { 
            height: 40vh;
        }
    }
`