import { createGlobalStyle } from 'styled-components';
import './font.css'

export default createGlobalStyle`
    *{
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        font-family: 'Montserrat', sans-serif;
    }

    body, html{
        width: 100%;
        height: 100%;
    }

    body{
        overflow-x: hidden;
    }
`;