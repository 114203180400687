/* eslint-disable import/no-anonymous-default-export */
export default {
    color: {
        redColor: '#AD4335',
        redAccentColor: '#D15e5E',
        buttonColor: '#EA4335',
        blackColor: '#171829',
        iceWhite: '#D4D4DB',
        tinWhite: '#F4F4F7',
        whiteColor: '#FFFFFF',
        blueColor: '#004FAC',
        greenColor: '#34A852'
    },
    fonts: {
        biggestBoldTextOnboard: 75,
        bigBoldTextOnboard: 48,
        mediumTextBold: 28,
        normalTextBold: 20,
        largeTitleBold: 34,
        title1Bold: 28,
        title2BottomBold: 22,
        headLineBold: 17,
        body: 17,
        footNote: 15,
        caption: 13
    }
}