import styled from 'styled-components'

export const H2 = styled.h2`
    padding-top: 80px;
    font-weight: bold;
    position: relative;
    font-size:${props => props.theme.fonts.largeTitleBold}px;

    @media(max-width: 530px){
        font-size: min(30px, 8vh);
    }
`;


export const Text = styled.p`
    font-size: ${props => props.theme.fonts.normalTextBold}px;
`;

export const Div = styled.div`
    max-width: 600px;
    margin: 0 auto;
    padding: 20px 0;
    margin: 20px 0;
`;

export const Image = styled.img`
    position: relative;
    padding: 30px 0;
    margin: 20px auto;
    max-height: 800px;
    width: 100%;

    @media(max-width: 300px){
        display: none;
    }
`;

export const Card = styled.div`
    position: absolute;
    top: 15%;
    right: 10px;
    background: rgba(255,255,255,0.6);
    border: 1px solid white;
    height: 285px;
    width: 450px;

    @media(max-width: 766px){
        top: 15%;
        right: 5px;
    }

    @media(max-width: 682px){
        height: 250px;
    }

    @media(max-width: 620px){
        height: 250px;
    }

    @media(max-width: 500px){
        height: 235px;
        width: 300px;
    }

    @media(max-width: 478px){
        height: 185px;
        top: 20%;
    }

    @media(max-width: 395px){
        height: 150px;
        width: 270px;
    }
    @media(max-width: 300px){
        position: relative;
        background: rgba(0,0,0,0.8);
        margin: 0 auto;
    }
`;

export const ChildOne = styled.div`
    position: absolute;
    height: 50px;
    width: 250px;
    left: 20px;
    top: 30px;
    background: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 1px 3px 12px rgba(0,0,0,.4);

    @media(max-width: 682px){
        top: 10px;
    }

    @media(max-width: 500px){
        position: relative;
        left: 40px;
    }
    @media(max-width: 478px){
        height: 35px;
        img{
            height: 20px;
        }
    }

    @media(max-width: 395px){
        font-size: 12px;
        height: 30px;
        left: 10px;
        img{
            height: 18px;
        }
    }

    @media(max-width: 300px){
        position: relative;
    }
`;

export const ChildTwo = styled.div`
    position: absolute;
    height: 150px;
    width: 350px;
    right: 20px;
    top: 110px;
    background: white;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 10px 30px;
    font-size: 14px;
    text-align: start;
    box-shadow: 1px 3px 12px rgba(0,0,0,.4);
    flex-direction: column;

    span{
        color: #004FAC;
        font-weight: bold;
    }

    @media(max-width: 682px){
        top: 70px;
    }

    @media(max-width: 500px){
        top: 25px;
        left: 40px;
        position: relative;
        width: 250px;
        padding: 10px 20px;
    }

    @media(max-width: 478px){
        height: 120px;
        padding: 10px 10px;
    }

    @media(max-width: 395px){
        font-size: 11px;
        height: 80px;
        padding: 10px 10px;
        left: 10px;
        justify-content: space-between;
    }

    @media(max-width: 300px){
        position: relative;
    }
`; 
