import styled from 'styled-components'

export const Foot = styled.footer`
    height: 170px;
    width: 100vw;
    background-color: ${props => props.theme.color.blackColor};
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    bottom: 0;
    right: 0;

    a {
        cursor: pointer;
        color: white;
        font-weight: bold;
        text-decoration: none;
        font-size: 1.2rem;
        margin-top: 20px;
    }

    span {
        cursor: pointer;
        margin: 20px;
        color: white;
        transition: .5s;
        text-align: center;

        &:hover{
            color: ${props => props.theme.color.iceWhite};
        }
    }
`;