import { FC } from 'react'
import styled from 'styled-components'
import ScreenDiv from '../ScreenDiv/ScreenDiv';

interface SectionProps{
    color: string
}

const Section = styled.section<SectionProps>`
    background-color: ${props => props.color};
    width: 100%;
`;

const ScreenSection:FC<SectionProps> = (props) => {
    return (
        <Section color={props.color}>
            <ScreenDiv>
                {props.children}
            </ScreenDiv>
        </Section>
    )
}

export default ScreenSection
