import styled, { keyframes } from "styled-components";

const isPassing = keyframes`
    from {
    transform: translateX(-100%); 
  }
`

export const PlaceholderCredit = styled.div`
    display: flex;
    width: 100%;
    height: 50%;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    overflow: hidden;

    > span{
        height: 30px;
        background: #c5c5c5;
        border-radius: 5px;
        overflow: hidden;
        position: relative;

        &::before{
            animation: ${isPassing} .8s ease-in infinite;
            background:
            linear-gradient(
                to right,
                transparent,
                #fff 10%,
                transparent
            );
            content: '';
            display: block;
            height: 100vh;
            position: absolute;
            width: 100vw;
        }
    }
`

export const Placeholder = styled.div`
    height: 50px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    

    > span{
        height: 20px;
        background: #c5c5c5;
        border-radius: 5px;
        overflow: hidden;
        position: relative;

        &::before{
            animation: ${isPassing} .8s ease-in infinite;
            background:
            linear-gradient(
                to right,
                transparent,
                #fff 50%,
                transparent
            );
            content: '';
            display: block;
            height: 100vh;
            position: absolute;
            width: 100vw;
        }
    }
`;