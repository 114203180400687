import styled from 'styled-components'

export const H2 = styled.h2`
    padding-top: 80px;
    font-weight: bold;
    font-size:${props => props.theme.fonts.largeTitleBold}px;

    @media(max-width: 530px){
        font-size: min(30px, 8vh);
    }
`;

export const Text = styled.p`
    font-size: ${props => props.theme.fonts.normalTextBold}px;
    margin: 20px 0;
    @media(max-width: 530px){
        font-size: min(20px, 4vh);
    }
`;

export const Div = styled.div`
    max-width: 700px;
    margin: 0 auto;
    padding: 20px 0;
`;

export const ImageDiv = styled.div`
    max-width: 100%;
`;

export const Image = styled.img`
    margin: 30px 0;
    max-height: 300px;
    width: 100%;
`;

export const Small = styled.small`
    margin-bottom: 10px;

    @media(max-width: 530px){
        font-size: 12px;
    }
`;
