import ScreenSection from '../Shared/ScreenSection/ScreenSection'
import { H2, Div, Text, Image, ImageDiv, Small } from './styles'
import Relatorio from '../../../images/relatorio.png'
import { Zoom } from "react-awesome-reveal";

const ScreenTree = () => {
    return (
        <ScreenSection color={"#FFFFFF"}>
            <Zoom><H2>Relatórios precisos que não te deixam na mão</H2></Zoom>
            <Div><Text>Acompanhe de perto o desempenho da sua campanha. Descubra quantas vezes seu anúncio apareceu, quantas pessoas entraram no seu cardápio e, por fim, quantos pedidos foram realizados.</Text></Div>
            <ImageDiv>
                <Image src={Relatorio} />
            </ImageDiv>
            <Small>(*) Os números acima foram extraídos dos relatórios de um cliente real.</Small>
        </ScreenSection>
    )
}

export default ScreenTree
