import { Foot } from './styles'
import WhiteLogo from '../../../images/icons/logo-branco.svg'

const Footer = () => {

    return (
        <Foot>
            <a href="https://www.programaconsumer.com.br" target="_blank" rel="noreferrer" className='removeSelection'>
                <img src={WhiteLogo} alt="" height="40"/>
                <p>consumer</p>
            </a>
        </Foot>
    )
}

export default Footer
