import { FormEvent, useEffect, useState } from 'react'
import { CURRENT_CREATION_STATUS, SIGN_IN } from '../api'
import useFetch from '../Hooks/useFetch';
import { Error, LoadingLogin, Login } from './styles'
import WhiteLogo from '../images/icons/logo-branco.svg'
import { useNavigate } from 'react-router'
import settings from '../../package.json'

const Index = () => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const { request } = useFetch();
    const [error, setError] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    async function login(e: FormEvent) {
        e.preventDefault();
        setLoading(true);
        window.localStorage.setItem("temMenuDino", "true")

        let forceAccountCreate: boolean = false

        if (document.location.href.includes("type")) {
            forceAccountCreate = document.location.href.split("?type=")[1] === "new";
        }

        let body = {
            "username": email,
            "password": password,
            "forceAccountCreate": forceAccountCreate
        }

        try {
            const response = await fetch(SIGN_IN, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })

            const json = await response.json();

            if (json.content?.menuUrl === null) {
                window.localStorage.setItem('token', json['content']['access_token'])
                setError("Publicação do MenuDino não realizada, realize a publicação pelo Consumer.")
                window.localStorage.setItem("temMenuDino", "false")
                setLoading(false)
                return;
            }

            if (response.status === 200) {
                window.localStorage.setItem('token', json['content']['access_token'])
                window.localStorage.setItem('user', json['content']['name'])

                let urlSplit = "";

                if (json.content.menuUrl !== null) {
                    urlSplit = json.content.menuUrl.split('//');
                    const u = urlSplit[1].split('.');
                    window.localStorage.setItem('menudino', u[0])
                }

                setError(null)

                const redirect = window.localStorage.getItem('url');
                if (redirect?.includes('pagamento')) {
                    navigate(`/${redirect}`)
                } else {
                    verifyCampaign()
                }
            } else if (response.status === 500 || response.status === 405) {
                setError(json["error_message"]);
                setTimeout(() => {
                    setError("")
                }, 10000)
                setLoading(false);
            }
            else {
                setError("Verifique os dados de acesso e tente novamente!")
                setTimeout(() => {
                    setError("")
                }, 4000)
                setLoading(false);
            }

        } catch (e: any) {
            setError(`Ocorreu um erro durante o login, verifique os dados e tente novamente! ${e.message}`)
            setTimeout(() => {
                setError("")
            }, 4000)
            setLoading(false);
        }
    }

    async function verifyCampaign() {
        const { response, json } = await request(CURRENT_CREATION_STATUS, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`
            }
        })

        if (response?.status === 400 || response?.status === 404) {
            setError("Ocorreu um erro durante o login, verifique os dados e tente novamente!")
            setTimeout(() => {
                setError("")
            }, 4000)
        } else if (json.content !== 0) {
            setError(null)
            setLoading(false);
            navigate('/criar')
        } else {
            setLoading(false);
            navigate('/dashboard')
        }
    }

    useEffect(() => {
        window.localStorage.clear();
    }, [])

    return (
        <Login>
            {error && <Error>{error}</Error>}
            <div>
                <img src={WhiteLogo} alt="Logo Consumer" />
                <p>Consumer Ads</p>
                <span>v{settings.version}</span>
            </div>
            <form onSubmit={login}>
                <p>Entre com seu Consumer ID:</p>
                <input type="email" name="email" autoComplete="on" value={email} onChange={({ target }) => setEmail(target.value)} placeholder="email@email.com" required />
                <input type="password" value={password} onChange={({ target }) => setPassword(target.value)} placeholder="insira sua senha" required />
                <small onClick={() => window.open('https://loja.programaconsumer.com.br/account/login')}>Esqueci minha senha</small>
                {loading ? <button disabled><LoadingLogin /></button> :
                    <button type="submit">Entrar</button>}
            </form>
        </Login>
    )
}

export default Index
