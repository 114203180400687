import styled from 'styled-components'

export const Section = styled.div`
display: flex;
align-items: center;
justify-content: center;
text-align: center;
flex-direction: column;
padding-top: 140px;
padding-bottom: 0;
`;