/* eslint-disable react-hooks/exhaustive-deps */
import { Header } from "../CampaignOrder/styles";
import { BodyContent, Container, PaymentContent, PaymentMode, PaymentModeBody, PaymentModeCreditCard, PaymentModeHeader, PaymentModeHeaderItem, PaymentModePix, Button, Checkbox, RecuringPayment, ContentInputs, Footer, Error, Loading } from "./styles";
import Logo from '../images/icons/whitelogo.png';
import { useNavigate } from "react-router-dom";
import { IoMdExit } from "react-icons/io";
import { useState, useEffect } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { getCardType } from "./creditCardFunction";
import { AddCredit, CreditCard, PaymentMethod } from "../Models/Requests/AddCredit";
import { ADD_CREDIT } from "../api";
import { AddCreditResponse } from "../Models/Responses/AddCreditResponse";
import { Spin } from "../Campaing/Shared/Load/styles";
import Modal from '../Shared/Modal/index'

export default function Checkout() {

    enum Subscription {
        Iniciante = 150,
        Básico = 300,
        Profissional = 600,
        Avançado = 1200
    }

    const [selectedPrice, setSelectedPrice] = useState<number>(150)
    const [paymentModelCreditCard, setPaymentModeCreditCard] = useState<boolean>(true)
    const [creditCardNumber, setCreditCardNumber] = useState<string>()
    const [creditCardNumberError, SetCreditCardNumberError] = useState<boolean>(false)
    const [flagCreditCard, setFlagCreditCard] = useState<string>()
    const [holderName, setHolderName] = useState<string>()
    const [holderNameError, setHolderNameError] = useState<boolean>(false)
    const [dateExpiration, setDateExpiration] = useState<string>()
    const [dateExpirationError, setDateExpirationError] = useState<boolean>(false)
    const [cvv, setCvv] = useState<string>()
    const [cvvError, setCvvError] = useState<boolean>()
    const [isRecuringPayment, setIsRecuringPayment] = useState<boolean>(true)
    const [error, setError] = useState<string>()
    const [userAdsAccountId, setUserAdsAccountId] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)

    const navigate = useNavigate()

    function showError(message: string, close: boolean) {
        setError(message)

        if (close) {
            setTimeout(() => {
                setError(undefined)
            }, 5000)
        }
    }

    function exit() {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        navigate('/login')
    }

    function creditCardFormatter(value: string) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g);
        var match = (matches && matches[0]) || ''
        var parts = []

        for (var i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }

        var currentValue = ""
        if (parts.length) {
            var formatted = parts.join(' ')
            currentValue = formatted
        } else {
            currentValue = value
        }

        setCreditCardNumber(currentValue)
    }

    function expirationDateFormatter() {
        if (dateExpiration?.length === 2) {
            var expirationDate = dateExpiration + "/";
            setDateExpiration(expirationDate)
        }
    }

    function validateFields(): boolean {
        var hasError = false
        if (creditCardNumber === undefined || creditCardNumber.length === 0) {
            hasError = true;
            SetCreditCardNumberError(true)
        } else {
            SetCreditCardNumberError(false)
        }

        if (holderName === undefined || holderName.length === 0) {
            hasError = true;
            setHolderNameError(true)
        } else {
            setHolderNameError(false)
        }

        if (dateExpiration === undefined || dateExpiration.length === 0) {
            hasError = true;
            setDateExpirationError(true)
        } else {
            setDateExpirationError(false)
        }

        if (cvv === undefined || cvv.length === 0) {
            hasError = true;
            setCvvError(true)
        } else {
            setCvvError(false)
        }

        return hasError
    }

    function checkExpirationCreditCard() {
        var date = new Date()
        var actualYear = date.getFullYear()
        var actualMonth = date.getMonth() + 1
        var dateExpirationSplit = dateExpiration!.split('/')
        var monthCreditCard = dateExpirationSplit[0]
        var yearCreditCard = dateExpirationSplit[1]

        if (actualYear > Number(yearCreditCard)) {
            setDateExpirationError(true);
            showError("Cartão expirado.", true);
            return false;
        }

        if (actualYear === Number(yearCreditCard)) {
            if (Number(monthCreditCard) < actualMonth) {
                setDateExpirationError(true);
                showError("Cartão expirado.", true);
                return false;
            }
        }

        setDateExpirationError(false);
        return true;
    }

    async function createPayment() {
        setError(undefined)
        if (paymentModelCreditCard) {
            let hasError = validateFields()

            if (hasError) {
                showError("Verifique os campos e tente novamente.", true)
                return;
            }

            let validExpirationDate = checkExpirationCreditCard();

            if (!validExpirationDate) {
                return;
            }

            if (flagCreditCard === "generic") {
                showError("Cartão de crédito não identificado, valide os campos ou utilize outro cartão e tente novamente.", true)
                setLoading(false)
                return;
            }
            await paymentByCreditCard()
        } else {
            await paymentByPixOrInvoice()
        }
    }

    async function paymentByPixOrInvoice() {
        var addCreditObj = AddCredit.toJson(userAdsAccountId!, PaymentMethod.Pix, selectedPrice)
        makePayment(addCreditObj)
    }

    async function paymentByCreditCard() {
        var creditCardObj = CreditCard.toJson(creditCardNumber!, holderName!, dateExpiration!, cvv!, flagCreditCard!, isRecuringPayment)

        var addCreditObj = AddCredit.toJson(userAdsAccountId!, PaymentMethod.CreditCard, selectedPrice, creditCardObj)

        makePayment(addCreditObj)
    }

    async function makePayment(body: AddCredit) {
        var response;
        try {
            document.body.style.overflowY = 'hidden';
            setLoading(true)
            response = await fetch(ADD_CREDIT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                },
                body: JSON.stringify(body)
            });

            if (response.status === 401 || response.status === 403) {
                localStorage.setItem("url", "checkout")
                navigate("/login")
                return;
            }

            var json = await response.json();
            var addCreditResponse = AddCreditResponse.fromJson(json);
            if (response.ok || response.status === 200) {
                if (addCreditResponse.success) {
                    localStorage.removeItem("valorEscolhido")
                    localStorage.removeItem("userAdsAccountId")
                    if (!paymentModelCreditCard) {
                        navigate(`/pagamento/${addCreditResponse.content!.userAdsAccountCreditHistoryId}`)
                    } else {
                        navigate("/dashboard")
                    }
                } else {
                    showError(addCreditResponse.errorMessage, false)
                }
            } else {
                showError(addCreditResponse.errorMessage, false)
            }
        } catch (e: any) {
            showError(e.message, false)
        } finally {
            document.body.style.overflowY = 'scroll';
            setLoading(false)
        }
    }

    useEffect(() => {
        if (creditCardNumber !== undefined)
            creditCardFormatter(creditCardNumber)

    }, [creditCardNumber])

    useEffect(() => {
        expirationDateFormatter()
    }, [dateExpiration])

    useEffect(() => {
        var token = localStorage.getItem("token")

        if (token === null) {
            localStorage.setItem("url", "checkout")
            navigate("/login")
        } else {
            window.scrollTo({ behavior: 'smooth', top: 0 })
            var price = localStorage.getItem("valorEscolhido");
            if (price !== undefined)
                setSelectedPrice(Number(price));

            var userAdsAccountIdStorage = localStorage.getItem("userAdsAccountId")

            if (userAdsAccountIdStorage !== undefined && userAdsAccountIdStorage !== null)
                setUserAdsAccountId(userAdsAccountIdStorage)
        }
    }, [])

    return (
        <Container>
            {error && <Error><span onClick={() => setError(undefined)}></span>{error}</Error>}
            {loading &&
                <Loading>
                    <div>
                        <Spin />
                        <p>Aguarde...</p>
                    </div>
                </Loading>
            }
            <Modal />
            <Header>
                <div onClick={() => navigate('/')}>
                    <img src={Logo} height="30" alt="Logo Consumer" />
                    <p>Consumer <b>Ads</b></p>
                </div>
                <div className="out">
                    <span>Olá! {window.localStorage.getItem('user')}</span>
                    <div onClick={() => exit()} className='removeSelection'>
                        <p>Sair</p>
                        <IoMdExit size="20px" />
                    </div>
                </div>
            </Header>
            <BodyContent>
                <p>Você pode realizar o pagamento utilizando cartão de crédito e PIX/Boleto</p>
                <PaymentContent>
                    <p>Plano escolhido: R$ {selectedPrice} - {Subscription[selectedPrice as unknown as keyof typeof Subscription]}</p>
                    <div>
                        <span>Alterar:</span>
                        <select defaultValue={'selecione'} onChange={({ target }) => setSelectedPrice(Number(target.value))}>
                            <option disabled defaultValue="selecione">selecione</option>
                            <option value={150}>Iniciante - R$ 150,00</option>
                            <option value={300}>Básico R$ 300,00</option>
                            <option value={600}>Profissional - R$ 600,00</option>
                            <option value={1200}>Avançado - R$ 1.200,00</option>
                        </select>
                        <FaInfoCircle title={`Será descontado 5% sobre o valor de cada recarga para custos operacionais.\nTodas opções representam aproximadamente 30 dias de campanha`} />
                    </div>
                    <PaymentMode isCreditCard={paymentModelCreditCard}>
                        <PaymentModeHeader>
                            <PaymentModeHeaderItem select={paymentModelCreditCard} onClick={() => setPaymentModeCreditCard(true)}>
                                <p>Cartão de crédito</p>
                            </PaymentModeHeaderItem>
                            <PaymentModeHeaderItem select={!paymentModelCreditCard} onClick={() => setPaymentModeCreditCard(false)}>
                                <p>Pix ou boleto bancário</p>
                            </PaymentModeHeaderItem>
                        </PaymentModeHeader>

                        <PaymentModeBody>
                            {paymentModelCreditCard ?
                                <PaymentModeCreditCard>
                                    <p>Insira abaixo os dados do cartão para pagamento</p>
                                    <ContentInputs>
                                        <label htmlFor="creditCardNumber">Número do cartão</label>
                                        <input className={`${creditCardNumberError ? 'hasError' : ''}`} placeholder="0000 0000 0000 0000" max={19} maxLength={19} value={creditCardNumber} id="creditCardNumber"
                                            onBlur={() => {
                                                if (creditCardNumber !== undefined && creditCardNumber.length > 0) {
                                                    setFlagCreditCard(getCardType(creditCardNumber.replaceAll(" ", '')))
                                                }

                                                if (creditCardNumber !== undefined)
                                                    creditCardFormatter(creditCardNumber)
                                            }} type={'text'}
                                            onChange={({ target }) => setCreditCardNumber(target.value)}
                                        />
                                        {flagCreditCard && <img src={require(`../images/creditcardflags/${flagCreditCard}.svg`).default} alt={`${flagCreditCard}`} />}
                                    </ContentInputs>
                                    <ContentInputs>
                                        <label htmlFor="nameCreditCard">Nome no cartão</label>
                                        <input className={`${holderNameError ? 'hasError' : ''}`} value={holderName} placeholder="Nome no Cartão" id="nameCreditCard" onChange={({ target }) => setHolderName(target.value)} />
                                    </ContentInputs>
                                    <ContentInputs>
                                        <label htmlFor="creditCardExpiration">Data de expiração</label>
                                        <input className={`${dateExpirationError ? 'hasError' : ''}`} onKeyDown={(e) => {
                                            if (e.code === 'Backspace') {
                                                if (dateExpiration?.length === 3) {
                                                    setDateExpiration(dateExpiration.slice(0, 0))
                                                }
                                            }
                                        }} placeholder="01/2022" max={7} maxLength={7} value={dateExpiration} id="creditCardExpiration" onChange={({ target }) => setDateExpiration(target.value)} />

                                    </ContentInputs>
                                    <ContentInputs>
                                        <label htmlFor="creditCardCvv">CVV</label>
                                        <input pattern="/\d+/g" className={`${cvvError ? 'hasError' : ''}`} max={3} maxLength={3} value={cvv} placeholder="999" id="creditCardCvv" type={'text'} onChange={({ target }) => setCvv(target.value)} />
                                    </ContentInputs>
                                    <RecuringPayment >
                                        <Checkbox selected={isRecuringPayment} onClick={() => setIsRecuringPayment(!isRecuringPayment)} />
                                        <small>Ao utilizar essa opção será feita automaticamente uma recarga com este cartão quando os créditos da campanha terminarem (cerca de 30 dias). Essa opção é recomendada para ter o melhor desempenho comercial possível</small>
                                    </RecuringPayment>
                                    <Button onClick={() => createPayment()}>Confirmar pagamento</Button>
                                </PaymentModeCreditCard>
                                :
                                <PaymentModePix>
                                    <p>Ao clicar em <strong>Gerar Boleto</strong>, você será redirecionado para visualização do boleto e do código PIX.</p>
                                    <Button onClick={() => createPayment()}>Gerar Boleto</Button>
                                </PaymentModePix>}
                        </PaymentModeBody>
                    </PaymentMode>
                </PaymentContent>
            </BodyContent>
            <Footer>
                <span onClick={() => navigate('/dashboard')}>Voltar</span>
                consumer
            </Footer>
        </Container>
    )
}