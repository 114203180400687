import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from './Shared/Header'
import Initial from './Shared/Steps'
import { Section } from './styles'

const Index = () => {
    const [hasToken, setHasToken] = useState<boolean>(false)
    const navigate = useNavigate();

    useEffect(() => {
        if(window.localStorage.getItem('token') === null){
            navigate('/login')
        } else {
            setHasToken(true)
        }
    }, [navigate])

    if(!hasToken) return null;
    
    return (
        <Section>
            <Header />
            <Initial />
        </Section>
    )
}

export default Index
