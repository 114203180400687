import styled, { keyframes } from 'styled-components';

export const Login = styled.section`
    display: grid;
    grid-template-columns: 1fr 500px;
    width: 100vw;
    height: 100vh;

    @media(max-width: 720px){
            display: flex;
            justify-content: start;
            padding-top: 100px;
            align-items: center;
            flex-direction: column;
            background: ${props => props.theme.color.buttonColor};

            div > img {
                height: 80px;
            }
    }

    @media(max-width: 400px){
        padding-top: 50px;
    }

    div {
        background: ${props => props.theme.color.buttonColor};
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;

        img {
            height: 130px;
        }

        P {
            margin: 20px;
            font-weight: bold;
            color: white;
            text-align: center;
            font-size: 2rem;
        }

        span {
            position: fixed;
            align-self: center;
            font-size: .7rem;
            color: white;
            bottom: 10px;
        }

        @media(max-width: 720px){
            p {
                font-size: 1.8rem;
                margin: 10px;
            }
            img {
                height: 80px;
            }
        }
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        p{
            margin: 20px 0;
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            text-align: center;
        }

        input {
            padding: 5px;
            margin: 10px 0;
            width: 250px;
            width: 60%;
            border: 2px solid ${props => props.theme.color.iceWhite};
            border-radius: 3px;
            transition: .5s;

            &:hover{
                border: 2px solid ${props => props.theme.color.blackColor};
            }
        }

        small {
            width: 300px;
            text-align: end;
            font-size: 12px;
            cursor: pointer;
        }

        button {
            width: 60%;
            height: 30px;
            margin-top: 20px;
            border: none;
            outline: none;
            color: white;
            background: ${props => props.theme.color.blackColor};
            font-size: 16px;
            cursor: pointer;
            transition: .5s;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover{
                filter: sepia(.85) brightness(1.75);
            }

            &:disabled:hover{
                filter: none;
                cursor: default;
            }
        }

        @media(max-width: 720px){
            background: white;
            padding: 30px;
            margin: 30px 20px 0 20px;
            border-radius: 10px;
            box-shadow: 5px 2px 8px rgba(0,0,0,.3);
            overflow: hidden;

            input, button, small {
                width: 100%;
                height: 35px;
            }

            p {
                margin-top: 0px;
            }
        }

        @media(max-width: 404px){
            margin: 20px 20px 0 20px;
            
            small {
                text-align: center;
            }
        }        
    }
`

const showError = keyframes`
    from {
        right: -250px;
    } to{
        right: 0px;
    }
`;

export const Error = styled.aside`
    position: absolute;
    background: #dd5554;
    height: 80px;
    width: 300px;
    color: white;
    top: 20px;
    right: 0px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    box-shadow: 2px 1px 1px rgba(0,0,0,.5);
    animation: ${showError} 2s forwards;
`;

const load = keyframes`
    to {
        transform: rotate(360deg);
    }
`

export const LoadingLogin = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${props => props.theme.color.blackColor} !important;
    border: 4px solid rgba(255, 255, 255, .6);
    border-left-color: white;
    animation: ${load} 1s linear infinite;
`;