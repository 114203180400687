import {useState, useEffect} from 'react'
import { Div, Text, Carousel, Previous, ContentCarrousel, Next, Card, DivFinal } from './styles'
import ScreenSection from '../Shared/ScreenSection/ScreenSection'
import { H2 } from './styles'
import { ReactComponent as Back } from '../../../images/icons/Icon ionic-ios-arrow-forward.svg';
import RedButton from '../RedButton/RedButton'
import { Zoom, Fade } from 'react-awesome-reveal'
import Logo from '../../../images/logo.png'

const ScreenFive = () => {
    const [canGo, setCanGo] = useState<boolean>(true);
    const [canBack, setCanBack] = useState<boolean>(false);

    useEffect(() => {
        const SCROLL_QUANTITY = 380;
        const container = document.querySelector(".quick-action-list");

        const scrollToTheLeft = () => {
            const currentPosition = container?.scrollLeft;

            if(container === null) return;
            if(Number(currentPosition) > 0){
                setCanGo(true)
                container.scrollLeft =  Number(currentPosition) - SCROLL_QUANTITY;
            } else{
                setCanBack(false);
            }
            
            };
      
          const scrollToTheRight = () => {
            const currentPosition = container?.scrollLeft;
            const maxScrollLeft = Number(container?.scrollWidth) - Number(container?.clientWidth);
            
            if(container === null) return;
            if(Number(currentPosition) - SCROLL_QUANTITY < maxScrollLeft){
                setCanBack(true)
                container.scrollLeft = Number(currentPosition) + SCROLL_QUANTITY;
            } else {
                container.scrollLeft = maxScrollLeft;
            }

            if(container.scrollLeft === maxScrollLeft){
                setCanGo(false);
            }
          };
      
          const scrollLeft = document.querySelector(".quick-action-left");
          const scrollRight = document.querySelector(".quick-action-right");
      
          scrollLeft?.addEventListener("click", scrollToTheLeft);
          scrollRight?.addEventListener("click", scrollToTheRight);
      
          return () => {
            scrollLeft?.removeEventListener("click", scrollToTheLeft);
            scrollRight?.removeEventListener("click", scrollToTheRight);
          };
    }, [])
    
    

    const comments = [
        {
            id: 1,
            image: `https://files.menudino.com/cardapios/15041/logo.png`,
            comment: 'A ferramenta além de gerar mais vendas e pedidos online, trouxe também pessoas da região para conhecer nossa hamburgueria.',
            author: 'Sr. Malte'
        },
        {
            id: 2,
            image: `https://files.menudino.com/cardapios/274/logo.png`,
            comment: 'Com o Consumer Ads conseguimos gerar mais vendas em nosso site de Delivery e aplicativo próprio, sem o pagamento de altas taxas e comissão.',
            author: 'Gil Lanches'
        },
        {
            id: 3,
            image: null,
            comment: 'Essa ferramenta foi importante para gerar vendas em nosso canal de Delivery que lançamos nos último meses para manter nossa operação durante o fechamento.',
            author: 'Bar do Cabral'
        }
    ]

    return (
        <ScreenSection color="#FFF">
            <Zoom duration={2000}><H2>Histórias de sucesso para se inspirar</H2></Zoom>
            <Div>
                <Text>Pessoas reais com histórias incríveis. Veja o que nossos clientes disseram sobre criar anúncios no Google pelo Consumer.
                </Text>
            </Div>
            <Carousel>
                <Previous className="quick-action-left" go={canBack}><Back /></Previous>
                <ContentCarrousel className="quick-action-list">
                    {comments.map(item => (
                        <Card key={item.id} id={`${item.id}`}>
                            {item.image === null ? <img src={Logo} alt="" height="60" width="60"/> : 
                            <img src={item.image} alt="" height="60" width="60"/>}
                            <div><cite>"{item.comment}"</cite><b> - {item.author}</b></div>
                        </Card>
                    ))}                    
                </ContentCarrousel>
                <Next go={canGo} className="quick-action-right"><Back/></Next>
            </Carousel>
            <DivFinal>
                <Zoom><H2>Vamos Começar?</H2></Zoom>
                <Fade><RedButton adaptive={false}/></Fade>
            </DivFinal>
        </ScreenSection>
    )
}

export default ScreenFive
