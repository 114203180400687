import styled from 'styled-components'

export const H2 = styled.h2`
    padding-top: 80px;
    font-weight: bold;
    font-size:${props => props.theme.fonts.largeTitleBold}px;

    @media(max-width: 530px){
        font-size: min(35px, 6vh);
    }
`;

export const Text = styled.p`
    font-size: ${props => props.theme.fonts.normalTextBold}px;
    margin: 20px 0;
`;

export const Div = styled.div`
    max-width: 760px;
    margin: 0 auto;
    padding: 20px 0;
`;

export const ContentDiv = styled.div`
    max-width: 1000px;
    background-color: white;
    border-radius: 10px;
    margin-top: 20px;

    @media(max-width: 730px){
        margin: 0 -30px;
    }

    @media(max-width: 580px){
        margin: 0;
    }

    @media(max-width: 350px){
        padding: 0px;
        margin: 0px;
    }
`;

export const Image = styled.img`
    padding: 30px 50px;
    margin: 0 auto;
    max-height: 600px;
    max-width: 100%;

    @media(max-width: 730px){
        padding: 30px 10px;
    }
`;

export const ContentSection = styled.div`
    display: flex;
    flex: 1 1 300px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 30px 50px 30px;

    div {
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        width: 250px;
        justify-content: space-evenly;
        align-items: flex-start;
        text-align: start;
        height: 200px;
    }

    @media(max-width: 350px){
        div {
            margin: 0;
        }

        margin: 0 10px;
    }
`;

export const Title = styled.p`
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: ${props => props.theme.fonts.normalTextBold}px;
    span {
        margin-right: 10px;
        font-size: ${props => props.theme.fonts.mediumTextBold}px;
        color: ${props => props.theme.color.buttonColor};
    }
`;

export const Description = styled.p`
    font-weight: normal;
    font-size: ${props => props.theme.fonts.caption}px;
    line-height: 30px;
`;

export const Link = styled.span`
    font-weight: normal;
    font-size: ${props => props.theme.fonts.footNote};
    color: ${props => props.theme.color.buttonColor};
    cursor: pointer;
`;

export const BannerDivImage = styled.div`
    position: relative;
    @media(max-width: 508px){
        max-width: 508px;
    }
`;

export const InfoCard = styled.div`
    position: absolute;
    max-width: 50%;
    min-height: 200px;
    max-height: 300px;
    top: 30px;
    left: 50px;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px 35px;
    overflow: hidden;

    @media(max-width: 850px){
        top: 0;
        left: 0;
        max-width: 60%;
        max-height: 99%;

        img { 
            position: absolute;
            top: 20px;
            left: 30px;
            height: 40px;
            width: 35px;
        }
    }

    @media(max-width: 525px){
        font-size: 10px;
        padding: 10px;
        margin: 0px;
    }

    @media(max-width: 508px){
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 508px;
        position: static;
        background-color: ${props => props.theme.color.blackColor};
        margin: 0 20px;
    }
`;

export const ImageBanner = styled.img`
    position: relative;   
    max-height: 600px;
    width: 100%;

    @media(max-width: 508px){
        display: none;
    }
`;

export const CardBannerDiv = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 50px;
    

    b {
        text-align: start;
        font-size: 18px;
    }

    p {
        font-size: ${props => props.theme.fonts.caption}px;
        line-height: 20px;
        text-align: start;

        span{
            font-weight: bold;
        }
    }

    em {
            font-size: 10px;
            text-align: start;
        }

    @media(max-width: 607px){
        b {
            font-size: 16px;
        }
        p {
            line-height: 20px;
            text-align: start;
        }
    }

    @media(max-width: 586px){
        b {
            margin-top: 10px;
            font-size: 16px;
        }

        p > b{
            font-size: 16px;
        }
    }

    @media(max-width: 563px){

        b {
            margin-left: 10px;
            font-size: 16px;
        }

        p { 
            margin-left: 10px;
        }

        p > b{
            font-size: 16px;
        }

        em {
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }

    @media(max-width: 508px){
        max-width: 508px;
        b {
            font-size: 16px;
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
        }

        p > b{
            font-size: 18px;
        }
    }

    @media(max-width: 445px){
        b {
            font-size: 16px;
            margin-bottom: 10px;
        }
        p{
            font-size: 14px;
        }

        p > b{
            font-size:14px;
        }
    }

    @media(max-width: 338px){
        b {
            font-size: 18px;
            margin-bottom: 10px;
        }
        p {
            font-size: 14px;
        }

        p > b {
            font-size: 14px;
        }
    }
`;

