import styled from 'styled-components'

export const Container = styled.header`
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.theme.color.blackColor};
    color: ${props => props.theme.color.whiteColor};
    position: fixed;
    padding: 10px 20px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, .5);
    z-index: 1000;

    @media(max-width: 350px){
        padding: 5px 10px;
    }
`;

export const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;

    p{
        margin-left: 10px;
        font-size: ${props => props.theme.fonts.title2BottomBold}px;
        font-weight: lighter;
    }

    @media(max-width: 410px){
        img {
            width: 25px;
            height: 25px;
        }

        p{
            font-size: 16px;
        }
    }

    @media(max-width: 360px){
        img {
            width: 20px;
            height: 20px;
        }

        p{
            font-size: 14px;
        }
    }
`;