import styled, {keyframes} from "styled-components";

const isPassing = keyframes`
    from {
        transform: translateX(-100%); 
    
  }
`

export const PlaceholderMetrics = styled.div`
    min-height: 500px;
    width: 90%;
    margin: 0 auto;
    background: white;
    padding: 10px;

    @media(min-width: 450px){
        height: 500px;
    }
    
`;

export const GridDiv = styled.div`
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 80px repeat(3, 1fr);
        grid-gap: 10px;
        overflow: hidden;

    @media(max-width: 450px){
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
    }
`

export const PlaceholderGraph = styled.div`
    grid-row: 2/4;
    grid-column: 2/5;
    background: #DCDCDC;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    

    > span {
            margin: 8px 10px;
            height: 10px;
            background: #c5c5c5;
            border-radius: 5px;
            overflow: hidden;
            position: relative;
        }

    &::before{
            animation: ${isPassing} 1s ease-in infinite;
            background:
            linear-gradient(
                -45deg,
                #E8E8E8 80%,
                #E8E8E8 10%,
                #F8F8F8
            );
            content: '';
            display: block;
            height: 100vh;
            position: absolute;
            width: 80vw;
        }

        @media(max-width: 450px)
        {
            height: 200px;
        }
`;

export const PlaceholderItem = styled.div`
    background: #DCDCDC;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    

    > span {
            margin: 8px 10px;
            height: 10px;
            background: #c5c5c5;
            border-radius: 5px;
            overflow: hidden;
            position: relative;
        }

    &::before {
            animation: ${isPassing} 1s ease-in infinite;
            background:
            linear-gradient(
                -45deg,
                #E8E8E8 80%,
                #E8E8E8 10%,
                #F8F8F8
            );
            content: '';
            display: block;
            height: 100vh;
            position: absolute;
            width: 50vw;
        }

        @media(max-width: 450px)
        {
            height: 100px;
        }
`;
