import styled from 'styled-components';

export const Modal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(200,200,200,.6);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 30px;
    
    > div {
        flex: 1 1 650px;
    }
`;

export const ModalContent = styled.form`    
    flex: 1;
    min-width: 50%;
    max-width: 600px;
    height: 70%;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 30px;
    text-align: center;    

    div:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;        

        input {
            margin-right: 10px;
            cursor: pointer;
        }

        label {
            cursor: pointer;
        }
    }

    
`;

export const ModalFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    span {
        cursor: pointer;
    }

    button:first-of-type {
        border: none;
        background-color: transparent;      
        outline  : none ;
        color: #333;
        box-shadow: none;
    }

    button:first-of-type:hover {
        box-shadow: 1px 1px 3px rgba(0,0,0,.1);
        transition: .3s;
    }

    button {
        width: 200px;
        height: 40px;
        outline: none;
        cursor: pointer;
        background-color: ${props => props.theme.color.blackColor};
        color: #fff;
        border: none;
        box-shadow: 1px 1px 5px rgba(0,0,0,.6);
    }
`;