import styled from "styled-components";

export const PlaceholderComponentCreditAd = styled.div`
    max-width: 1200px;
    border-top: 2px solid white;
    position: relative;

    @media(min-width: 731px){ 
        margin: 20px;
    }
`