import { useEffect } from 'react'
import { Container, Title } from './styles'
import Happy from '../../../images/happy.png'
import { Button } from '../../Shared/Steps/styles'
import { useNavigate } from 'react-router-dom'

const Index = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scroll({top: 0, behavior: 'smooth'})
    },[])


    return (
        <Container>
            <Title>
                <b>Anúncios no Google criados com sucesso!</b>                
                
                <img src={Happy} alt="Anúncio do Google criado!"/>
                <span>E agora, quais são os próximos passos?</span>
                <p>Clique no botão abaixo e adicione créditos para ativar seus anúncios!</p>
                <Button onClick={() => navigate('/dashboard')}>Gerenciar campanha</Button>                
            </Title>
            
            {/* <Card>
                <div><CardTitle><b>E agora, quais são os próximos passos?</b></CardTitle></div>
                <CardContent>
                    <div>
                        <b>Gerencie sua campanha</b>
                        <p>Acompanhe o desempenho da sua campanha quantas vezes seu anúncio foi visualizado, clicado e convertido em vendas</p>
                    </div>
                    <div>
                        <b>Utilize seu crédito exclusivo</b>
                        <p>Não esqueça de utilizar seu crédito exclusivo de 225,00 e mantenha sua campanha ativa por mais 30 dias.</p>
                    </div>
                    <div>
                        <b>Faça novos investimentos</b>
                        <p>Consistência geram resultados. Defina um plano de marketing e continue fazendo novos investimentos. Bons negócios!</p>
                    </div>
                </CardContent>
            </Card> */}
        </Container>
    )
}

export default Index
