/* eslint-disable react-hooks/exhaustive-deps */
import {useRef, useCallback} from 'react'
import { Container, Div } from './styles'
import Logo from '../../../images/icons/logo-branco.svg';
import RedButton from '../RedButton/RedButton';
import Modal, { ModalHandles } from '../Shared/Modal/Modal';
import { useNavigate } from 'react-router';

const Header = () => {

    const modalRef = useRef<ModalHandles>(null);
    const navigate = useNavigate();

    const handleClick = useCallback(() => {

            const ntf = window.localStorage.getItem('notfound');

            if(ntf !== null){
                window.localStorage.removeItem('notfound');
                navigate('/')
            } else {
                window.scroll({
                    top: 0,
                    behavior: 'smooth',
                })
            }
        },[],
    )
    return (
        <Container>
            <Div onClick={handleClick} className='removeSelection'>
                <img src={Logo} alt="Consumer Ads" height="35" width="35"/>
                <p><span>Consumer</span> <b>Ads</b></p>
            </Div>
            <RedButton adaptive={true}/>
            <Modal ref={modalRef}/>
        </Container>
    )
}

export default Header
