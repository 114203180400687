import styled, { css, keyframes } from 'styled-components'

interface ModalProps{
    animation: string;
}

const show = keyframes`
    from{
        opacity: 0;
        top: -100vh;
    }
    to{
        opacity: .99;
        top: 0;
    }
`;

const hide = keyframes`
    from{
        opacity: .99;
    }
    to{
        top: -100vh;
        opacity: 0;
    }
`;

const step2 = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const Content = styled.div<ModalProps>`
    background-color: rgba(23,24,41,.8);
    position: fixed;
    z-index: 5000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    animation: ${props => props.animation === 'hide' ? css`${hide} .3s forwards` : css`${show} .5s forwards`} ;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
`;

export const Div = styled.div`
    width: max(40%, 300px);
    height: max(50%, 350px);
    margin: 50px auto 0 auto;
    border-radius: 8px;
    background-color: white;
    padding: 5px 30px;
    position: relative;



`;

export const Close = styled.span`
    position: absolute;
    right: 10px;
    top: 10px;
    line-height: 25px;
    text-align: center;
    background-color: ${props => props.theme.color.blackColor};
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 30px;
    transform: rotate(45deg);
    color: white;
    transition: .5s;
    cursor: pointer;
    box-shadow: 2px 2px 8px rgba(0,0,0,.5);

    &:hover{
        background-color: black;
    }
`;

export const Props = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    flex-direction: column;
    color: ${props => props.theme.color.blackColor};
    text-align: center;

    h3 {
        margin-top: 20px;
        font-size: 18px
    }

    input {
        padding-left: 8px;
        margin-top: 30px;
        border: 1px solid ${props => props.theme.color.iceWhite};
        height: 30px;
        width: max(70%, 200px);      
        font-size: 12px;  
        transition: .6s;

        &:focus{
            outline: none;
        }
        &:hover{
            border: 1px solid ${props => props.theme.color.blackColor};
        }
    }

    button {
        background-color: ${props => props.theme.color.blackColor};
        color: white;
        height: 30px;
        width: max(70%, 200px);
        margin-top: 10px;
        border: 1px solid ${props => props.theme.color.blackColor};
        outline: none;
        cursor: pointer;
        transition: all .2s ease-out;
        box-shadow: 2px 2px 8px rgba(0,0,0,.5);

        &:hover{
            color: white;
            border: 1px solid ${props => props.theme.color.blackColor};
            filter: sepia(2);
        }
    }

    @media(max-width: 440px){
        margin: 20px 10px;
    }

    @media(max-width: 413px){
        h3{
            font-size: 16px;
        }
    }
    @media(max-width: 377px){
        h3{
            font-size: 14px;
        }
    }
`;

export const Step1 = styled.div`
    p{
        margin-top: 10px;
        font-size: 14px;
        text-align: center;
    }

    input {
         margin-bottom: 5px;
         height: 35px !important;
    }

    button {
        height: 35px !important;
    }
`;

export const Error = styled.p`
    font-size: 12px !important;
    color: ${props => props.theme.color.buttonColor};
`;

export const ErrorLogin = styled.span`
    font-size: 12px !important;
    color: ${props => props.theme.color.buttonColor};
`;

export const Step2 = styled.div`
    animation: ${step2} .5s forwards;

    p{
        margin: 30px 0 10px 0;
        font-size: 14px;
        text-align: start;
    }

    div {
        width: max(60%, 200px);        
        position: relative;
        margin: 0 auto;
        text-align: start;
    }

    div > input{
        margin-bottom: 5px;
        height: 35px !important;
        margin: 30px auto 10px auto;
        position: relative;
        width: 100%;
    }

    button {
        width: max(60%, 200px);
        div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }



    @media(min-width: 555px){
        width: 434px;
        p{
            text-align: center;
        }
    }

    @media(max-width: 558px){
        max-width: 370px;
    }
`;

export const Recover = styled.a`
    position: relative;
    font-size: 12px;
    margin-top: 20px;
    text-decoration: none;
    color: gray;
    outline: none;
`;

export const Back = styled.img`
    position: absolute;
    left: 10px;
    top: 12px;
    line-height: 25px;
    opacity: .5;
    text-align: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 30px;
    transform: rotate(180deg);
    transition: .5s;
    cursor: pointer;
`;

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const Spin = styled.div`
    line-height: 20px;
    width: 20px;
    height: 20px;
    text-align: center;
    margin: 0 auto;

    svg {
        animation: ${rotate} infinite 1.5s linear;
    }
`;

export const See = styled.span`
    position: absolute;
    right: 10px;
    top: 38px;
    cursor: pointer;
    svg {
        color: gray;
    }

    @media(max-width: 558px){
        right: 90px;
    }

    @media(max-width: 554px){
        right: 8px;
    }
`;