export enum PaymentMethod {
    Boleto = 1,
    Pix = 2,
    CreditCard = 3
}

export class AddCredit {
    userAdsAccountId: string;
    paymentMethod: PaymentMethod;
    price: Number;
    creditCard?: CreditCard

    constructor(userAdsAccountId: string, paymentMethod: PaymentMethod, price: Number, creditCard?: CreditCard) {
        this.userAdsAccountId = userAdsAccountId;
        this.paymentMethod = paymentMethod;
        this.price = price;
        this.creditCard = creditCard
    }

    static toJson(userAdsAccountId: string, paymentMethod: PaymentMethod, price: Number, creditCard?: CreditCard): AddCredit {
        return new AddCredit(userAdsAccountId, paymentMethod, price, creditCard)
    }
}

export class CreditCard {
    cardNumber: string;
    holder: string;
    expirationDate: string;
    securityCode: string;
    brand: string;
    activateAutoRecharge: boolean

    constructor(cardNumber: string, holder: string, expirationDate: string, securityCode: string, brand: string, activateAutoRecharge: boolean) {
        this.cardNumber = cardNumber;
        this.holder = holder;
        this.expirationDate = expirationDate;
        this.securityCode = securityCode;
        this.brand = brand;
        this.activateAutoRecharge = activateAutoRecharge
    }

    static toJson(cardNumber: string, holder: string, expirationDate: string, securityCode: string, brand: string, activateAutoRecharge: boolean): CreditCard {
        return new CreditCard(cardNumber, holder, expirationDate, securityCode, brand, activateAutoRecharge);
    }
}
