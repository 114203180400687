import styled, { keyframes } from 'styled-components'

export const Content = styled.section`
    width: 100%;
    height: 100%;
    background: #F4F4F7;
    padding-bottom: 30px;
    margin: 0 auto;
    padding: 0;
    /* padding-right: 3px; */
`

export const InfoScreen = styled.div`
    height: calc(100vh - 50px - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;

    p {
        padding: 20px;
        text-align: center;
        font-weight: bold;
    }
`;

const spin = keyframes`
    to {
        transform: rotate(360deg)
    }
`

export const Spin = styled.div`
    width: 60px;
    height: 60px;
    border: 5px solid gray;
    border-left-color: ${props => props.theme.color.blackColor};
    animation: ${spin} 1s linear infinite;
    border-radius: 50%;
`;