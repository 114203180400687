import styled from 'styled-components'

interface ButtonProps {
    adaptive: boolean
}

export const Button = styled.button<ButtonProps>`
    background-color: ${props => props.theme.color.buttonColor};
    color: ${props => props.theme.color.whiteColor};
    outline: none;
    border: 1px solid transparent;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bolder;
    font-size: ${props => props.theme.fonts.title2BottomBold};
    transition: .5s;
    max-width: 200px;
    
    &:hover{
        background-color: ${props => props.theme.color.redColor};
    }

    @media(max-width: 600px){
        font-size: ${props => props.adaptive === true ? '10px' : props.theme.fonts.title2BottomBold};
    }
`;