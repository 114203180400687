import { PlaceholderAd } from './styles'


const index = () => {
    return (
        <PlaceholderAd>
            <span style={{width: "70%", height: "10px"}}></span>
            <span style={{width: "90%", height: "10px"}}></span>
            <br/>
            <span style={{width: "80%", height: "8px"}}></span>
            <span style={{width: "80%", height: "8px"}}></span>
            <span style={{width: "80%", height: "8px"}}></span>

        </PlaceholderAd>
    )
}

export default index
