import styled, { keyframes } from 'styled-components';

const animeLeft = keyframes`
    from {
        opacity: 0;
        left: -50px;
    }
    to {
        opacity: 1;
        left: 0;
    }
`

export const Container = styled.div`
    animation: ${animeLeft} .5s forwards;
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    position: relative;
    padding-bottom: 50px;
`

export const Title = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    b {
        font-size: 1.3rem;
    }
    span {
        font-size: .7rem;
        margin-top: 10px;
    }
`

export const Content = styled.div`
    background: #f4f4f7;
    display: flex;
    flex: 1 1 200px;
    padding: 80px 30px 0 30px;
    flex-wrap: wrap;
    margin: 20px auto;
    justify-content: space-evenly;
    border-radius: 10px;
    /* margin-bottom: 70px; */
    @media(max-width: 539px){
        padding: 0 30px;
    }
`;

export const ErrorSection = styled.div`
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    > span{
        cursor: pointer;
        margin-top: 20px;
        font-weight: bold;
        background: ${props => props.theme.color.buttonColor};
        color: white;
        padding: 10px 20px;
        border-radius: 5px;
        box-shadow: 1px 1px 10px rgba(0,0,0,.5);
        transition: .3s;

        &:hover{
            filter: brightness(110%);
        }
    }
`;

export const Footer = styled.div`
    font-size: .8rem;
    text-align: start;
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 0px auto;
    padding: 0 10px 50px 10px;

    svg {
        margin-right: 20px;
    }

    > div {
        p {
            margin-bottom: 10px;
            text-align: center;
        }

        em {
            font-size: 10px;
            
        }
    }

    @media(max-width: 500px){
        margin: 10px;
        justify-content: center;
        align-items: center;

        svg {
            margin-left: 20px;
            height: 90px;
            width: 90px;
        }
    }
`

export const ErroDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: min(700px,90%);
    padding: 20px;

    > p {
        font-weight: 600;
        margin-bottom: 10px;
    }

    > p:nth-child(2n) {
        font-weight: normal;
    }

    > div > pre {
        max-width: 100%;
        min-height: 100px;
        margin: 10px 0;
        text-align: start !important;
        font-size: .9rem;
        background: #FBFBFB;
        padding: 5px;
        /* word-wrap: break-word; */

        @media(max-width: 400px){
            overflow-y: auto;
        }
    }

    > span {
        cursor: pointer;
        margin-top: 20px;
        margin-bottom: 30px;
        font-style: italic;
        transition: .5s;
        text-align: right;

        &:hover{
            color: rgba(0,0,0,.6)
        }
    }

    > a {
        text-decoration: none;
        color: ${props => props.theme.color.redColor};
        cursor: pointer;
    }
`



