import styled, { keyframes } from 'styled-components';


const anime = keyframes`
    to {
        transform: rotate(360deg);
    }
`

export const LoadScreen = styled.section`
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        margin-top: 20px;
    }
`;

export const Spin = styled.div`
    border: 5px solid rgba(0,0,0, .1);   
    border-left-color: ${props => props.theme.color.blackColor}; 
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation: ${anime} 1s linear infinite;
`;