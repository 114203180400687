const API_URL = process.env.REACT_APP_URL;

export const SIGN_IN = `${API_URL}/v1/Token/SignIn`;
export const TOKEN_SIGN_IN = `${API_URL}/v1/Token/SignInByMenuDinoToken`;
export const MENU_INFO = `${API_URL}/v1/Menu/MenuInfo`;
export const MENU_AD_PREVIEW = `${API_URL}/v1/Menu/AdPreview`;
export const CREATE = `${API_URL}/v1/AdsAccount/CreateAll`;
export const CREATE_CAMPAIGN = `${API_URL}/v1/AdsAccount/CreateCampaign`;
export const DATA_CAMPAIGN = `${API_URL}/v1/AdsAccount/GetReport`;
export const CURRENT_CREATION_STATUS = `${API_URL}/v1/AdsAccount/GetCurrentCreationStatus`;
export const GET_CAMPAIGN = `${API_URL}/v1/AdsAccount/GetCampaign`;
export const GET_INFOS = `${API_URL}/v1/AdsAccount/GetInfos`;
export const GET_USER_DATA = `${API_URL}/v1/AdsAccount/GetUserData`;
export const ADD_CREDIT = `${API_URL}/v1/Payments/AddCreditRequest/`;
export const REMOVE_AUTO_RECHARGE = `${API_URL}/v1/Payments/UnsetAutoRecharge/`;
export const UPDATE_AUTO_RECHARGE_AMOUNT = `${API_URL}/v1/Payments/UpdateAutoRechargeAmount`;
export const PAYMENT_DETAILS = `${API_URL}/v1/Payments`