import { useEffect, useState } from "react"
import { Modal, ModalContent, ModalFooter } from "./styles"
import { useNavigate } from "react-router-dom"

const Index = () => {
    const [showModal, setShowModal] = useState<boolean>(true)
    const navigate = useNavigate()

    function continueCampaign(e: any) {
        e.preventDefault()
        document.body.style.overflow = 'scroll';
        setShowModal(false)
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden';
    }, [])
    return (
        showModal ?
            <Modal onSubmit={continueCampaign}>
                <ModalContent>
                    <h1>Leia com atenção:</h1>
                    <p>Recomendamos a criação de campanhas pelo Consumer Ads, apenas para cidades acima de 500 mil habitantes, cidades com população menor não possui um volume de buscas na internet, recomendado para criação da campanha e consumo do orçamento.
                    </p>
                    <div>
                        <input required type="checkbox" id="ciente" name="ciente" /><label htmlFor="ciente">Estou ciente</label>
                    </div>
                    <ModalFooter>
                        <button onClick={() => navigate('/')}>Cancelar</button>

                        <button type="submit">Continuar</button>

                    </ModalFooter>
                </ModalContent>
            </Modal > : <></>
    )
}

export default Index