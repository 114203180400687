/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Card, CardContent, CardHead, CardSubtitle, CardTitle, Container, Content, Foot, FootContent, FootTitle, LoadKey, Title } from './styles';
import Ads from '../../../images/icons/ads.png';
import { FaSearch } from 'react-icons/fa';
import useFetch from '../../../Hooks/useFetch';
import LoadScreen from '../../Shared/Load';
import { MENU_AD_PREVIEW } from '../../../api';
import { ErrorSection } from '../Investment/styles';

const Index = () => {
    const { loading, error, request, data } = useFetch();
    const [loadingKey, setLoadingKey] = useState(true);
    const [errorResp, setErrorResp] = useState<boolean>(false);

    useEffect(() => {
        const abortCont = new AbortController();

        window.scroll({top: 0, behavior: 'smooth'});
        async function getAdPreview(){
            try {
                const { response } = await request(MENU_AD_PREVIEW, {
                    method: 'GET',
                    signal: abortCont.signal,
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('token')}`
                    }
                })

                if(response?.ok){
                    setLoadingKey(false)
                }
            } catch(e){
                setErrorResp(true);
            }
        }
        getAdPreview();
        window.localStorage.setItem("currentStep", "2")
        
        return () => abortCont.abort();
    },[])

    if(loading) return <LoadScreen message="" />

    if(error) return <ErrorSection>Ocorreu um erro ao executar esta operação.</ErrorSection>

    if(errorResp) return <ErrorSection>Ocorreu um erro ao executar esta operação.</ErrorSection>

    if(data === null) return <ErrorSection>Nenhum dado encontrado.</ErrorSection>

    return (
        <Container>
            <Title><b>Nossa recomendação de anúncio para você</b></Title>
            <Content>
                {data.content.map((item: any, index: any) => (
                    <Card key={index}>
                    <img src={Ads} alt="" height="20"/>
                    <CardHead><b>Versão {index + 1}</b></CardHead>
                    <CardTitle><span onClick={() => window.open(item.url)}><b>Anúncio</b> - {item.url}</span></CardTitle>
                    <CardSubtitle><p>{item.title}</p></CardSubtitle>
                    <CardContent>{item.description}</CardContent>
                </Card>
                ))}
                
            </Content>
            <Foot>
                <FaSearch height="25"/>
                <FootTitle>
                    <b>Palavras-chave Selecionadas</b>
                    <span>É o que as pessoas vão digitar na pesquisa do Google para que seu anúncio apareça.</span>
                </FootTitle>
                <FootContent lines={false}>
                    {
                       loadingKey === true ? <LoadKey /> : 
                       data.content[0].keywords !== null ?
                       data.content[0].keywords.slice(0, 15).map((item: any, index: any) => (
                       <p key={index}>{item}</p>)) : <p>nenhuma palavra-chave encontrada.</p>
                    }
                    <br/>
                    {/* <b onClick={() => setLines(!lines)}>{lines === false ? 'Ver mais +' : 'Ver menos -'}</b> */}
                </FootContent>
            </Foot>
        </Container>
    )
}

export default Index
