import { FC, useEffect, useState } from 'react'
import { Container, Content, Title, Footer, ErrorSection, ErroDescription } from './styles';
import useFetch from '../../../Hooks/useFetch';
import { CREATE, CURRENT_CREATION_STATUS } from '../../../api';
import LoadScreen from '../../Shared/Load';
import { useNavigate } from 'react-router';
import CardPlans from '../../Shared/CardPlans';
import { FaRedo } from 'react-icons/fa';

interface InvestmentProps {
    next: () => void
}

const Index: FC<InvestmentProps> = (props) => {

    enum Status {
        Done,
        Account,
        AccountConversion,
        BillingSetup,
        AccountBudget,
        Campaign,
        CampaignAdGroup,
        CampaignAds,
        CampaignKeywords,
        CampaignKeywordsNegative,
        CampaignProximity,
        CampaignBusinessHours,
        CampaignCallConversion
    }

    const { loading, request } = useFetch();
    const [errorCurrentState, setErrorCurrentState] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [errorJson, setErrorJson] = useState<string>('');
    const [detailError, setDetailError] = useState<boolean>(false);
    const navigate = useNavigate();

    const create = async (amount: any) => {

        const abortCreate = new AbortController();
        const abortCampaign = new AbortController();
        let js;

        try {
            const { response, json } = await request(CREATE, {
                method: 'POST',
                signal: abortCreate.signal,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })

            js = json;

            if (response?.ok && json?.success === true) {
                localStorage.setItem("userAdsAccountId", json.content.userAdsAccountId)
                localStorage.setItem("valorEscolhido", amount);
                navigate("/checkout");
            } else if (response?.status === 500 || json.success === false) {
                setErrorMessage(json?.error_message)
                setErrorJson(JSON.stringify(json, null, 2));
                checkCurrentStatus(json?.error_messsage)
            } else if (response?.status === 403 || response?.status === 401) {
                navigate('/login')
            } else {
                setErrorMessage(json.error_message)
                setErrorJson(JSON.stringify(json, null, 2));
                checkCurrentStatus(json?.error_messsage)
            }
        } catch (e: any) {
            setErrorMessage(js?.error_message)
            setErrorJson(JSON.stringify(js, null, 2));
            checkCurrentStatus(js?.error_message)
            if (e.message === 'AbortError') {
            } else {
                abortCreate.abort();
                abortCampaign.abort();
            }
        }
    }

    async function checkCurrentStatus(errorMessage: string) {
        let response: any;
        let json: any;

        try {
            response = await fetch(CURRENT_CREATION_STATUS, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })

            if (response?.ok) {
                json = await response.json();
                let st: Status = Status[json["content"] as keyof typeof Status]
                setErrorCurrentState(`Current State: ${json["content"]} - ${st} / Error Json`)
            } else {
                setErrorMessage(errorMessage);
                setErrorCurrentState(json["content"].toString())
            }
        } catch (e: any) {
            setErrorMessage(`${errorMessage} / ${e.message}`)
            setErrorCurrentState(e.message)
        }
    }

    useEffect(() => {
        window.localStorage.setItem("currentStep", "3")
        window.addEventListener("beforeunload", alertUser);

        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, []);

    const alertUser = (e: any) => {
        e.preventDefault();
        e.returnValue = "";
    };

    if (loading) return <div>
        <LoadScreen message={`${'Criando campanha.'} Isso pode levar alguns minutos... Não atualize a página!`} />
    </div>

    if (errorCurrentState || errorMessage) return (

        <ErrorSection>
            {errorCurrentState.includes('already') ? (
                <>
                    <p>Você já possui uma campanha no Google!</p>
                    <p onClick={() => navigate('/dashboard')}>Clique aqui para gerenciar</p>
                </>

            ) :
                <ErroDescription>
                    <p>Ops... Algo deu errado!</p>
                    <p>{errorMessage}</p>
                    {
                        detailError &&
                        <div>
                            {errorCurrentState && <p>{errorCurrentState}</p>}
                            <pre><code>{errorJson}</code></pre>
                        </div>
                    }
                    <span onClick={() => setDetailError(!detailError)}>{detailError ? 'Ocultar' : 'Exibir'} detalhes do erro</span>
                    <p style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => {
                        window.localStorage.setItem("currentStep", '3');
                        window.location.reload();
                    }}><FaRedo style={{ marginRight: '5px' }} /> Tentar novamente</p>
                    <br />
                    <a href='https://loja.consumer.com.br/contato' target='_blank' rel="noreferrer">Entrar em contato com nosso suporte</a>
                </ErroDescription>
            }
        </ErrorSection>
    )

    return (
        <Container>
            <Title>
                <b>Quanto gostaria de investir?</b>
                <span>Fique tranquilo, você não será cobrado mensalmente.</span>
            </Title>
            <Content>
                <CardPlans isRecommended={false} cardTitle="Iniciante" create={() => create(150)} fee="7,50" price={150} spendDay={5} totalPrice={"142,50"} />
                <CardPlans isRecommended={false} cardTitle="Básico" create={() => create(300)} fee="15,00" price={300} spendDay={10} totalPrice={"285,00"} />
                <CardPlans isRecommended={true} cardTitle="Profissional" create={() => create(600)} fee="30,00" price={600} spendDay={20} totalPrice={"570,00"} />
                <CardPlans isRecommended={false} cardTitle="Avançado" create={() => create(1200)} fee="60,00" price={1200} spendDay={30} totalPrice={"1.140,00"} />
            </Content>
            <Footer>
                <div className='title'>
                    <p>Após selecionar o valor, você será redirecionado para tela de checkout com instruções para finalizar o pagamento.</p>
                    <p>Todas opções representam aproximadamente 30 dias de campanha</p>
                </div>
            </Footer>
        </Container>
    )
}

export default Index