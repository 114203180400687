import styled from 'styled-components';

export const Div = styled.div`
    max-width: 1200px;
    padding: 0 80px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media(max-width: 677px){
        margin: 0 auto;
        padding: 0 30px;
    }

    @media(max-width: 580px){
        margin: 0 auto;
        padding: 0 20px;
    }

    @media(max-width: 380px){
        margin: 0 auto;
        padding: 0 10px;
    }
`;