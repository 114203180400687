import styled from 'styled-components';


export const H2 = styled.h2`
    padding-top: 80px;
    font-weight: bold;
    font-size:${props => props.theme.fonts.largeTitleBold}px;
`;

export const Text = styled.p`
    font-size: ${props => props.theme.fonts.normalTextBold}px;
`;

export const Div = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 0;
`;

export const Carousel = styled.div`
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 20px 20px 0 20px;
    align-items: center;

    @media(max-width: 500px){
        padding: 0;
    }
`;

interface PreviusProps {
    go: boolean
}
export const Previous = styled.div<PreviusProps>`
    opacity: ${props => props.go === true ? 1 : 0.5};
    transform: rotate(180deg);
    width: 50px;
    margin-right: 10px;
    cursor: ${props => props.go === true ? 'pointer' : 'none'};
    outline: none;

    @media(max-width: 445px){
        svg {
            width: 10px;
            height: 39px;
        }
    }
`;

interface NextProps {
    go: boolean
}

export const Next = styled.div<NextProps>`
    cursor: ${props => props.go === false ? 'none' : 'pointer'};
    opacity: ${props => props.go === true ? 1 : 0.5};
    z-index: 2;
    width: 50px;
    margin-left: 10px;
    outline: none;
    
    @media(max-width: 445px){
        svg {
            width: 10px;
            height: 39px;
        }
    }
`;

export const ContentCarrousel = styled.div`
    display: flex;
    /* z-index: 2; */
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    ::-webkit-scrollbar{
        width: 0px;
        height: 0px;
    }
`;

export const Card = styled.div`
    position: relative;
    min-width: 350px;
    background-color: ${props => props.theme.color.tinWhite};
    display: flex;
    border-radius: 5px;
    scroll-snap-type: x mandatory !important;
    margin: 20px;
    img {
        position: absolute;
        top: -15px;
        left: -20px;
        border-radius: 50%;
    }

    div {
        padding: 30px 30px 10px 40px;
    }
`;

export const DivFinal = styled.div`
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;

    h2{
        padding: 0;
        margin: 0;
    }

    button {
        margin-top: 30px;
    }
`;
