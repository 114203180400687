import React from 'react'
import { FooterDiv } from './styles'

const Footer = () => {
    return (
        <FooterDiv />
    )
}

export default Footer
