import styled, { keyframes } from "styled-components";

export const Content = styled.section`
    min-width: 100vw;
    min-height: 100vh;
    position: relative;
    padding-top: 4rem;
    /* background: rgb(0,15,12); */
`

export const Header = styled.header`
    height: 3.8rem;
    width: 100%;
    padding: 0 30px;
    color: white;
    background: ${props => props.theme.color.blackColor};
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    div:first-of-type{
        cursor: pointer;
    }

    > div {
        display: flex;
        align-items: center;

        > p {
            margin-left: 10px;
            font-weight: 200;
        }

        > span {
            font-size: 12px;
            margin-right: 50px;
            font-weight: 200;
        }

        > div {
            display: flex;
            align-items: center;

            > p {
                margin-right: 10px;
                font-weight: 200;
                font-size: 12px;
            }
        }
    }
    
.out {
    > div {
        cursor: pointer;
    }
    @media(max-width: 658px){
        > span {
            display: none;
        }
    }
}
`

export const DivStep = styled.div`
    height: 60px;
    width: 100vw;
    background: white;
    border-bottom: 6px solid ${props => props.theme.color.buttonColor};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    > p {
        font-weight: bold;
        font-size: .8rem;
    }
`

export const Section = styled.div`
    padding-top: 4rem;
    margin: 0 auto;
    max-width: 700px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;

    > h3 {
        margin-bottom: 15px;
    }

    > p {
        margin-top: 15px;
    }

    > button {
        padding: 10px 15px;
        margin-top: 10px;
        outline: none;
        background: ${props => props.theme.color.buttonColor};
        border: 1px solid transparent;
        border-radius: 8px;
        color: white;
        font-weight: bold;
        box-shadow: 1px 1px 8px rgba(0,0,0,0.5);
        cursor: pointer;
        transition: .3s;
        margin-bottom: 3rem;

        &:hover{
            box-shadow: 0px 0px 10px rgba(0,0,0,0.9);
        }
    }

    /* > div {
        margin-top: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1 1 1 400px;
        flex-wrap: wrap;

        @media(max-width: 656px){
            flex-direction: column;
        }
    } */
`

export const Pix = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    > b {
        margin-top: 40px;
        margin-bottom: 10px;
    }

    > small{
        margin-bottom: 0;
    }

    > img {
        margin: 0;
        padding: 0;
        height: 200px;
    }
`

export const BarCode = styled.div`
    display: flex;
    align-items: center;  
    flex-direction: column;
    flex-wrap: wrap;

    > b {
        margin-top: 40px;
    }

    > small {
        margin-top: 10px;
    }

    > div {
        border: 1px solid gray;
        padding: 15px 50px 15px 15px;
        position: relative;
        width: 100%;
        word-wrap: break-word;
        margin: 20px 0;
        text-align: start;

        > svg {
            position: absolute;
            right: 10px;
            top: 12px;
            bottom: 12px;
            color: gray;
            cursor: pointer;
            height: 25px;
            width: 25px;
            transition: .5s;

            &:hover {
                color: #fdc500;
            }   
        }

        &:hover {
            border-color: #fdc500;
        }
    }

    > button {
        padding: 5px 10px;
        outline: none;
        border: 1px solid transparent;
        background: ${props => props.theme.color.blackColor};
        color: white;
        border-radius: 5px;
        box-shadow: 1px 1px 8px rgba(0,0,0,0.5);
        cursor: pointer;
        transition: .5s;

        &:hover{
            border-color: ${props => props.theme.color.blackColor};
            color: ${props => props.theme.color.blackColor};
            background: white;
        }
    }
`

const show = keyframes`
    from {
        right: -100px;
        opacity: 0;
    } 
    to {
        right: 30px; 
        opacity: 1;
    }
`

export const CopyText = styled.div`
    position: fixed;
    animation: ${show} .5s linear forwards;
    z-index: 1000;
    top: 30;
    padding: 0 20px;
    font-size: .8rem;
    height: 50px;
    color: #007200;
    background: #b7e4c7;
    border: 1px solid #007200;
    font-weight: 600;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 3px 8px rgba(0,0,0,0.4);
`

export const Info = styled.div`
    display: flex;
    text-align: start;
    align-items: flex-start;
    justify-content: start;
    flex-direction: column;
    background: #fdffb6;
    border: 1px solid #ffb703;
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 40px;
    margin-bottom: 30px;
    border-radius: 8px;
    
    > ul {
        text-align: start;
        font-size: 14px;
        margin-top: 10px;
        > li {
            margin-left: 20px;
        }
    }
`