import styled from 'styled-components'

export const NotFound = styled.section`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > b {
        text-align: center;
        margin: 20px;
        font-size: 30px;
    }

    > button {
        border: none;
        background: ${props => props.theme.color.blackColor};
        height: 50px;
        padding: 20px 40px;
        border-radius: 5px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }

    > p {
        padding: 0 30px;
        text-align: center;
    }

    > ul {
        padding: 0 30px;
        margin-top: 30px;
        /* text-align: center; */
    }

    > ul > li {
        list-style: circle;
        margin: 10px 0;
    }

    > ul > li > span {
        cursor: pointer;
        text-decoration: none;
        color: ${props => props.theme.color.blackColor};
        transition: .4s;

        :hover{
            color: ${props => props.theme.color.buttonColor};
        }
    }
`

export const Top = styled.div`
    position: fixed;
    top: 0;
    left: 0;
`

export const Bottom = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    height: 90px;
    background: ${props => props.theme.color.blackColor};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > img {
        cursor: pointer;
    }

    > a {
        margin-top: 5px;
        text-decoration: none;
        color: white;
    }
`