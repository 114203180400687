/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Md from '../../../images/md.png';
import Maps from '../../../images/map.png';
import { GridImage, GridTitle, GridCenter1, GridCenter2, GridAside, GridFooter, TextCenter, OpenNow, MenuDinoCard, Cover, Logo, MdTitle, GridCenter } from './styles'
import { FaPlay, FaMapMarkerAlt, FaRegClock, FaChevronCircleRight, FaStore } from 'react-icons/fa'
import { Container } from './styles';
import LoadScreen from '../../Shared/Load/index';
import { CURRENT_CREATION_STATUS, MENU_INFO } from '../../../api';
import useFetch from '../../../Hooks/useFetch';
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const [getCampaign, setGetCampaign] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("")
    const { loading, error, request, data } = useFetch();
    const navigate = useNavigate();

    useEffect(() => {
        const abortCont = new AbortController();
        const abortContGet = new AbortController();

        window.scroll({top: 0, behavior: 'smooth'});
        window.localStorage.removeItem('url')

        async function verifyCampaign(){
            try {
                const response = await fetch(CURRENT_CREATION_STATUS, {
                    signal: abortContGet.signal,
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('token')}`
                    }
                })

                let js = await response.json();

                if(response?.status === 401 || response?.status === 403 ){
                    navigate('/login')
                } else if(js["content"] !== 0) {
                    const { response, json } = await request(MENU_INFO, {
                        method: 'GET',
                        signal: abortCont.signal,
                        headers: {
                            Authorization: `Bearer ${window.localStorage.getItem('token')}`
                        },
                    })
                    
                    if(json.error_message !== null) {
                        setGetCampaign(false)
                        setErrorMessage(json.error_message);
                    } else if(response?.status !== 200){
                        setGetCampaign(false)
                    } else {
                        setGetCampaign(false)
                    }
                } else {
                    setGetCampaign(false)
                    navigate('/dashboard')
                }
            } catch(e: any){
                setGetCampaign(false)
                setErrorMessage(e.message)
            }
        }

        if(window.localStorage.getItem("currentStep") === undefined){
            window.localStorage.setItem("currentStep", "1")
        }

        verifyCampaign()

        return () => {
            abortCont.abort();
            abortContGet.abort();
        };
        
    },[])

    
    if(getCampaign === true) return <LoadScreen message=""/>
    
    if(loading) return <LoadScreen message=""/>
    
    if(errorMessage !== "") 
        return <TextCenter><p>{errorMessage === "User Menu not found" ? "MenuDino não publicado! Realize a publicação e após criado retorne aqui para prosseguir." : errorMessage}</p></TextCenter>
    
    if(error) return <TextCenter><p>Ocorreu um erro ao carregar, tente novamente.</p></TextCenter>
    
    if(data === null) return <TextCenter><p>Nenhum dado encontrado.</p></TextCenter>

    return (
        <Container>
            <div>
                <GridImage>
                    <img src={Md} alt="MenuDino" title="Imagem de demonstração."/>
                    <MenuDinoCard className='removeSelection'>
                        <Cover src={data.content.details.urlCover} alt="Url Capa" title="Imagem de demonstração."/>
                        <div>
                            <Logo>
                                <img  src={data.content.details.urlLogo} alt="Url Logo" title="Imagem de demonstração."/>
                            </Logo>
                            <MdTitle>
                                <p>{data.content.details.name.toUpperCase()}</p>
                            </MdTitle>
                            <OpenNow>
                                <b>Aberto agora</b>
                            </OpenNow>
                        </div>
                    </MenuDinoCard>
                </GridImage>
                
                <GridTitle>
                    <div>
                        <span onClick={() => window.open(`https://${window.localStorage.getItem('menudino')}.menudino.com`)}><FaPlay /> <b>{window.localStorage.getItem('menudino')}</b>.menudino.com</span>
                    </div>
                </GridTitle>
                <GridCenter>
                    <div>
                        <div>
                            <b> <FaStore /> Segmento: </b>
                            <span>{data.content.details.segment}</span>
                        </div>
                        <em>Esse é o segmento principal cadastrado para seu negócio, confira todas
                        informações abaixo antes de continuar.</em>
                        {/* <div>
                            <b> <FaPhone /> Telefone: </b>
                            <span>{data.content.details.telephone}</span>
                        </div>
                        <em>Este é o telefone para seus clientes entraram em contato quando
                        visualizarem seu anúncio.</em> */}
                    </div>
                </GridCenter>
                <GridCenter1>
                    <div>
                        <span>
                            <FaMapMarkerAlt />
                            <b>Endereço</b>
                        </span>
                        <ul>
                            <li>{data.content.details.address}, {data.content.details.number}</li>
                            <li>{data.content.details.city} - {data.content.details.uf}</li>
                            <li>{data.content.details.zipCode} - <p title="Este é o telefone para seus clientes entrarem em contato quando
                        visualizarem seu anúncio.">{data.content.details.telephone}</p></li>
                        </ul>
                    </div>
                </GridCenter1>
                <GridCenter2>
                    <div>
                        <span>
                            <FaRegClock />
                            <b>Horários</b>
                        </span>
                        <ul>
                            {data.content.details.businessHours.map((hours: any, index: any) => (
                                index >= 7 ? null : 
                                <li key={index}><b>{hours.extendedWeekDay}</b>
                                {hours.scheduleStart.slice(0,5)} às {hours.scheduleEnd.slice(0,5)}</li>
                            ))}
                        </ul>
                    </div>
                </GridCenter2>
                <GridAside>
                    <div>
                        <span>
                            <FaChevronCircleRight />
                            <b>Público-alvo</b>
                        </span>
                        <p>Vamos segmentar seu anúncio para sua cidade, região e horário de funcionamento.</p>
                        <img src={Maps} alt="mapa" className='removeSelection'/>
                    </div>
                </GridAside>
                <GridFooter>
                    <p onClick={() => window.open('https://ajuda.programaconsumer.com.br/como-alterar-os-dados-de-minha-assinatura-do-consumer/')}>Precisa corrigir algo? <span> Saiba como.</span> </p>
                </GridFooter>
            </div>
        </Container>
    )
}

export default Index
