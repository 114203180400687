import styled, { keyframes } from 'styled-components';

export const ContentGrid = styled.div`
    display: grid;
    grid-template-columns: min(20vw, 220px) 40% 40%;
    grid-template-rows: 50px 155px 155px 100px;
    grid-gap: 20px;
    padding: 0 30px;
    margin: 0 auto;
    position: relative;

    @media(min-width: 704px){
        margin: 0 80px 0 30px;
    }

    @media(max-width: 703px){
        display: flex;
        flex-direction: column;
    }

    @media(max-width: 429px){
        padding: 0;
    }
`;

export const Title = styled.section`
    grid-column: 1/4;
    grid-row: 1/1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    > div {
        box-shadow: 0 2px 5px rgba(0,0,0,.2);
    }
    @media(max-width: 703px){
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
    }

    @media(max-width: 672px){
        display: none;
    }

`

const campaign = keyframes`
    to {
        opacity: 1;
        top: 38px;
    }
`;

export const ItemTitle1 = styled.div`
    /* cursor: pointer; */
    height: 40px;
    width: min(20vw, 220px);
    background: white;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-size: .6rem;
    position: relative;
    z-index: 500;
    overflow: hidden;

    b {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }
    
    svg {
        height: 20px;
        width: 20px;
    }

    > ul {
        opacity: 0;
        animation: ${campaign} .2s linear forwards;
        transition: .5s;
        top: 0px;
        left: 0px;
        position: absolute;
        background: white;
        padding: 10px;
        width: 200px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 3px 3px 5px rgba(0,0,0,.5);

        li {
            list-style: none;
            margin-bottom: 5px;
            transition: .3s;

            &:hover{
                font-weight: bold;
                background: #F9F9F9;
            }
        }
    }

    @media(max-width: 703px){
        flex: 1 1 100px;
    }
`;
export const ItemTitle2 = styled.div`
    position: relative;
    height: 40px;
    width: 150px;
    background: white;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-size: .8rem;
    margin-left: 20px;
    margin-right: 10px;

    > p {
        padding: 15px 0;
        cursor: pointer;
    }
    
    svg {
        height: 18px;
        cursor: pointer;
        width: 18px;
    }

    @media(max-width: 788px){
        /* display: none; */
        font-size: .7rem;
        width: 130px;
    }
`;

interface SelectProps {
    show: boolean
}

const showSelect = keyframes`
    from { 
        opacity: 0;
        top: 20px;
    } 
    to {
        opacity: 1;
        top: 30px;
    }
`

export const Select = styled.div<SelectProps>`
    display: ${props => props.show === true ? 'flex' : 'none'};
    animation: ${props => props.show === true ? showSelect : ''} .3s ease-out forwards;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 2px 3px 5px rgba(0,0,0,.5);
    z-index: 10;
    width: 100%;
    background: white;
    top: 30px;
    left: 0;
    cursor: pointer;
    margin-top: 10px;

    p {
        width: 100%;
        margin: 0 10px;
        text-align: center;
        &:hover{
            font-weight: bold;
            background: ${props => props.theme.color.tinWhite};
        }       
    }
`

const showDateCustom = keyframes`
    from {
        width: 0px;
        height: 0px;
        opacity: 0;
    } 

    to {
        opacity: 1;
    }
`

export const DateCustom = styled.div`
    position: absolute;
    margin-top: 10px; 
    overflow: hidden;
    z-index: 50;
    top: 35px;
    width: 300px;
    height: 250px;
    left: 0;
    background: white;
    box-shadow: 3px 2px 5px rgba(0,0,0,0.5);
    animation: ${showDateCustom} .5s ease-in forwards;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    > div {
        display: flex;
        flex-direction: column;

        input {
            margin-top: 10px;
            width: 200px;
            height: 30px;
            padding: 0 10px;
        }
    }

    > span {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    > button {
            width: 100px;
            height: 30px;
            font-weight: bold;
            background: ${props => props.theme.color.blackColor};
            outline: none;
            color: white;
            border: 1px solid transparent;
            border-radius: 5px;
            cursor: pointer;
        }


    @media(max-width: 845px){

        > button {
            margin: 0 auto;
        }
    }
`

export const ItemTitle3 = styled.div`
    height: 40px;
    width: 300px;
    background: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    font-size: .8rem;
    flex: 1 1 130px;

    a{
        text-decoration: none;
        color: black;

        span {
            color: ${props => props.theme.color.buttonColor};
            font-weight: bold;
        }
    }

    @media(max-width: 853px){
        width: 250px;
    }

    @media(max-width: 782px){
        width: 230px;
        font-size: min(4vw, .8rem);
        margin-left: 5px;
    }

    @media(max-width: 760px){
        /* display: none; */
        font-size: .7rem;
    }
`;

interface ItemProps {
    status: boolean
}

export const ItemTitle4 = styled.div<ItemProps>`
    height: 35px;
    border: 2px solid ${props => props.status === true ? props.theme.color.greenColor : props.theme.color.buttonColor};
    background: white;
    color: ${props => props.status === true ? props.theme.color.greenColor : props.theme.color.buttonColor};
    font-weight: bold;
    width: 80px;
    font-size: .8rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 50px;
    margin-left: 10px;
    
    
    span{
        padding: 0;
        margin: 0;
        font-size: 40px;
        text-align: center;
        transform: translateY(-3px)
    }

    @media(max-width: 760px){
        /* display: none; */
        font-size: .7rem;
        width: 60px;
    }
`;

export const Graph = styled.section`
    grid-column: 2/4;
    grid-row: 2/4;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,.2);
    margin: 0 0 0 0;

    div{
        display: flex;
        justify-content: space-around;
        align-items: center;
        

        input {
                margin: 0 10px;
                border: 1px solid black;
        }
        
        input[type=radio] {
            width: 18px;
            height: 18px;
            border-radius: 15px;
            position: relative;
            border: 1px solid black;
        }
        
        input[type=radio]:checked:after {
            width: 10px;
            height: 10px;
            border-radius: 15px;
            top: 2px;
            left: 1.30px;
            position: relative;
            background-color: ${props => props.theme.color.buttonColor};
            content: '';
            display: inline-block;
            visibility: visible;
            border: 2px solid white;
        }

        div{
            div > svg {
                width: 600px;
                height: 600px;
                
            }
        }

        @media(max-width: 450px){
            input[type=radio]:checked:after {
                width: 10px;
                height: 10px;
                border-radius: 15px;
                top: 2px;
                left: 1.68px;
                position: relative;
                background-color: ${props => props.theme.color.buttonColor};
                content: '';
                display: inline-block;
                visibility: visible;
                border: 2px solid white;
            }
        }            
    }

    @media(max-width: 570px){
        /* margin: 0 -20px; */
        padding: 10px 10px 0 -10px; 

        div > div {
            font-size: .6rem;
        }
    }

    @media(max-width: 420px){
       div > div {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        /* min-width: 330px; */
        margin: 0;
       }
    }

    @media(max-width: 672px){
        margin-top: 50px;
    }
`

export const Aside = styled.aside`
    grid-column: 1/1;
    grid-row: 2/5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media(max-width: 703px){
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
`

export const AsideItem1 = styled.div`
    grid-row: 1/2;
    grid-column: 1/1;
    background: white;
    border-radius: 5px;
    padding: 10px 20px;
    background: transparent linear-gradient(180deg, #EA4335 0%, #3E1F93 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px #7777817E;
    color: white;

    b{
        font-size: .6rem;
        color: white;
    }

    div {
        display: flex;
        align-items: center;
        text-align: center;
        margin-left: 10px;
        margin-top: 5px;
        color: white;

        svg{
            background: transparent;
            height: 30px;
            width: 30px;
            padding: 5px;
            border-radius: 4px;
            margin-left: -8px;
        }

        p {
            color: white;
            font-size: 1.4rem;
            font-weight: bold;
            margin-left: 13px;
        }
    }
    small {
        display: flex;
        color: white;
        font-size: .7rem;
        text-align: center;
        margin-top: 5px;
    }

    @media(min-width: 703px) and (max-width: 775px){
        margin-bottom: 5px;
    }

    @media(max-width: 703px){
        flex: 1 1 150px;
        margin: 5px 10px 5px 0;
    }

    @media(max-width: 599px){
        margin: 10px 10px 10px 0px;
    }

    @media(max-width: 429px){
        margin: 10px;
    }

    @media(max-width: 410px){
        margin: 10px 0;
        min-width: 100%;
    }
`

export const AsideItem2 = styled.div`
    grid-row: 2/3;
    grid-column: 1/1;
    background: white;
    border-radius: 5px;
    padding: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,.2);
    margin-left: 30px;
    position: relative;

    b{
        font-size: .6rem;
    }

    div {
        display: flex;
        align-items: center;
        text-align: center;
        margin-left: 10px;
        margin-top: 5px;

        svg{
            background: red;
            height: 25px;
            width: 25px;
            padding: 5px;
            fill: white;
            border-radius: 4px;
            margin-left: -8px;
        }

        p {
            color: red;
            font-size: 1.2rem;
            font-weight: bold;
            margin-left: 13px;
        }
    }
    small {
        display: flex;
        color: gray;
        font-size: .7rem;
        text-align: center;
        margin-top: 5px;
    }

    @media(min-width: 703px) and (max-width: 775px){
        margin-bottom: 5px;
    }

    @media(max-width: 703px){
        flex: 1 1 150px;
        margin: 10px;
        padding: 12px;
    }

    @media(max-width: 599px){
        margin: 10px 10px 10px 0px;
    }

    @media(max-width: 589px){
        margin: 10px 0px 10px 10px;
    }

    @media(max-width: 429px){
        margin: 10px;
    }

    @media(max-width: 410px){
        margin: 10px 0;
        min-width: 100%;
    }
`

export const AsideItem3 = styled.div`
    grid-row: 3/4;
    grid-column: 1/1;
    background: white;
    border-radius: 5px;
    padding: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,.2);
    margin-left: 30px;
    position: relative;

    b{
        font-size: .6rem;
    }

    div {
        display: flex;
        align-items: center;
        text-align: center;
        margin-left: 10px;
        margin-top: 5px;

        svg{
            background: red;
            height: 25px;
            width: 25px;
            padding: 5px;
            fill: white;
            border-radius: 4px;
            margin-left: -8px;
        }

        p {
            color: red;
            font-size: 1.2rem;
            font-weight: bold;
            margin-left: 13px;
        }
    }
    small {
        display: flex;
        color: gray;
        font-size: .7rem;
        text-align: center;
        margin-top: 5px;
    }

    @media(min-width: 703px) and (max-width: 775px){
        margin-bottom: 5px;
    }

    @media(max-width: 703px){
        flex: 1 1 150px;
        margin: 5px 0px 5px 10px;
        padding: 12px;

        small{
            font-size: min(.6rem, 3vw);
        }
    }

    @media(max-width: 599px){
        margin: 10px 0px 10px 10px;
    }

    @media(max-width: 589px){
        margin: 10px 10px 10px 0px;
    }

    @media(max-width: 429px){
        margin: 10px;
    }

    @media(max-width: 410px){
        margin: 10px 0;
        min-width: 100%;
    }
`

export const AsideItem4 = styled.div`
    grid-row: 4/5;
    grid-column: 1/1;
    background: white;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,.2);
    margin-left: 30px;
    position: relative;

    b{
        font-size: .6rem;
    }

    div {
        display: flex;
        align-items: center;
        text-align: center;
        margin-left: 10px;
        margin-top: 5px;

        svg{
            background: red;
            height: 25px;
            width: 25px;
            padding: 5px;
            fill: white;
            border-radius: 4px;
            margin-left: -8px;
        }

        p {
            color: red;
            font-size: 1.2rem;
            font-weight: bold;
            margin-left: 13px;
        }
    }
    small {
        display: flex;
        color: gray;
        font-size: .7rem;
        text-align: center;
        margin-top: 5px;
    }

    @media(min-width: 703px) and (max-width: 775px){
        b {
            font-size: .5rem;
        }

        div p {
            font-size: 1rem;
        }

        small{
            font-size: .5rem;
        }
    }

    @media(max-width: 703px){
        flex: 1 1 150px;
        margin: 5px 0px;
    }

    @media(max-width: 599px){
        margin: 10px 0 10px 10px;
    }

    @media(max-width: 429px){
        margin: 10px;
    }

    @media(max-width: 410px){
        margin: 10px 0;
        min-width: 100%;
    }
`

export const LineVertical = styled.span`
    width: 6px;
    grid-row: 2/5;
    grid-column: 1/1;
    background: black;
    margin-left: 10px;

    @media(max-width: 703px){
        display: none;
    }
`

export const LineHorizontal = styled.span`
    position: absolute;
    top: 8px;
    left: -20px;
    height: 3px;
    width: 20px;
    background: black;

    @media(max-width: 704px){
        display: none;
    }
`

export const CircleLine = styled.span`
    width: 7px;
    height: 7px;
    border-radius: 100%;
    border: 1px solid #000;
    background: white;
    position: absolute;
    left: -2px;
    top: 6px;

    @media(max-width: 704px){
        display: none;
    }
`

export const Footer = styled.div`
    grid-column: 2/4;
    grid-row: 4/5;

    div{
        /* padding: 0 10px; */
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        border-radius: 5px;
        flex: 1 1 20%;

        div {
            min-height: 100px;
            padding: 5px 10px;
            text-align: center;
            box-shadow: 0 2px 5px rgba(0,0,0,.2);
        }
    }

    @media(max-width: 703px){
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    @media(max-width: 410px){
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

export const FooterItem1 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: white;
    max-width: 33%;
    margin-right: 5px;

    b {
        font-size: .8rem;
        font-weight: bold;
    }

    p{
        color: ${props => props.theme.color.buttonColor};
        font-size: 40px;
    }

    @media(max-width: 844px){
        width: 30%;
    }

    @media(max-width: 855px){
        p{
            font-size: min(3vw, 40px);
            margin-bottom: 10px;
        }   
    }

    @media(max-width: 703px){
        flex: 1 1 150px;
        margin: 5px;
        p{
            margin-bottom: 0px;
        }
    }

    @media(max-width: 589px){
        flex: 1 1 100px;
        p{
            font-size: 1.6rem;
        }
    }

    @media(max-width: 439px){
        flex: 1 1 50px;  
    }

    @media(max-width: 410px){
        min-width: 100%;
        margin: 0 10px;
    }
`
export const FooterItem2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: white;
    max-width: 33%;
    margin-right: 5px;
    margin-left: 5px;

    b {
        font-size: .8rem;
        font-weight: bold;
    }

    p{
        color: ${props => props.theme.color.buttonColor};
        font-size: 40px;
    }

    @media(max-width: 855px){
        p{
            font-size: min(3vw, 25px);
            margin-bottom: 10px;
        }   
    }

    @media(max-width: 703px){
        flex: 1 1 150px;
        margin: 5px;
        p{
            margin-bottom: 0px;
        }

        b {
            font-size: .7rem;
            font-weight: bold;
        }
    }

    @media(max-width: 589px){
        p {
            font-size: 1.4rem;
        }
        /* flex: 1 1 100px; */
    }

    @media(max-width: 459px){
        font-size: 1.2rem;
    }

    @media(max-width: 410px){
        min-width: 100%;
        margin: 10px 10px;
        font-size: 1.6rem;
    }
`
export const FooterItem3 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: white;
    margin-left: 5px;

    b {
        font-size: .8rem;
        font-weight: bold;
    }

    p{
        color: ${props => props.theme.color.buttonColor};
        font-size: 40px;
    }
    @media(max-width: 855px){
        p{
            font-size: min(3vw, 40px);
            margin-bottom: 10px;
        }   
    }

    @media(max-width: 703px){
        flex: 1 1 150px;
        margin: 5px;
        height: 80px;
        p{
            margin-bottom: 0px;
        }
    }

    @media(max-width: 589px){
        flex: 1 1 100px;
        p{
            font-size: 1.6rem;
        }
    }

    @media(max-width: 439px){
        flex: 1 1 50px;
    }

    @media(max-width: 410px){
        min-width: 100%;
        margin: 0 10px;
    }
`

export const MobileMenu = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: end;
    max-width: 92%;
    background: pink;
    text-align: right;
    position: relative;
`

export const Menu = styled.menu`
    display: none;

    @media(max-width: 672px){
        display: flex;
        position: absolute;
        right: -60px;
        top: 0px;
        border-radius: 50%;
        background: ${props => props.theme.color.buttonColor};
        color: white;
        height: 40px;
        width: 40px;
        box-shadow: 0 3px 5px rgba(0,0,0,.3);
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    @media(max-width: 429px){
        right: -35px;
    }
`

const showsMenu = keyframes`
    from {
        opacity: 0;
        right: -50px;
    }
    to {
        opacity: 1;
        right: -20px;
    }
`;


export const ContentMenu = styled.div<ItemProps>`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 300;
    width: 300px;
    height: 200px;
    top: 30px;
    background: white;
    box-shadow: 0 3px 5px rgba(0,0,0,.5);
    animation: ${showsMenu} .4s ease-in forwards;
    padding: 20px;
    border-radius: 5px;

    span {
        width: 100%;
        background: #f3f4ed;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: start;
        text-align: start;
        border-radius: 4px;
        padding: 5px 5px;

        b {
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            font-size: .7rem;
        }
    }

    select {
        width: 100%;
        height: 30px;
        margin: 15px 0;
        border: 1px solid transparent;
        outline: none;
    }

    a {
        width: 100%;
        height: 30px;
        font-size: .8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: black;
        border: 1px solid ${props => props.theme.color.buttonColor};
        border-radius: 4px;
        margin-bottom: 15px;

        p {
            color: ${props => props.theme.color.buttonColor};
            font-weight: bold;
        }
    }

    > div {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border: 1px solid ${props => props.status === true ? props.theme.color.greenColor : props.theme.color.buttonColor};
        color: ${props => props.status === true ? props.theme.color.greenColor : props.theme.color.buttonColor};
        border-radius: 4px;

        b {
            font-size: .7rem;
            margin: 0;
            padding: 0;
            text-align: center;
        }
    }

    @media(max-width: 400px){
        position: absolute;
        left: 0px;
        top: 40px;
        animation: ${showsMenu} .4s ease-in forwards;
    }
`;

export const DateCustomMobile = styled.div`
    position: absolute;
    z-index: 20;
    top: 100px;
    /* right: 100%; */
    width: 100% !important;
    height: 200px !important;
    background: white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: ${props => props.theme.color.blackColor} !important;
    border: 1px solid ${props => props.theme.color.blackColor} !important;

    button {
        background: ${props => props.theme.color.blackColor};
        color: white;
        width: 70px;
        height: 25px;
        margin: 10px 0;
        outline: none;
        border: none;
    }

    > svg {
        color: ${props => props.theme.color.blackColor};
        position: absolute;
        top: 5px;
        right: 5px;
    }
`;

interface TooltipProps {
    isImp: boolean
}

export const TooltipDiv = styled.div<TooltipProps>`
    border-radius: .25rem;
    background: ${props => props.isImp ? '#EA4335' : '#004FAC'};
    color: #fff;
    padding: 1rem;
    box-shadow: 8px 20px 40px 5px rgba(0,0,0,.5);
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: start !important;
    text-align: start !important;
`

export const ErrorSection = styled.section`
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    > p {
        margin-bottom: 20px;

        > span {
            font-style: italic;
        }
    }
`