import styled, { keyframes } from 'styled-components';

const animeLeft = keyframes`
    from {
        opacity: 0;
        left: -50px;
    }
    to {
        opacity: 1;
        left: 0;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    flex-wrap: wrap;
    flex: 1 1 1 300px;
    animation: ${animeLeft} .5s forwards;
    padding: 10px;
`;

export const Title = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    font-size: 20px;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px; */
    margin: 0 auto;
    
    @media(max-width: 900px){
        flex-direction: column;        
    }
`;

export const Card = styled.div`
    max-width: 350px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px 35px;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    border-radius: 5px;
    margin: 20px;

    img{
        position: absolute;
        top: -10px;
        left: -15px;
        height: 25px;
        width: 25px;
    }

    @media(max-width: 500px){
        max-width: 300px;
        /* margin-right: 30px; */
    }
`;

export const CardHead = styled.div`
    font-size: .7rem;
`;

export const CardTitle = styled.div`
    font-size: .7rem;
    margin-top: 20px;

    span {
        cursor: pointer;
    }
`;

export const CardSubtitle = styled.div`
    font-size: .7rem;
    color: ${props => props.theme.color.blueColor};
    font-weight: bold;
    letter-spacing: .03rem;
    margin: 10px 0;
`;

export const CardContent = styled.div`
    font-size: .8rem;
    color: gray;
    line-height: 22px;
`;

export const Foot = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 100%;
    margin-bottom: 50px;


    svg {
        position: absolute;
        top: 35px;
        left: 15px;
    }
`;

export const FootTitle = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px 20px;

    b{
        font-size: min(4vw, 1rem);
    }

    span{
        margin-top: 10px;
        font-size: .7rem;
    }
`;

interface FootContentProps {
    lines: boolean
}

export const FootContent = styled.div<FootContentProps>`
    display: flex;
    margin-top: 10px;
    flex: 1 1 100px;
    flex-wrap: wrap;
    background: #F4F4F7;
    padding: 10px 30px 20px 30px;
    justify-self: center;
    justify-content: space-evenly;
    align-items: center;
    font-size: .85rem;
    
    &:last-child() {
        color: red;
    }

    p{
        margin: 5px;
    }

    b {
        margin-top: ${props => props.lines === true ? '20px' : '0px'};
        color: #a2a2a2;
        cursor: pointer;
        padding: 5px;
    }
`;

const animationKey = keyframes`
    from {
        transform: rotate(-360deg);
    }
`

export const LoadKey = styled.div`
    height: 20px;
    width: 20px;
    border: 3px solid rgba(0,0,0,0.5);
    border-left-color: ${props => props.theme.color.blackColor};
    border-radius: 50%;
    animation: ${animationKey} 1s linear infinite;
`