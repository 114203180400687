import { FC } from 'react'
import { Div } from './styles'

const ScreenDiv:FC = (props) => {
    return (
        <Div>
            {props.children}
        </Div>
    )
}

export default ScreenDiv
