import React, {useState, useCallback, forwardRef, useImperativeHandle, useEffect} from 'react'
import { Content, Div, Close, Props, Step1, Step2, Recover, Back, Spin, See, Error, ErrorLogin } from './styles';
import Logo from '../../../../images/icons/redlogo.png'
import GoBack from '../../../../images/icons/Icon ionic-ios-arrow-forward.svg'
import { FaEye, FaSpinner, FaEyeSlash } from 'react-icons/fa'
import { useNavigate } from 'react-router';
import { GET_CAMPAIGN, SIGN_IN } from '../../../../api';
import useFetch from '../../../../Hooks/useFetch';

export interface ModalHandles {
    openModal: () => void;
}

const Modal:React.ForwardRefRenderFunction<ModalHandles> = (props, ref) => {

    const [visible, setVisible] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [loginError, setLoginError] = useState<string>('');
    const [step, setStep] = useState<any>(1);
    const [hide, setHide] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [showPass, setShowPass] = useState<string>('password')
    const { request } = useFetch();
    const navigate = useNavigate();

    useImperativeHandle(ref, () => {
        return {
            openModal
        }
    })

    useEffect(() => {
        setEmailError('')
        setLoginError('')
    },[])
    
    const openModal = useCallback(() => {
            setVisible(true);
            document.body.style.overflowY = 'hidden';
        }, [],
    )

    const handleCloseModal = useCallback(() => {
            setHide('hide');
            document.body.style.overflowY = 'scroll';
            setTimeout(() => {
                setHide('');
                setVisible(false);
                setStep(1);
                setShowPass('password');
            }, 500);
        }, [],
    )

    const handleClick = useCallback((e) => {
            if(e.currentTarget === e.target){
                document.body.style.overflowY = 'scroll';
                setHide('hide');
                setTimeout(() => {
                    clearStates();
                }, 500);
            }
        },[],
    )

    function clearStates(){
        setEmail('');
        setPassword('');
        setEmailError('');
        setHide('');
        setVisible(false);
        setLoginError('');
        setStep(1);
        setLoading(false);
        setShowPass('password')
    }

    function submit(){
        setLoading(true);
        if(password === null || password === ''){
            setLoginError("A senha deve ser preenchida!")
            setLoading(false);
            return null;
        } else {
            try {
                login()
            } catch {
                setLoginError("Ocorreu um erro ao realizar o login.")
            }      
        }             
    }

    function click(){
        if(email.includes('@')){
            setStep(2)
            setEmailError('')
        } else {
            setEmailError("O e-mail deve ser preenchido corretamente.")
        }
    }

    async function login(){
        if(step === 1){
            return null;
        }

        let body = {
            "username": email,
            "password": password
          }        

        try {
            const response = await fetch(SIGN_IN, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
    
            const json = await response.json();
    
            if(response.status === 200){
                window.localStorage.setItem('token', json['content']['access_token'])
                window.localStorage.setItem('user', json['content']['name'])
                const urlSplit = json.content.menuUrl.split('//');
                const u = urlSplit[1].split('.');
                window.localStorage.setItem('menudino', u[0])
                setLoading(false);
    
                verifyCampaign()
            } else {
                setLoading(false);
                setLoginError("Verifique os dados inseridos e tente novamente.")
            }
        } catch(e: any){
            setLoginError(e.message)
        }
    }

    async function verifyCampaign(){
        const { response } = await request(GET_CAMPAIGN, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`
            }
        })
        
        document.body.style.overflowY = 'scroll';
        if(response?.status === 204 ) {
            navigate('/criar')
        } else {
            navigate('/dashboard')
        }
    }

    function closeKeyUp(e: any){
        if(e.keyCode === 27 && loading !== true){
            handleCloseModal()
            document.body.style.overflowY = 'scroll';
            clearStates()
        }
    }

    function keyUp(e: any){
        if(e === 'Enter'){
            if(step === 1){
                setStep(2)
            } else if(step === 2){
                submit()
            } else {
                return null;
            }
        }
    }

    if(!visible) return null;

    return (
        <Content onClick={(e) => handleClick(e)} onKeyUp={(e) => closeKeyUp(e)} animation={hide}>
            <Div>
                <Close onClick={handleCloseModal}>+</Close>
                <Props>
                    <img src={Logo} alt="" width="60" height="60"/>
                    <h3>Login com o Consumer ID</h3>
                    {step === 1 ? <Step1>
                        <p>Digite o e-mail usado na sua assinatura do Consumer</p>
                        <input type="email" name="email" autoComplete="on" autoFocus value={email} onChange={(e) => setEmail(e.target.value)} placeholder="seu@email.com" onKeyUp={(e) => keyUp(e.key)}/>
                        {emailError && <Error>{emailError}</Error>}
                        <div>
                        <button type="button" onClick={click}>PRÓXIMO</button>
                        </div>
                    </Step1> :
                    <Step2>
                        <div>
                            <input autoFocus type={showPass} value={password} onChange={({target}) => setPassword(target.value)} placeholder="Senha" onKeyUp={(e) => keyUp(e.key)}/>
                            <See>
                                {showPass === 'text' ? <FaEyeSlash onClick={() => setShowPass('password')}/>: <FaEye onClick={() => setShowPass('text')}/>}
                            </See>
                        </div>
                        <Back src={GoBack} onClick={() => setStep(1)}></Back>
                        {loginError && <ErrorLogin>{loginError}</ErrorLogin>}
                        {loading === true ? <button><Spin><FaSpinner size="20px" /></Spin></button> : <button onClick={submit}>ENTRAR</button>}
                    </Step2>}
                    {step === 2 && <Recover href="https://loja.programaconsumer.com.br/account/login" target="_blank">Esqueceu a senha? Clique aqui!</Recover>}
                </Props>
            </Div>
        </Content>
    )
}

export default forwardRef(Modal)
