import { FC } from 'react'
import ContentGrid from './SectionOne'
import { Container } from './styles'
import ContentFlex from './SectionTwo'

const Index:FC = () => {

    return (
        <Container>
            <ContentGrid/>
            <ContentFlex />
        </Container>
    )
}

export default Index
