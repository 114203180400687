/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router'
import Modal, { ModalHandles } from '../Shared/Modal/Modal'
import { Button } from './styles'

interface RedButtonProps {
    adaptive: boolean,
}

const RedButton: FC<RedButtonProps> = ({ adaptive }) => {
    const navigate = useNavigate();
    const modalRef = useRef<ModalHandles>(null)

    const handleOpenModal = useCallback(() => {
        let token = window.localStorage.getItem('token');

        if (token !== null) {
            navigate('/criar')
        } else {
            modalRef.current?.openModal();
        }

        // Link para lista de espera
        //window.open('https://forms.gle/18qRysd2Qb5tv7br8');
    }, [])

    return (
        <>
            <Button onClick={handleOpenModal} adaptive={adaptive} className='removeSelection'>
                Criar
            </Button>
            <Modal ref={modalRef} />
        </>
    )
}

export default RedButton
