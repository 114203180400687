import { GridDiv,  PlaceholderItem, PlaceholderGraph, PlaceholderMetrics } from './styles'

const index = () => {
    return (
        <PlaceholderMetrics>
            <GridDiv>
                <PlaceholderGraph>
                    <span style={{width: '20%'}}></span>
                    <span style={{width: '60%'}}></span>
               </PlaceholderGraph>
               <PlaceholderItem>
                    <span style={{width: '30%'}}></span>
                    <span style={{width: '80%'}}></span>
               </PlaceholderItem>
               <PlaceholderItem>
                    <span style={{width: '40%'}}></span>
                    <span style={{width: '50%'}}></span>
               </PlaceholderItem>
               <PlaceholderItem>
                    <span style={{width: '50%'}}></span>
                    <span style={{width: '60%'}}></span>
               </PlaceholderItem>
               <PlaceholderItem>
                    <span style={{width: '20%'}}></span>
                    <span style={{width: '50%'}}></span>
               </PlaceholderItem>
               <PlaceholderItem>
                    <span style={{width: '60%'}}></span>
                    <span style={{width: '90%'}}></span>
               </PlaceholderItem>
               <PlaceholderItem>
                    <span style={{width: '80%'}}></span>
                    <span style={{width: '30%'}}></span>
               </PlaceholderItem>
               <PlaceholderItem>
                    <span style={{width: '40%'}}></span>
                    <span style={{width: '70%'}}></span>
               </PlaceholderItem>
               <PlaceholderItem>
                    <span style={{width: '80%'}}></span>
                    <span style={{width: '30%'}}></span>
               </PlaceholderItem>
               <PlaceholderItem>
                    <span style={{width: '50%'}}></span>
                    <span style={{width: '90%'}}></span>
               </PlaceholderItem>
               <PlaceholderItem>
                    <span style={{width: '20%'}}></span>
                    <span style={{width: '60%'}}></span>
               </PlaceholderItem>
            </GridDiv>
        </PlaceholderMetrics>
    )
}

export default index
